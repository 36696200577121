export const formatTimeAgo = (createdAt: string): string => {
  const now = new Date();
  const createdDate = new Date(createdAt);
  const diffInSeconds = Math.floor(
    (now.getTime() - createdDate.getTime()) / 1000
  );

  switch (true) {
    case diffInSeconds < 60:
      return `${diffInSeconds} sec ago`;
    case diffInSeconds < 3600:
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} min ago`;
    case diffInSeconds < 86400:
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} h ago`;
    case diffInSeconds < 2592000:
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} days ago`;
    case diffInSeconds < 31536000:
      const months = Math.floor(diffInSeconds / 2592000);
      return `${months} months ago`;
    default:
      const years = Math.floor(diffInSeconds / 31536000);
      return `${years} years ago`;
  }
};
