import {
  Form,
  Input,
  Upload,
  Button,
  Typography,
  UploadFile,
  Row,
  Col,
  Skeleton,
} from "antd";
import { ReactComponent as UploadIcon } from "../../../../assets/images/upload.svg";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import DepartmentSelector from "../../../shared/DepartmentSelector";
import PositionSelector from "../../../shared/PositionSelector";
import showNotification from "../../../../services/notificationService";

const { Text } = Typography;
const { TextArea } = Input;

const EditModuleScreen = (props: any) => {
  const {
    module,
    actions: { getModuleById, setInitialSteps, editModule },
  } = props;

  const [fileList, setFileList] = useState<any>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setInitialSteps(2);
  }, []);

  useEffect(() => {
    if (id) {
      getModuleById({ id });
    }
  }, [id]);

  useEffect(() => {
    if (id && !!module) {
      form.setFieldsValue({
        ...module,
      });
    }

    if (module?.coverImageKey) {
      setFileList([
        {
          url: `${process.env.REACT_APP_CLOUDFRONT_KEY}/${module.coverImageKey}`,
        },
      ]);
    }
    if (module?.assignments?.departments?.length > 0) {
      setSelectedDepartments(
        module?.assignments?.departments.map((department: any) => department.id)
      );
    }

    if (module?.assignments?.positions?.length > 0) {
      setSelectedPositions(
        module?.assignments?.positions.map((position: any) => position.id)
      );
    }
  }, [module]);

  const handleSubmit = ({
    title,
    description,
    navigateRoute = "module",
  }: {
    title: string;
    description: string;
    navigateRoute: string;
  }) => {
    const formData = new FormData();
    formData.append("title", title.trim());
    formData.append("description", description.trim());
    if (fileList.length > 0 && fileList[0]?.originFileObj) {
      formData.append("coverImage", fileList[0].originFileObj as File);
    }
    selectedDepartments.forEach((departmentId: string) => {
      formData.append("departmentIds", departmentId);
    });
    selectedPositions.forEach((positionId: string) => {
      formData.append("positionIds", positionId);
    });

    editModule({ module: formData, id: module.id, navigate, navigateRoute });
  };

  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
    setIsFormChanged(true);
  };

  const handleDepartmentChange = (departments: string[]) => {
    setSelectedDepartments(departments);
    setIsFormChanged(true);
  };

  const handlePositionChange = (positions: string[]) => {
    setSelectedPositions(positions);
    setIsFormChanged(true);
  };

  const handleSaveAndContinue = () => {
    if (!isFormChanged) {
      navigate(`/educator/module/${id}/assign-courses`, {
        state: { isEdit: true, module },
      });
      return;
    }

    form
      .validateFields()
      .then((values) => {
        handleSubmit({ ...values, navigateRoute: "assign-courses" });
      })
      .catch((info) => {
        showNotification("error", "Validation failed");
      });
  };

  const handleFormChange = () => {
    setIsFormChanged(form.isFieldsTouched() || fileList.length > 0);
  };

  if (!module) {
    return <Skeleton active />;
  }

  return (
    <Row className="h-full">
      <Col lg={8}>
        <CreateEntityInfo
          title=""
          text={`You can edit the information and Save the Changes.
         
              Continue to add or remove the courses to the Module`}
        />
      </Col>
      <Col lg={16} className=" max-w-[600px]">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          onValuesChange={handleFormChange}
        >
          <Text className="text-neutral-600 text-sm font-semibold">Title</Text>
          <Form.Item
            name="title"
            className="mt-2"
            rules={[
              { required: true, message: "Please input the title!" },
              {
                validator: async (_, value) => {
                  if (!!value && value.trim() === "") {
                    return Promise.reject(new Error("Title cannot be empty"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="Type the title here" />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Description
          </Text>
          <Form.Item
            name="description"
            className="mt-2"
            rules={[
              { required: true, message: "Please input the description!" },
              {
                validator: async (_, value) => {
                  if (!!value && value.trim() === "") {
                    return Promise.reject(
                      new Error("Description cannot be empty")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <TextArea placeholder="Add description here" rows={10} />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Cover Image
          </Text>

          <Upload
            name="coverImage"
            listType="picture-card"
            maxCount={1}
            beforeUpload={() => false}
            accept="image/png, image/jpeg, image/gif"
            className="bg-white rounded-md h-[310px] mt-2 mb-6"
            fileList={fileList}
            onChange={handleChange}
          >
            {fileList.length === 0 && (
              <div className="flex flex-col items-center justify-center">
                <UploadIcon />
                <p className="text-neutral-600 text-base font-medium">
                  Upload a cover image
                </p>
                <p className="text-xs text-neutral-500 font-normal">
                  PNG, JPG, GIF up to 5MB
                </p>
              </div>
            )}
          </Upload>

          <Text className="text-neutral-600 text-sm font-semibold">
            Assign Module
          </Text>

          <div className="mt-2 flex flex-col gap-2">
            <DepartmentSelector
              onSelectionChange={handleDepartmentChange}
              setSelectedDepartments={setSelectedDepartments}
              selectedDepartments={selectedDepartments}
            />
            <PositionSelector
              onSelectionChange={handlePositionChange}
              selectedPositions={selectedPositions}
              setSelectedPositions={setSelectedPositions}
            />
          </div>
          <div className="w-full flex justify-end mt-5 gap-4">
            <Form.Item className="w-2/4">
              <Button
                htmlType="submit"
                className="w-full h-[50px] text-base  text-primary-600 border-primary-200 font-semibold rounded-lg"
                disabled={!isFormChanged || fileList.length === 0}
              >
                Save & Exit
              </Button>
            </Form.Item>
            <Form.Item className="w-2/4">
              <Button
                onClick={handleSaveAndContinue}
                type="primary"
                className="w-full bg-primary-600 h-[50px] font-semibold text-base rounded-lg"
                disabled={fileList.length === 0}
              >
                Save & Continue
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default EditModuleScreen;
