import { useState } from "react";
import { Avatar, Button, Input, Typography, Upload } from "antd";

import CompanyStructureItems from "../../../assets/images/company-structure-items/company-structure-items";
import { ReactComponent as UploadIcon } from "../../../assets/images/upload.svg";

const { Text } = Typography;

const CompanyInfoEdit = ({
  companyInfo,
  setIsEditing,
  editCompanyInfo,
}: {
  companyInfo: any;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  editCompanyInfo: (payload: any) => {
    type: string;
    payload: any;
  };
}) => {
  const [newCompanyInfoName, setnewCompanyInfoName] = useState(
    companyInfo?.name
  );
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [fileList, setFileList] = useState<any>(null);

  const handleUploadChange = (info: any) => {
    const file = info.file;
    if (file) {
      setFileList(file);
      try {
        const imageUrl = URL.createObjectURL(file);
        setAvatarUrl(imageUrl);
      } catch (error) {
        console.error("Error creating object URL: ", error);
      }
    } else {
      console.error("No valid file provided for upload.");
    }
  };

  const handleEditCompanyInfo = () => {
    if (companyInfo?.name === newCompanyInfoName && !fileList) return;
    const formData = new FormData();
    formData.append("name", newCompanyInfoName);
    if (fileList) {
      formData.append("logo", fileList);
    }

    editCompanyInfo({ id: companyInfo?.id, companyInfo: formData });
    setIsEditing(false);
  };

  return (
    <div>
      <div className="flex gap-4 mb-6">
        <CompanyStructureItems.Step1 />
        <Text className="text-neutral-600 font-normal text-lg">
          Company Info
        </Text>
      </div>
      <Text className="text-neutral-600 font-semibold text-sm">
        Company Name
      </Text>
      <Input
        value={newCompanyInfoName}
        placeholder="Type Company Name"
        onChange={(e) => setnewCompanyInfoName(e.target.value)}
        className="flex-1 rounded-lg mt-2 mb-8"
      />
      <Text className="text-neutral-600 font-semibold text-sm">
        Company Logotype
      </Text>
      <div className="bg-white rounded-md shadow-md pb-10 mt-2 flex flex-col justify-center items-center">
        {avatarUrl ? (
          <Avatar size={130} src={avatarUrl} className="my-[16px]" />
        ) : (
          <div className="flex flex-col items-center justify-center pt-10">
            <UploadIcon />
            <p className="text-neutral-600 text-base font-medium mt-6">
              Upload a logotype
            </p>
            <p className="text-xs text-neutral-500 font-normal mb-5">
              PNG, JPG, GIF up to 5MB
            </p>
          </div>
        )}

        <div className="flex gap-4">
          <Button
            disabled={!fileList}
            key="cancel"
            onClick={() => {
              setAvatarUrl("");
              setFileList(null);
            }}
            className="w-[142px]"
          >
            Close
          </Button>
          <Upload
            name="logo"
            maxCount={1}
            beforeUpload={() => false}
            accept="image/png, image/jpeg, image/gif"
            onChange={handleUploadChange}
            showUploadList={false}
          >
            <Button type="primary" className="w-[142px]">
              Browse Files
            </Button>
          </Upload>
        </div>
      </div>

      <div className="flex w-full gap-10 mt-4">
        <Button
          className="flex-1 text-primary-600 font-semibold text-sm border border-primary-200 h-[50px]"
          onClick={() => setIsEditing(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={
            (companyInfo?.name === newCompanyInfoName && !fileList) ||
            !newCompanyInfoName
          }
          className="flex-1 bg-primary-600 text-white font-semibold text-sm h-[50px]"
          onClick={() => handleEditCompanyInfo()}
        >
          Save & Continue
        </Button>
      </div>
    </div>
  );
};

export default CompanyInfoEdit;
