import { produce } from "immer";

import { positionTypes as types } from "../actions";
import { EducatorPositionStateType } from "../../../utils";

const defaultState: EducatorPositionStateType = {
  positions: [],
  positionsWithUsers: [],
  position: null,
  loading: false,
  loaded: false,
};

const handleSetPosition = (state: any, payload: any) => {
  state.position = payload.position;
  return state;
};

const handleSetPositions = (state: any, payload: any) => {
  state.positions = payload.positions;
  state.loaded = true;
  state.loading = false;
  return state;
};

const handleSetPositionsWithUsers = (state: any, payload: any) => {
  state.positions = payload.positions;
  state.loaded = true;
  state.loading = false;
  return state;
};

export default produce(
  (state: EducatorPositionStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_POSITION:
        return handleSetPositions(state, action.payload);
      case types.SET_POSITION_BY_ID:
        return handleSetPosition(state, action.payload);
      case types.SET_POSITION_USER:
        return handleSetPositionsWithUsers(state, action.payload);
      case types.GET_POSITION:
        return {
          ...state,
          loading: true,
        };
      case types.GET_POSITION_USER:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
