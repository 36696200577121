import { createSelector } from "reselect";
import { LearnerNewsFeedStateType } from "../../../utils/types";

const selectLearnerNewsFeedState = (
  state: any
): { newsFeed: LearnerNewsFeedStateType } => state.learnerNewsFeedReducer;

export const selectLearnerNewsFeed = createSelector(
  selectLearnerNewsFeedState,
  (state: { newsFeed: LearnerNewsFeedStateType }) => state.newsFeed
);

export const selectLearnerNewsFeedLoading = createSelector(
  selectLearnerNewsFeedState,
  (state: any) => state.loading
);
