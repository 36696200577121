import { produce } from "immer";

import { AuthenticationPayload, AuthenticationStateType } from "../../../utils";
import { types } from "../actions";

const defaultState: AuthenticationStateType = {
  user: null,
  token: "",
};

const handleLoginSuccess = (
  state: AuthenticationStateType,
  payload: AuthenticationPayload
) => {
  state.token = payload.token;
  state.user = payload.user;
  return state;
};

const handleSetLoggedInUser = (state: any, payload: any) => {
  state.user = payload.user;
  return state;
};

export default produce(
  (state: AuthenticationStateType = defaultState, action) => {
    switch (action.type) {
      case types.LOGIN_ACTION_SUCCESS:
        return handleLoginSuccess(state, action.payload);
      case types.SET_LOGGED_USER:
        return handleSetLoggedInUser(state, action.payload);
      default:
        return state;
    }
  }
);
