import { CourseStatistics } from "../../../utils/types/Learner";

export const courseStatisticsTypes = {
  GET_COURSE_STATISTICS_ACTION: "GET_COURSE_STATISTICS_ACTION",
  SET_COURSE_STATISTICS_ACTION: "SET_COURSE_STATISTICS_ACTION",
};

export const getCourseStatistics = () => ({
  type: courseStatisticsTypes.GET_COURSE_STATISTICS_ACTION,
});

export const setCourseStatistics = (payload: CourseStatistics) => ({
  type: courseStatisticsTypes.SET_COURSE_STATISTICS_ACTION,
  payload,
});
