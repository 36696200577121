import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import StorageService from "../../services/storageService";

const WildCardNavigation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userToken = StorageService.getStringItem("accessToken");
    if (!userToken) {
      navigate("/login");
      return;
    }
    const decodedToken: any = jwtDecode(userToken);
    const userRole = decodedToken.user.scope;
    if (userRole === "EDUCATOR") {
      navigate("/educator/home");
      return;
    } else if (userRole === "USER") {
      navigate("/user/home");
      return;
    }

    navigate("/login");
  }, []);
  return <></>;
};

export default WildCardNavigation;
