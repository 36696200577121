import {
  Typography,
  Col,
  Row,
  Button,
  Input,
  Tooltip,
  Skeleton,
  Popconfirm,
} from "antd";
import { useEffect, useState } from "react";
import EmptyState from "../../../shared/EmptyState";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import { ReactComponent as AddIcon } from "../../../../assets/images/company-structure-items/add.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import { ReactComponent as AlertIcon } from "../../../../assets/images/reusable-items/alert.svg";
import { useNavigate } from "react-router-dom";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";

const { Text } = Typography;

const EducatorAlertsScreen = (props: any) => {
  const {
    alerts,
    loading,
    actions: { getAlert, deleteAlert },
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAlerts, setFilteredAlerts] = useState(alerts);
  const navigate = useNavigate();

  useEffect(() => {
    getAlert();
  }, []);

  useEffect(() => {
    setFilteredAlerts(
      alerts.filter((alert: any) =>
        alert.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, alerts]);

  const handleCreateFromExisting = (alert: any) => {
    navigate("/educator/alert/create", {
      state: { fromExisting: true, alert },
    });
  };

  if (loading) return <Skeleton active />;

  return (
    <>
      {alerts && alerts.length > 0 ? (
        <Row className="h-full pb-[60px]">
          <Col lg={8}>
            <CreateEntityInfo
              title="Monitor your Alerts"
              text="Here you can monitor all Alerts, you can view details, edit or delete selected Alert.
                    If you need you can also create a new Alert."
            />
            <Button
              onClick={() => navigate("/educator/alert/create")}
              className="h-[50px] border-none shadow-md mt-8"
            >
              <AddIcon />
              <Text className="text-sm font-semibold text-neutral-600">
                Create New Alert
              </Text>
            </Button>
          </Col>
          <Col lg={16} className="h-full max-w-[600px]">
            <Input
              placeholder="Search"
              prefix={<SearchIcon className="mx-3" />}
              className="mb-10"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <div className="w-full flex flex-col gap-4 max-h-[calc(100vh-290px)] overflow-y-auto">
              {filteredAlerts && filteredAlerts.length > 0 ? (
                filteredAlerts.map((alert: any, index: any) => (
                  <div
                    key={index}
                    className="flex justify-between items-center rounded-lg min-h-16 bg-white py-4 px-6 shadow"
                  >
                    <div className="flex gap-3 pr-5 items-center">
                      <AlertIcon className="w-8 h-8 flex-shrink-0 fill-primary-600" />
                      <Text className="text-neutral-600 font-semibold text-base leading-5 flex-grow">
                        {alert.title}
                      </Text>
                    </div>
                    <div className="flex gap-2 flex-shrink-0">
                      <Tooltip title="Create from existing">
                        <Button
                          type="text"
                          className="text-primary-500"
                          icon={<PlusCircleOutlined />}
                          onClick={() => handleCreateFromExisting(alert)}
                        />
                      </Tooltip>
                      <Tooltip title="Delete alert">
                        <Popconfirm
                          title="Delete alert"
                          description="Are you sure to delete this alert?"
                          onConfirm={() => deleteAlert({ id: alert.id })}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="text"
                            className="text-danger-500 "
                            icon={<DeleteOutlined />}
                          />
                        </Popconfirm>
                      </Tooltip>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex items-center justify-center h-full pb-[60px]">
                  <EmptyState />
                </div>
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </>
  );
};

export default EducatorAlertsScreen;
