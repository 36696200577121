import { SetInitialStepsActionType } from "../../../utils/types/Header/HeaderActions";

export const types = {
  CREATE_INITIAL_STEPS: "CREATE_INITIAL_STEPS",
  SET_NEXT_STEP: "SET_NEXT_STEP",
  SET_PREVIOUS_STEP: "SET_PREVIOUS_STEP",
  RESET_STEPPER: "RESET_STEPPER",
  SET_STEP: "SET_STEP",
  SET_TITLES: "SET_TITLES",
};

export const setInitialSteps = (payload: SetInitialStepsActionType) => ({
  type: types.CREATE_INITIAL_STEPS,
  payload,
});

export const setNextStep = () => ({
  type: types.SET_NEXT_STEP,
});

export const setPreviousStep = () => ({
  type: types.SET_PREVIOUS_STEP,
});

export const resetStepper = () => ({
  type: types.RESET_STEPPER,
});

export const setCurrentStep = (payload: any) => ({
  type: types.SET_STEP,
  payload,
});

export const setHeaderTitles = (payload: any) => ({
  type: types.SET_TITLES,
  payload,
});
