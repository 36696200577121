import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ConnectedProps } from "react-redux";
import { Button, Card, Image, Skeleton, Typography } from "antd";

import connector from "./Container";
import FilterButtons from "../../shared/FilterButtons";
import { CourseStatistics } from "../../../utils";
import EmptyState from "../../shared/EmptyState";
import CircleProgress from "../../shared/CircleProgress";
import { ReactComponent as LessonIcon } from "../../../assets/images/reusable-items/lesson.svg";
import { ReactComponent as DurationIcon } from "../../../assets/images/reusable-items/duration.svg";

const { Text } = Typography;

const UserDashboardScreen = (props: ConnectedProps<typeof connector>) => {
  const {
    courseStatistics,
    loading,
    actions: { getCourseStatistics },
  } = props;
  const navigate = useNavigate();
  const [filteredCourses, setFilteredCourses] = useState(courseStatistics);
  const [filter, setFilter] = useState("ALL");
  const filterProps = [
    { key: "All Courses", value: "ALL" },
    { key: "In Progress", value: "UNFINISHED" },
    { key: "Finished", value: "FINISHED" },
  ];

  useEffect(() => {
    getCourseStatistics();
  }, []);

  useEffect(() => {
    let filtered = courseStatistics;

    if (filter === "FINISHED") {
      filtered = filtered.filter(
        (course: CourseStatistics) => course.progress === 100
      );
    } else if (filter === "UNFINISHED") {
      filtered = filtered.filter(
        (course: CourseStatistics) => course.progress !== 100
      );
    }

    setFilteredCourses(filtered);
  }, [filter, courseStatistics]);

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {courseStatistics && courseStatistics.length > 0 ? (
        <div>
          <div className="flex justify-between items-center">
            <Text className="text-xl text-primary-700 font-semibold">
              Course Progress
            </Text>
            <Link
              to={"/user/home"}
              className="text-sm font-semibold text-primary-700"
            >
              {"< Back to Home"}
            </Link>
          </div>
          <FilterButtons
            selectedFilter={filter}
            handleFilterChange={handleFilterChange}
            filterOptions={filterProps}
          />
          <div className="flex flex-wrap gap-5 mt-4 overflow-y-scroll max-h-[calc(100vh-265px)]">
            {filteredCourses?.length > 0 ? (
              filteredCourses.map(
                (content: CourseStatistics, index: number) => (
                  <Card key={index} className="w-[calc(33.33%-14px)] p-4">
                    <div className="flex gap-4">
                      <div className="flex items-center">
                        <CircleProgress
                          size={100}
                          percentage={content.progress}
                        />
                      </div>
                      <div className="w-full">
                        <div className="text-neutral-600 font-semibold min-h-12">
                          {content.courseTitle}
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="w-4 h-4">
                            <DurationIcon className="w-full h-full fill-primary-400" />
                          </div>

                          <Text className="text-sm text-neutral-500">
                            {content?.courseDuration ?? "0 hr 0 min"}
                          </Text>
                        </div>
                        <div className="border-b border-primary-300 my-4"></div>
                        <div className="flex gap-2 items-center ">
                          <div className="w-4 h-4">
                            <LessonIcon className="w-full h-full fill-primary-400" />
                          </div>
                          <Text className="text-sm text-neutral-600">
                            {content.remainingLessons === "0" ? (
                              <>Finished</>
                            ) : content.remainingLessons === "1" ? (
                              <>1 Lesson remain</>
                            ) : (
                              <>{content.remainingLessons} Lessons remain</>
                            )}
                          </Text>
                        </div>
                        <div className="flex gap-2 mt-4 ">
                          <Text
                            className={`${
                              content.pointsCompleted === content.totalPoints
                                ? "w-1/3"
                                : "w-1/2"
                            }  h-[30px] text-xs text-center bg-rose-400 text-white rounded-lg flex justify-center items-center`}
                          >
                            {content.pointsCompleted === content.totalPoints ? (
                              <>{content.totalPoints} P</>
                            ) : (
                              <>
                                {content.pointsCompleted}/{content.totalPoints}{" "}
                                P
                              </>
                            )}
                          </Text>

                          <Button
                            onClick={() =>
                              navigate(
                                content.progress === 100
                                  ? `/user/course/${content.courseId}`
                                  : `/user/course/${content.courseId}/lesson`
                              )
                            }
                            className={`${
                              content.progress === 100 ? "w-2/3" : "w-1/2"
                            } h-[30px] bg-primary-600 text-white rounded-lg text-[13px]`}
                          >
                            {content.progress === 100
                              ? "View Lessons"
                              : "Continue"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Card>
                )
              )
            ) : (
              <div className="flex items-center w-full justify-center h-full py-[60px]">
                <EmptyState />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </>
  );
};

export default UserDashboardScreen;
