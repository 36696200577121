import React from "react";
import { Typography } from "antd";
import { ReactComponent as LessonIcon } from "../../../../../assets/images/reusable-items/lesson.svg";
import { ReactComponent as DurationIcon } from "../../../../../assets/images/reusable-items/duration.svg";

const { Text } = Typography;

type CourseHeaderProps = {
  title: string;
  lessonCount: number;
  duration: string;
  points: number;
};

const CourseHeader: React.FC<CourseHeaderProps> = ({
  title,
  lessonCount,
  duration,
  points,
}) => (
  <div className="bg-white p-4 rounded-lg shadow flex flex-col lg:flex-row gap-4 lg:gap-10 items-center mb-9">
    <Text className="text-neutral-600 text-2xl font-semibold flex-1 text-center lg:text-left">
      {title}
    </Text>
    <div className="flex gap-4 w-full lg:w-60 justify-center lg:justify-start">
      <div className="flex gap-2 items-center">
        <LessonIcon className="w-6 h-6 fill-primary-300" />
        <Text className="text-neutral-500 text-base">
          {lessonCount} Lessons
        </Text>
      </div>
      <div className="flex gap-2 items-center">
        <DurationIcon className="w-6 h-6 fill-primary-300" />
        <Text className="text-neutral-500 text-base">{duration}</Text>
      </div>
    </div>
    <div className="bg-rose-400 w-full lg:w-48 h-[50px] rounded-lg flex items-center justify-center mt-4 lg:mt-0">
      <Text className="text-white font-semibold text-lg">
        {points} <span className="text-xs">Available PTS</span>
      </Text>
    </div>
  </div>
);

export default CourseHeader;
