import { produce } from "immer";

import { courseTypes as types } from "../actions";
import { LearnerCourseStateType } from "../../../utils";

const defaultState: LearnerCourseStateType = {
  courses: [],
  course: null,
  latestCourse: null,
  loaded: false,
  loading: false,
};

const handleSetCourse = (state: any, payload: any) => {
  state.course = payload.course;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetCourses = (state: any, payload: any) => {
  state.courses = payload.courses;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetLatestCourse = (state: any, payload: any) => {
  state.latestCourse = payload.latestCourse;
  state.loading = false;
  state.loaded = true;
  return state;
};

export default produce(
  (state: LearnerCourseStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_COURSE:
        return handleSetCourses(state, action.payload);
      case types.SET_COURSE_BY_ID:
        return handleSetCourse(state, action.payload);
      case types.SET_LATEST_COURSE:
        return handleSetLatestCourse(state, action.payload);
      case types.GET_COURSE:
        return {
          ...state,
          loading: true,
        };
      case types.GET_COURSE_BY_ID:
        return {
          ...state,
          loading: true,
        };
      case types.GET_LATEST_COURSE:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
