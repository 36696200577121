import { Button, Typography, Modal, Row, Col } from "antd";
import { useEffect, useState } from "react";
import CompanyStructureItems from "../../../assets/images/company-structure-items/company-structure-items";
import { useNavigate } from "react-router-dom";
import CreateEntityInfo from "../../shared/CreateEntityInfo";
import ItemList from "../../shared/CompanyStructure/ItemList";
import ItemForm from "../../shared/CompanyStructure/ItemForm";
import showNotification from "../../../services/notificationService";

const { Text } = Typography;

const DepartmentsScreen = (props: any) => {
  const {
    departments,
    actions: {
      getDepartmentUser,
      addDepartment,
      editDepartment,
      deleteDepartment,
      setCurrentStep,
    },
  } = props;

  const [newDepartment, setNewDepartment] = useState("");
  const [editingDepartment, setEditingDepartment] = useState<any>(null);
  const [editingName, setEditingName] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentStep({ steps: 4, currentStep: 2 });
    getDepartmentUser();
  }, []);

  const handleAddDepartment = async () => {
    if (newDepartment.trim() === "") return;

    const departmentAlreadyExists = departments.some(
      (department: any) =>
        department.name.toLowerCase() === newDepartment.toLowerCase().trim()
    );

    if (departmentAlreadyExists) {
      showNotification("error", "Department name already exists");
      return;
    }

    await addDepartment({ department: { name: newDepartment } });
    setNewDepartment("");
  };

  const handleEditDepartment = (department: any) => {
    setEditingDepartment(department);
    setEditingName(department.name);
  };

  const handleSaveEdit = async () => {
    if (editingName.trim() === "") return;

    await editDepartment({
      id: editingDepartment.id,
      department: { name: editingName },
    });
    setEditingDepartment(null);
    setEditingName("");
  };

  const handleDeleteDepartment = async (id: string) => {
    Modal.confirm({
      title: "Are you sure you want to delete this department?",
      onOk: async () => {
        await deleteDepartment({ id });
      },
    });
  };

  const handleViewUsers = (department: any) => {
    setSelectedDepartment(department);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedDepartment(null);
  };

  return (
    <Row className="h-full  pb-10">
      <Col lg={8}>
        <CreateEntityInfo
          title=""
          text="Step 2 is to define the Company’s Structure by adding Department(s)."
        />
      </Col>
      <Col lg={16} className="gap-4 h-full flex flex-col max-w-[600px]">
        <div>
          <div className="flex gap-4 mb-6">
            <CompanyStructureItems.Step2 />
            <Text className="text-neutral-600 font-normal text-lg">
              Department(s)
            </Text>
          </div>
          <div className="w-full mb-2">
            <Text className="text-neutral-600 font-semibold text-sm">
              Department(s)
            </Text>
          </div>

          <div className="bg-white shadow-md rounded-lg p-6 flex flex-col gap-4">
            <Text className="text-neutral-600 font-normal text-sm">
              Created Department(s)
            </Text>
            <ItemList
              itemType="Department"
              items={departments}
              editingItem={editingDepartment}
              setEditingItem={setEditingDepartment}
              editingName={editingName}
              onEditItem={handleEditDepartment}
              onSaveItem={handleSaveEdit}
              onDeleteItem={handleDeleteDepartment}
              onViewUsers={handleViewUsers}
              onChangeName={(e: any) => setEditingName(e.target.value)}
            />

            <ItemForm
              itemType="Department"
              newItem={newDepartment}
              onChangeNewItem={(e: any) => setNewDepartment(e.target.value)}
              onAddItem={handleAddDepartment}
            />
          </div>
        </div>
        <div className="flex w-full gap-10">
          <Button
            className="flex-1 text-primary-600 font-semibold text-sm border border-primary-200 h-[50px]"
            onClick={() => navigate("/educator/home")}
          >
            Save & Exit
          </Button>
          <Button
            className="flex-1 bg-primary-600 text-white font-semibold text-sm h-[50px]"
            onClick={() => navigate("/educator/position")}
          >
            Continue
          </Button>
        </div>
      </Col>

      <Modal
        title={`Users in ${selectedDepartment?.name}`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="mt-3">
          {selectedDepartment?.users.map((user: any) => (
            <div
              key={user.id}
              className="w-full h-[50px] bg-neutral-200 rounded-lg flex items-center px-4 mb-1 justify-between"
            >
              <Text className="text-neutral-700 font-semibold text-sm leading-4">
                {user.name || user.email}
              </Text>
            </div>
          ))}
        </div>
      </Modal>
    </Row>
  );
};

export default DepartmentsScreen;
