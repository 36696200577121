export const quizzieTypes = {
  GET_QUIZZIE_BY_ID: "LEARNER_GET_QUIZZIE_BY_ID",
  SET_QUIZZIE_BY_ID: "LEARNER_SET_QUIZZIE_BY_ID",
  GET_QUIZZIE: "LEARNER_GET_QUIZZIE",
  SET_QUIZZIES: "LEARNER_SET_QUIZZIES",
  SUBMIT_QUIZZIE_ANSWERS: "LEARNER_SUBMIT_QUIZZIE_ANSWERS",
};

export const getQuizzieById = (payload: any) => ({
  type: quizzieTypes.GET_QUIZZIE_BY_ID,
  payload,
});

export const setQuizzieById = (payload: any) => ({
  type: quizzieTypes.SET_QUIZZIE_BY_ID,
  payload,
});

export const submitAnswers = (payload: any) => ({
  type: quizzieTypes.SUBMIT_QUIZZIE_ANSWERS,
  payload,
});

export const getQuizzie = () => ({
  type: quizzieTypes.GET_QUIZZIE,
});

export const setQuizzies = (payload: any) => ({
  type: quizzieTypes.SET_QUIZZIES,
  payload,
});
