import { Button, Modal, Typography, Skeleton } from "antd";
import { useEffect } from "react";
import EntityCard from "../../../shared/EntityCard";
import { useNavigate, useParams } from "react-router-dom";
import AddEntity from "../../../shared/AddEntity";
import { ReactComponent as lessonIcon } from "../../../../assets/images/reusable-items/lesson.svg";

const { Text } = Typography;

const CourseDetailsScreen = (props: any) => {
  const {
    course,
    loading,
    actions: { getCourseById, deleteCourse, changePublishStatus },
  } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getCourseById({ id });
  }, []);

  const onDeleteCourse = async () => {
    Modal.confirm({
      title: "Are you sure you want to delete this course?",
      onOk: async () => {
        await deleteCourse({ id, navigate });
      },
    });
  };

  const onChangePublishStatus = async (publish: boolean) => {
    Modal.confirm({
      title: `Are you sure you want to ${
        publish ? "publish" : "unpublish"
      } this course?`,
      onOk: async () => {
        await changePublishStatus({ id, publish, navigate });
      },
    });
  };

  if (loading) return <Skeleton active />;

  return (
    <>
      {course && (
        <div className="flex w-full gap-8 pb-4">
          <div className="w-full">
            <Text className="text-neutral-600 font-semibold text-lg">
              Course Overview
            </Text>
            <div className="mt-3">
              <div className="bg-white rounded-lg p-6">
                <Text className="text-neutral-600 font-semibold text-sm">
                  Title
                </Text>
                <div className="w-100 mt-2 mb-6 h-[50px] bg-neutral-100 p-4 text-neutral-600 text-base font-semibold rounded-lg">
                  <Text>{course.title}</Text>
                </div>

                <Text className="text-neutral-600 font-semibold text-sm">
                  Description
                </Text>
                <div className="w-100 mt-2 mb-6 h-[156px] bg-neutral-100 p-4 text-neutral-600 text-base font-normal rounded-lg overflow-auto">
                  <Text>{course.description}</Text>
                </div>

                <Text className="text-neutral-600 font-semibold text-sm">
                  Cover Image
                </Text>

                <div>
                  <img
                    src={
                      course?.coverImageKey &&
                      `${process.env.REACT_APP_CLOUDFRONT_KEY}/${course?.coverImageKey}`
                    }
                    alt="cover"
                    className="rounded-lg w-full  mt-2 mb-6"
                  />
                </div>
              </div>
              <div className="w-100 flex flex-row justify-between mt-4  text-base font-semibold">
                <Button
                  type="default"
                  className={
                    "w-1/4 h-[46px] " +
                    (course.isPublished ? "bg-neutral-300" : "")
                  }
                  onClick={() => onChangePublishStatus(!course.isPublished)}
                >
                  {course.isPublished ? "Published" : "Publish"}
                </Button>
                <div className="w-1/2 flex gap-4">
                  <Button
                    type="primary"
                    className="w-2/4 h-[46px]"
                    onClick={() => navigate(`/educator/course/${id}/edit`)}
                  >
                    Edit
                  </Button>
                  <Button
                    danger
                    type="primary"
                    className="w-2/4 h-[46px]"
                    onClick={onDeleteCourse}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <Text className="text-neutral-600 font-semibold text-lg">
              Selected Lessons
            </Text>
            <div className="module-details-max-height flex flex-col gap-4 mt-3">
              {course.lessons?.length > 0 ? (
                course.lessons.map((lesson: any) => (
                  <EntityCard
                    key={lesson.id}
                    title={lesson.title}
                    duration={lesson.duration || "0 hr 0 min"}
                    coverImageKey={lesson?.coverImageKey || ""}
                    footer={
                      <Button
                        type="primary"
                        className="w-full h-6 bg-primary-600 "
                        key="view"
                        onClick={() =>
                          navigate(`/educator/lesson/${lesson.id}`)
                        }
                      >
                        View
                      </Button>
                    }
                    classes="w-full max-w-[507px] h-[200px]"
                  />
                ))
              ) : (
                <div>
                  <div className="h-full w-full flex items-center justify-center mt-2">
                    <AddEntity
                      firstButtonText="Select Lessons"
                      firstButtonOnClick={() =>
                        navigate(
                          `/educator/course/${course.id}/assign-lessons`,
                          {
                            state: { isEdit: true, course },
                          }
                        )
                      }
                      Icon={lessonIcon}
                      cardClasses="shadow-md max-w-[583px] w-full max-h-[400px] p-[50px]"
                      imageClasses="w-[150px] text-center mb-10"
                    />{" "}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseDetailsScreen;
