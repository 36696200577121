import {
  Form,
  Input,
  Upload,
  Button,
  Typography,
  Progress,
  Modal,
  UploadFile,
  Card,
  Skeleton,
} from "antd";
import { ReactComponent as UploadIcon } from "../../../../assets/images/upload.svg";
import { ReactComponent as UploadVideoIcon } from "../../../../assets/images/upload-video.svg";
import { ReactComponent as UploadPDFIcon } from "../../../../assets/images/upload-pdf.svg";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { QuizProps } from "../../../../utils";
import showNotification from "../../../../services/notificationService";
import axios from "axios";
import * as tus from "tus-js-client";

const { Text } = Typography;
const { TextArea } = Input;

const EditLessonScreen = (props: any) => {
  const {
    lesson,
    quizzes,
    lessonLoading,
    quizzesLoading,
    actions: {
      getLessonById,
      setInitialSteps,
      assignQuiz,
      getQuiz,
      editLesson,
    },
  } = props;

  const [fileList, setFileList] = useState<any>([]);
  const [videoFileList, setVideoFileList] = useState<any>([]);
  const [pdfFileList, setPdfFileList] = useState<any>([]);
  const [progress, setProgress] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isQuizModalVisible, setIsQuizModalVisible] = useState<boolean>(false);
  const [selectedQuizId, setSelectedQuizId] = useState<string | null>(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setInitialSteps(2);
  }, []);

  useEffect(() => {
    if (id) {
      getLessonById({ id });
    }
  }, [id]);

  useEffect(() => {
    getQuiz();
  }, []);

  useEffect(() => {
    if (id && !!lesson) {
      form.setFieldsValue({
        ...lesson,
      });
    }

    if (lesson?.coverImageKey) {
      setFileList([
        {
          url: `${process.env.REACT_APP_CLOUDFRONT_KEY}/${lesson.coverImageKey}`,
        },
      ]);
    }
    if (lesson?.videoUri) {
      setVideoFileList([
        {
          url: getBestQualityVideo(lesson.files),
        },
      ]);
    }
    if (lesson?.fileKey) {
      setPdfFileList([
        {
          url: `${process.env.REACT_APP_CLOUDFRONT_KEY}/${lesson.fileKey}`,
          name: lesson.fileKey?.split("-")[
            lesson.fileKey?.split("-").length - 1
          ],
        },
      ]);
    }
    if (lesson?.quiz) {
      setSelectedQuizId(lesson?.quiz?.id);
    }
  }, [lesson]);

  const createVimeoUploadLink = async (fileSize: number) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VIMEO_API_BASE_URL}`,
        {
          upload: {
            approach: "tus",
            size: fileSize.toString(),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
            "Content-Type": "application/json",
            Accept: "application/vnd.vimeo.*+json;version=3.4",
          },
        }
      );

      return {
        uploadLink: response.data.upload.upload_link,
        videoUri: response.data.uri,
      };
    } catch (error) {
      throw error;
    }
  };

  const uploadVideoToVimeo = async (file: File) => {
    try {
      const { uploadLink, videoUri } = await createVimeoUploadLink(file.size);
      const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB
      return new Promise((resolve, reject) => {
        const upload = new tus.Upload(file, {
          uploadUrl: uploadLink,
          chunkSize: CHUNK_SIZE,
          onError: (error) => {
            reject(error);
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            const percentage = bytesUploaded / bytesTotal;
            setProgress(Math.round(percentage * 100));
          },
          onSuccess: async () => {
            try {
              const isUploadComplete = await verifyUpload(uploadLink);

              if (isUploadComplete) {
                resolve(videoUri);
              } else {
                reject("Upload failed");
              }
            } catch (error) {
              reject(error);
            }
          },
        });
        upload.start();
      });
    } catch (error) {
      throw error;
    }
  };

  const verifyUpload = async (uploadLink: string) => {
    try {
      const response = await axios.head(uploadLink, {
        headers: {
          "Tus-Resumable": "1.0.0",
          Accept: "application/vnd.vimeo.*+json;version=3.4",
        },
      });

      const uploadLength = response.headers["upload-length"];
      const uploadOffset = response.headers["upload-offset"];

      return uploadLength === uploadOffset;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async ({
    title,
    description,
    points,
  }: {
    title: string;
    description: string;
    points: number;
  }) => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("points", points.toString());
    if (fileList.length > 0 && fileList[0]?.originFileObj) {
      formData.append("coverImage", fileList[0].originFileObj as File);
    }
    if (pdfFileList.length > 0 && pdfFileList[0]?.originFileObj) {
      formData.append("file", pdfFileList[0].originFileObj as File);
    }

    try {
      let videoUri = null as string | null;
      if (videoFileList.length > 0 && videoFileList[0]?.originFileObj) {
        setIsModalVisible(true);
        const videoFile = videoFileList[0].originFileObj as File;
        videoUri = (await uploadVideoToVimeo(videoFile)) as string;
        setIsModalVisible(false);
      }

      if (videoUri) {
        formData.append("videoUri", videoUri);
      } else if (lesson.videoUri) {
        formData.append("videoUri", lesson.videoUri);
      }

      editLesson({ lesson: formData, id, navigate });
    } catch (error) {
      setIsModalVisible(false);
      showNotification("error", "Error uploading video to Vimeo");
    }
  };

  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };

  const handleVideoChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setVideoFileList(fileList);
  };

  const handlePdfChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setPdfFileList(fileList);
  };

  const handleRemoveVideo = () => {
    setVideoFileList([]);
  };

  const handleCancelModal = () => {
    setIsModalVisible(false);
    setProgress(0);
  };

  const handleAssignQuiz = () => {
    assignQuiz({ lessonId: lesson.id, quizId: selectedQuizId });
    setIsQuizModalVisible(false);
    const quiz = quizzes.find((quiz: any) => quiz.id === selectedQuizId);
    form.setFieldsValue({
      ...lesson,
      quiz: quiz,
    });
  };

  const getSourceVideo = () => {
    const hasVideoUploaded =
      videoFileList?.length > 0 && videoFileList[0]?.originFileObj;
    const hasVideoUrl = getBestQualityVideo(lesson.files);
    return hasVideoUploaded
      ? URL.createObjectURL(videoFileList[0].originFileObj as Blob)
      : hasVideoUrl || "";
  };

  const getBestQualityVideo = (files: any[]) => {
    if (files.length === 0) return null;
    const sortedFiles = [...files].sort((a, b) => {
      const qualityOrder = ["sd", "hd"];
      const renditionOrder = [
        "240p",
        "360p",
        "540p",
        "720p",
        "1080p",
        "adaptive",
      ];

      const qualityComparison =
        qualityOrder.indexOf(a.quality) - qualityOrder.indexOf(b.quality);
      if (qualityComparison !== 0) return qualityComparison;

      return (
        renditionOrder.indexOf(a.rendition) -
        renditionOrder.indexOf(b.rendition)
      );
    });

    return sortedFiles[sortedFiles.length - 1].link;
  };

  if (lessonLoading || quizzesLoading) return <Skeleton active />;

  return (
    <div>
      <Form
        onFinish={handleSubmit}
        form={form}
        className="flex flex-row pb-10 gap-10"
      >
        <div className="flex-1">
          <Text className="text-neutral-600 text-sm font-semibold">Title</Text>
          <Form.Item
            name="title"
            className="mt-2"
            rules={[{ required: true, message: "Please input the title!" }]}
          >
            <Input placeholder="Type the title here" />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Description
          </Text>
          <Form.Item
            name="description"
            className="mt-2"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
          >
            <TextArea placeholder="Add description here" rows={10} />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">Points</Text>
          <Form.Item
            name="points"
            className="mt-2"
            rules={[{ required: true, message: "Please input the points!" }]}
          >
            <Input type="number" placeholder="Points" />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Cover Image
          </Text>

          <Upload
            name="coverImage"
            listType="picture-card"
            maxCount={1}
            beforeUpload={() => false}
            accept="image/png, image/jpeg, image/gif"
            className="bg-white rounded-md h-[310px] mt-2"
            fileList={fileList}
            onChange={handleChange}
          >
            {fileList.length === 0 && (
              <div className="flex flex-col items-center justify-center">
                <UploadIcon />
                <p className="text-neutral-600 text-base font-medium">
                  Upload a cover image
                </p>
                <p className="text-xs text-neutral-500 font-normal">
                  PNG, JPG, GIF up to 5MB
                </p>
              </div>
            )}
          </Upload>
        </div>
        <div className="flex-1 flex flex-col justify-between mt-[100px] gap-3">
          <div>
            <Text className="text-neutral-600 text-sm font-semibold">
              Upload Video
            </Text>
            <Upload
              name="video"
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
              accept="video/mp4"
              className="bg-white rounded-md h-[310px] mt-2"
              fileList={videoFileList}
              onChange={handleVideoChange}
              showUploadList={false} // Sakrij default listu fajlova
            >
              {videoFileList.length === 0 ? (
                <div className="flex flex-col items-center justify-center">
                  <UploadVideoIcon />
                  <p className="text-neutral-600 text-base font-medium">
                    Upload a video
                  </p>
                  <p className="text-xs text-neutral-500 font-normal">
                    MP4 up to 50MB
                  </p>
                </div>
              ) : (
                <div className="relative flex justify-center items-center w-full h-full">
                  <video
                    src={getSourceVideo()}
                    controls
                    className="w-full h-full object-cover"
                  />
                  <Button
                    onClick={handleRemoveVideo}
                    className="absolute top-2 right-2 bg-red-500 text-white"
                  >
                    Remove
                  </Button>
                </div>
              )}
            </Upload>
          </div>
          <div>
            <Text className="text-neutral-600 text-sm font-semibold">
              Upload PDF
            </Text>
            <Upload
              name="pdf"
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
              accept="application/pdf"
              className="bg-white rounded-md h-[310px] mt-2"
              fileList={pdfFileList}
              onChange={handlePdfChange}
              iconRender={() => (
                <div className="w-full h-full flex items-center justify-center ">
                  <UploadPDFIcon />
                </div>
              )}
            >
              {pdfFileList.length === 0 && (
                <div className="flex flex-col items-center justify-center">
                  <UploadPDFIcon />
                  <p className="text-neutral-600 text-base font-medium">
                    Upload a PDF
                  </p>
                  <p className="text-xs text-neutral-500 font-normal">
                    PDF up to 10MB
                  </p>
                </div>
              )}
            </Upload>
          </div>
          <Form.Item name="quiz">
            <Text className="text-neutral-600 font-semibold text-sm">Quiz</Text>
            <div className="w-full mt-2 mb-6 bg-white p-3 text-neutral-600 text-base font-semibold rounded-lg flex items-center justify-between">
              {form?.getFieldsValue()?.quiz?.title ? (
                <Text>{form?.getFieldsValue()?.quiz?.title}</Text>
              ) : (
                <div>No Quiz Added</div>
              )}
              <Button
                type="primary"
                className="bg-primary-600 text-white"
                onClick={() => setIsQuizModalVisible(true)}
              >
                {form?.getFieldsValue()?.quiz?.title
                  ? "Change Quiz"
                  : "Select Quiz"}
              </Button>
            </div>
          </Form.Item>
          <div className="w-100 flex flex-row justify-end">
            <Form.Item className="w-2/4 mb-0">
              <Button
                type="primary"
                htmlType="submit"
                className="w-full bg-primary-600 h-[50px] font-semibold text-base mb-0"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>

      <Modal
        title="Upload Progress"
        open={isModalVisible}
        footer={null}
        closable={true}
        onCancel={handleCancelModal}
      >
        <Progress percent={progress} />
      </Modal>

      <Modal
        title="Select Quiz"
        open={isQuizModalVisible}
        footer={null}
        closable={true}
        onCancel={handleCancelModal}
        width={900}
      >
        <div className="grid grid-cols-3 gap-4 mt-4">
          {quizzes &&
            quizzes?.length > 0 &&
            quizzes.map((quiz: QuizProps) => (
              <Card
                key={quiz.id}
                className="max-w-[350px]  p-4 flex items-center"
              >
                <div className="flex flex-col justify-between h-full">
                  <Text className="text-base font-medium">{quiz.title}</Text>

                  <Text className="text-sm text-neutral-600">
                    Questions: {quiz.questions.length}
                  </Text>
                  <Text className="text-sm text-neutral-600">
                    Minimum Correct Answers: {quiz.minimumCorrectAnswers}
                  </Text>
                  <Text className="text-sm text-neutral-600">
                    Mandatory: {quiz.isMandatory ? "Yes" : "No"}
                  </Text>

                  <Button
                    className={`mt-4 ${
                      selectedQuizId === quiz.id
                        ? "bg-rose-400 text-white"
                        : "bg-primary-600 text-white"
                    }`}
                    onClick={() => setSelectedQuizId(quiz.id)}
                  >
                    {selectedQuizId === quiz.id ? "Selected" : "Select"}
                  </Button>
                </div>
              </Card>
            ))}
        </div>
        <div className="w-full flex justify-end mt-6">
          <Button
            size="large"
            disabled={!selectedQuizId}
            type="primary"
            onClick={handleAssignQuiz}
          >
            Add Quiz
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default EditLessonScreen;
