export const positionTypes = {
  ADD_POSITION: "ADD_POSITION",
  GET_POSITION: "GET_POSITION",
  GET_POSITION_BY_ID: "GET_POSITION_BY_ID",
  DELETE_POSITION: "DELETE_POSITION",
  SET_POSITION: "SET_POSITION",
  SET_POSITION_BY_ID: "SET_POSITION_BY_ID",
  EDIT_POSITION: "EDIT_POSITION",
  GET_POSITION_USER: "GET_POSITION_USER",
  SET_POSITION_USER: "SET_POSITION_USER",
};

export const addPosition = (payload: any) => ({
  type: positionTypes.ADD_POSITION,
  payload,
});

export const getPosition = () => ({
  type: positionTypes.GET_POSITION,
});

export const setPosition = (payload: any) => ({
  type: positionTypes.SET_POSITION,
  payload,
});

export const getPositionById = (id: any) => ({
  type: positionTypes.GET_POSITION_BY_ID,
  payload: id,
});

export const setPositionById = (payload: any) => ({
  type: positionTypes.SET_POSITION_BY_ID,
  payload,
});

export const deletePosition = (payload: any) => ({
  type: positionTypes.DELETE_POSITION,
  payload,
});

export const editPosition = (payload: any) => ({
  type: positionTypes.EDIT_POSITION,
  payload,
});

export const getPositionUser = () => ({
  type: positionTypes.GET_POSITION_USER,
});

export const setPositionUser = (payload: any) => ({
  type: positionTypes.SET_POSITION_USER,
  payload,
});
