const course = "COURSE";
const module = "MODULE";
const alert = "ALERT";
const quizzie = "QUIZZIE";

const newsFeedType = {
  course,
  module,
  alert,
  quizzie,
};

export { newsFeedType, course, module, alert, quizzie };
