import { Outlet } from "react-router-dom";
import { Col, Row, Image } from "antd";
import logoImage from "../../assets/images/logo-large.svg";
import { useEffect } from "react";
import StorageService from "../../services/storageService";

const AuthenticationLayout = () => {
  useEffect(() => {
    clearTokens();
  });

  const clearTokens = () => {
    StorageService.clearStorage();
  };

  return (
    <Row className="bg-neutral-100">
      <Col span={12} className="flex items-center justify-center">
        <Outlet />
      </Col>
      <Col span={12} className="bg-white h-screen flex justify-center items-center">
        <img src={logoImage} alt="auth-image" />
      </Col>
    </Row>
  );
};

export default AuthenticationLayout;
