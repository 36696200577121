import { useState, useEffect } from "react";
import { Col, Row, Form, Skeleton } from "antd";

import QuizForm from "../../Quiz/Create/Form/QuizForm";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import { useNavigate, useParams } from "react-router-dom";
import QuizzieStructure from "../Create/Structure/QuizzieStructure";

const QuizzieDetails = (props: any) => {
  const {
    actions: {
      setInitialSteps,
      setNextStep,
      setPreviousStep,
      addQuizzie,
      getQuizzieById,
    },
    quizzie,
    loading,
  } = props;

  const [quizStep, setQuizStep] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setInitialSteps(2);
  }, []);

  useEffect(() => {
    if (id) {
      getQuizzieById({ id });
    }
  }, [id]);

  useEffect(() => {
    if (id && !!quizzie) {
      setFormValues({
        ...quizzie,
      });
      form.setFieldsValue({
        ...quizzie,
      });
    }
  }, [quizzie]);

  const onFinishQuiz = () => {
    addQuizzie({ quizzie: formValues, navigate });
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {quizzie && (
        <Form
          form={form}
          name="quizForm"
          autoComplete="off"
          size="small"
          className="h-full"
          initialValues={{
            questions: [
              {
                question: "",
                answers: [
                  {
                    answer: "",
                    isCorrect: false,
                  },
                ],
              },
            ],
          }}
          onFinish={onFinishQuiz}
          onValuesChange={() =>
            setFormValues((prevState: any) => ({
              ...prevState,
              questions: form.getFieldsValue()?.questions,
              title: form.getFieldsValue()?.title,
            }))
          }
        >
          {quizStep === 1 ? (
            <Row>
              <Col lg={8}>
                <CreateEntityInfo
                  title="Test your students"
                  text="Create a Quizzie by adding the questions and related answers. Once you finalize all questions proceed to Finish Quizzie."
                ></CreateEntityInfo>
              </Col>
              <Col lg={16}>
                <QuizForm
                  form={form}
                  setNextStep={setNextStep}
                  setQuizStep={setQuizStep}
                  isEdit={true}
                  quiz={quizzie}
                  isQuizzie={true}
                ></QuizForm>
              </Col>
            </Row>
          ) : (
            <QuizzieStructure
              form={form}
              formValues={formValues}
              setFormValues={setFormValues}
              setQuizStep={setQuizStep}
              setPreviousStep={setPreviousStep}
            ></QuizzieStructure>
          )}
        </Form>
      )}
    </>
  );
};

export default QuizzieDetails;
