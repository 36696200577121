import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const getQuizzieByIdService = async (id: string) =>
  handleApiCall(() => ApiService.get(apiRoutes.quizzieById.replace(":id", id)));

export const getQuizzieService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.quizzie));

export const submitQuizzieAnswersService = async (id: string, data: any) =>
  handleApiCall(() =>
    ApiService.post(apiRoutes.quizzieSubmitAnswers.replace(":id", id), data)
  );
