import { call, all, put, takeLatest, take } from "redux-saga/effects";

import {
  quizzieTypes as types,
  setQuizzieById,
  setQuizzies,
} from "../actions/quizzie";
import { AxiosResponse } from "axios";
import {
  getQuizzieByIdService,
  submitQuizzieAnswersService,
  getQuizzieService,
} from "../../../services/learner/quizzie";
import { EntityIdPayload } from "../../../utils";

export default function* educatorQuizzieSagas() {
  yield all([
    takeLatest(types.GET_QUIZZIE_BY_ID, handleGetQuizzieById),
    takeLatest(types.SUBMIT_QUIZZIE_ANSWERS, handleSubmitAnswers),
    takeLatest(types.GET_QUIZZIE, handleGetQuizzies),
  ]);
}

function* handleGetQuizzieById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getQuizzieByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setQuizzieById({ quizzie: data }));
  }
}

function* handleSubmitAnswers(action: any) {
  const { data }: AxiosResponse = yield call(
    submitQuizzieAnswersService,
    action.payload.id,
    action.payload.answers
  );

  action.payload.navigate("/user/quizzie/results", {
    state: {
      passed: data.passed,
      id: action.payload.quizzie.id,
      points: action.payload.quizzie.points,
      minimumCorrectAnswers: action.payload.quizzie.minimumCorrectAnswers,
    },
  });
}

function* handleGetQuizzies() {
  const { data }: AxiosResponse = yield call(getQuizzieService);
  if (data) {
    yield put(setQuizzies({ quizzies: data }));
  }
}
