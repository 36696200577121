import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { addQuizzie, getQuizzieById } from "../../../../redux/educator/actions";
import {
  selectQuizzie,
  selectQuizzesLoading,
} from "../../../../redux/educator/selector";
import { StateType } from "../../../../utils";
import {
  setInitialSteps,
  setNextStep,
  setPreviousStep,
} from "../../../../redux/shared/actions";

const mapStateToProps = (state: StateType) => ({
  quizzie: selectQuizzie(state),
  loading: selectQuizzesLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      setInitialSteps,
      setNextStep,
      setPreviousStep,
      addQuizzie,
      getQuizzieById,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
