import { produce } from "immer";

import { EducatorStateType, SetStatisticsOverallPayload } from "../../../utils";
import { statisticsTypes as types } from "../actions";

const defaultState: EducatorStateType = {
  statistics: null,
};

const handleSetStatisticsOverall = (
  state: EducatorStateType,
  payload: SetStatisticsOverallPayload
) => {
  state.statistics = payload;
  return state;
};

export default produce((state: EducatorStateType = defaultState, action) => {
  switch (action.type) {
    case types.SET_STATISTICS_OVERALL_ACTION:
      return handleSetStatisticsOverall(state, action.payload);
    default:
      return state;
  }
});
