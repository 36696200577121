import { createSelector } from "@reduxjs/toolkit";

import { StateType } from "../../../utils";
import { CompanyStateType } from "../../../utils/types/Educator/company/CompanyState";

const selectCompanyInfoState = (state: StateType): CompanyStateType =>
  state.educatorCompanyReducer;

export const selectCompanyInfo = createSelector(
  selectCompanyInfoState,
  (state: CompanyStateType) => state.companyInfo
);
