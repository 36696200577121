import { Button, Image } from "antd";
import logo from "../../../assets/images/logo-large.svg";
import { useNavigate } from "react-router-dom";
import { ChooseRoleProps } from "../../../utils";
import { ReactComponent as EducatorIcon } from "../../../assets/images/educator.svg";
import { ReactComponent as UserIcon } from "../../../assets/images/user.svg";

const ChooseRole = (props: ChooseRoleProps) => {
  const {
    actions: { chooseRole },
  } = props;

  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-[350px] flex flex-col gap-10">
        <Image src={logo} alt="logo" preview={false} />
        <div className="flex justify-between">
          <Button
            onClick={() => {
              chooseRole({ role: "EDUCATOR", navigate });
            }}
            className="text-base leading-4 py-5 font-semibold text-neutral-600 bg-neutral-100 w-[170px] border-none"
          >
            <EducatorIcon />
            Educator
          </Button>

          <Button
            onClick={() => {
              chooseRole({ role: "USER", navigate });
            }}
            className="text-base leading-4 py-5 font-semibold text-neutral-600 bg-neutral-100 w-[170px] border-none"
          >
            <UserIcon />
            User
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChooseRole;
