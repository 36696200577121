import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const addQuizService = async (quizData: FormData) =>
  handleApiCall(() => ApiService.post(apiRoutes.quiz, quizData));

export const getQuizService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.quiz));

export const getQuizByIdService = async (id: string) =>
  handleApiCall(() => ApiService.get(apiRoutes.quizById.replace(":id", id)));

export const deleteQuizByIdService = async (id: string) =>
  handleApiCall(() => ApiService.delete(apiRoutes.quizById.replace(":id", id)));

export const editQuizService = async (id: string, quizData: FormData) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.quizById.replace(":id", id), quizData)
  );

export const assignQuizService = async (lessonId: string, quizId: string) =>
  handleApiCall(() =>
    ApiService.post(
      apiRoutes.lessonAssignQuiz
        .replace(":lessonId", lessonId)
        .replace(":quizId", quizId)
    )
  );

export const generateQuizService = async (lessonId: string) =>
  handleApiCall(() => ApiService.post(apiRoutes.generateQuiz, { lessonId }));
