import { call, all, put, takeLatest } from "redux-saga/effects";

import { AxiosResponse } from "axios";
import {
  newsFeedTypes as types,
  setLearnerNewsFeed,
  deleteNewsFeed,
  updateNewsFeed,
} from "../actions";
import {
  getLearnerNewsFeedService,
  deleteLearnerNewsFeedTypeService,
} from "../../../services/learner/newsFeed";

export default function* learnerNewsFeedSaga() {
  yield all([
    takeLatest(types.GET_LEARNER_NEWS_FEED_ACTION, handleGetLearnerNewsFeed),
    takeLatest(types.DELETE_LEARNER_NEWS_FEED_ACTION, handleDeleteNewsFeedType),
  ]);
}

function* handleGetLearnerNewsFeed() {
  const { data }: AxiosResponse = yield call(getLearnerNewsFeedService);
  yield put(setLearnerNewsFeed(data));
}

function* handleDeleteNewsFeedType(action: any) {
  const { data }: AxiosResponse = yield call(
    deleteLearnerNewsFeedTypeService,
    action.payload.type
  );
  yield put(updateNewsFeed(action.payload.type));
}
