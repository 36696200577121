import { createSelector } from "@reduxjs/toolkit";

import { LearnerModuleStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectModuleState = (state: StateType): LearnerModuleStateType =>
  state.learnerModuleReducer;

export const selectModules = createSelector(
  selectModuleState,
  (state: LearnerModuleStateType) => state.modules
);

export const selectModule = createSelector(
  selectModuleState,
  (state: LearnerModuleStateType) => state.module
);

export const selectModulesLoading = createSelector(
  selectModuleState,
  (state: LearnerModuleStateType) => state.loading
);

export const selectModulesLoaded = createSelector(
  selectModuleState,
  (state: LearnerModuleStateType) => state.loaded
);
