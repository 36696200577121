import { all, spawn } from "redux-saga/effects";

import authSagas from "./auth/sagas";
import educatorStatisticsSaga from "./educator/sagas/statistics";
import educatorQuizSagas from "./educator/sagas/quiz";
import educatorQuizzieSagas from "./educator/sagas/quizzie";
import educatorModuleSagas from "./educator/sagas/module";
import educatorCourseSagas from "./educator/sagas/course";
import educatorCompanyInfoSagas from "./educator/sagas/company";
import educatorDepartmentSagas from "./educator/sagas/department";
import educatorPositionSagas from "./educator/sagas/position";
import educatorLessonSagas from "./educator/sagas/lesson";
import educatorUserSagas from "./educator/sagas/user";
import educatorAlertSagas from "./educator/sagas/alert";
import educatorAnalyticsSagas from "./educator/sagas/analytics";
import educatorAIContentSagas from "./educator/sagas/ai-content";
import learnerQuizSagas from "./learner/sagas/quiz";
import learnerStatisticsSaga from "./learner/sagas/statistics";
import learnerAnalyticsSaga from "./learner/sagas/analytics";
import learnerCourseSagas from "./learner/sagas/course";
import learnerModuleSagas from "./learner/sagas/module";
import learnerLessonSagas from "./learner/sagas/lesson";
import learnerQuizzieSagas from "./learner/sagas/quizzie";
import learnerAlertSagas from "./learner/sagas/alert";
import learnerUserSagas from "./learner/sagas/user";
import learnerCourseStatisticsSaga from "./learner/sagas/courseStatistics";
import learnerNewsFeedSaga from "./learner/sagas/newsFeed";

const sagas = [
  authSagas,
  educatorStatisticsSaga,
  educatorQuizSagas,
  educatorQuizzieSagas,
  educatorModuleSagas,
  educatorCourseSagas,
  educatorCompanyInfoSagas,
  educatorDepartmentSagas,
  educatorPositionSagas,
  educatorLessonSagas,
  educatorUserSagas,
  educatorAlertSagas,
  educatorAnalyticsSagas,
  educatorAIContentSagas,
  learnerQuizSagas,
  learnerStatisticsSaga,
  learnerAnalyticsSaga,
  learnerCourseSagas,
  learnerLessonSagas,
  learnerQuizzieSagas,
  learnerAlertSagas,
  learnerUserSagas,
  learnerModuleSagas,
  learnerCourseStatisticsSaga,
  learnerNewsFeedSaga,
];

export default function* rootSagas() {
  yield all(sagas.map(spawn));
}
