export const aiContentTypes = {
  ADD_VIDEO: "ADD_VIDEO",
  GET_VIDEO: "GET_VIDEO",
  DELETE_VIDEO: "DELETE_VIDEO",
  SET_VIDEO: "SET_VIDEO",
  EXTRACT_TEXT: "EXTRACT_TEXT",
  SET_EXTRACT_TEXT: "SET_EXTRACT_TEXT",
};

export const addVideo = (payload: any) => ({
  type: aiContentTypes.ADD_VIDEO,
  payload,
});

export const getVideo = () => ({
  type: aiContentTypes.GET_VIDEO,
});

export const setVideo = (payload: any) => ({
  type: aiContentTypes.SET_VIDEO,
  payload,
});

export const deleteVideo = (payload: any) => ({
  type: aiContentTypes.DELETE_VIDEO,
  payload,
});

export const extractText = (payload: any) => ({
  type: aiContentTypes.EXTRACT_TEXT,
  payload,
});

export const setExtractText = (payload: any) => ({
  type: aiContentTypes.SET_EXTRACT_TEXT,
  payload,
});
