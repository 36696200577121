import { Form, Input, Button, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import type { ResetPasswordProps } from "../../../utils/types";

const { Text } = Typography;

const ResetPassword = (props: ResetPasswordProps) => {
  const {
    actions: { resetPassword, sendResetPassword },
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setToken(queryParams.get("token") || "");
    setEmail(queryParams.get("email") || "");
  }, [location.search]);

  useEffect(() => {
    form.setFieldsValue({ email });
  }, [email, form]);

  const onFinishResetPassword = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    resetPassword({
      email,
      password,
      token: token,
      navigate,
    });
  };

  const onFinishSendResetPassword = ({ email }: { email: string }) => {
    sendResetPassword({
      email,
    });
  };

  return (
    <div className="w-[351px]">
      {!token && !email && (
        <div className="flex flex-col gap-3 mb-[50px]">
          <Text className="text-neutral-600 text-xl font-medium">
            Forgot your password?
          </Text>
          <Text className="text-neutral-500 text-sm font-normal">
            Please enter the email address you'd like your password reset
            information sent to
          </Text>
        </div>
      )}
      {token && email && (
        <div className="flex flex-col gap-3 mb-[50px]">
          <Text className="text-neutral-600 text-xl font-medium">
            Reset your password
          </Text>
        </div>
      )}
      <Form
        form={form}
        name="basic"
        onFinish={
          token && email ? onFinishResetPassword : onFinishSendResetPassword
        }
        autoComplete="off"
        size="large"
        initialValues={{ email }}
      >
        <Text className="text-neutral-600 text-sm font-semibold">
          Email address
        </Text>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
          className="mt-2"
        >
          <Input
            type="email"
            autoComplete="off"
            name="email"
            placeholder="Input your Email Adress"
            value={token && email ? email : ""}
            readOnly={token && email ? true : false}
          />
        </Form.Item>

        {token && email && (
          <>
            <Text className="text-neutral-600 text-sm font-semibold">
              Password
            </Text>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your new password!" },
              ]}
              className="mt-2"
            >
              <Input.Password
                type="password"
                autoComplete="off"
                name="Password"
                placeholder="Enter your new password"
              />
            </Form.Item>
            <Text className="text-neutral-600 text-sm font-semibold">
              Confirm
            </Text>
            <Form.Item
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match!"));
                  },
                }),
              ]}
              className="mt-2"
            >
              <Input.Password
                type="password"
                autoComplete="off"
                name="confirm"
                placeholder="Confirm your new password"
              />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="w-full bg-primary-600 h-[50px] text-base leading-[18px] font-semibold"
          >
            {token && email ? "Reset Password" : "Send reset password link"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPassword;
