import { createSelector } from "@reduxjs/toolkit";

import { LearnerLessonStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectLessonState = (state: StateType): any => state.learnerLessonReducer;

export const selectLessonSummary = createSelector(
  selectLessonState,
  (state: LearnerLessonStateType) => state.lessonSummary
);

export const selectLessonSummaryLoading = createSelector(
  selectLessonState,
  (state: LearnerLessonStateType) => state.loading
);

export const selectLessonPresignedUrl = createSelector(
  selectLessonState,
  (state: LearnerLessonStateType) => state.presignedUrl
);
