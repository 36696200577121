import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  setLesson,
  setLessonById,
  setSummary,
  lessonTypes as types,
} from "../actions";
import { AxiosResponse } from "axios";
import { resetStepper } from "../../shared/actions";
import {
  addLessonService,
  getLessonService,
  getLessonByIdService,
  deleteLessonByIdService,
  editLessonService,
  generateSummaryService,
} from "../../../services/educator/lesson";
import { EntityIdPayload } from "../../../utils";
import showNotification from "../../../services/notificationService";

export default function* educatorLessonSagas() {
  yield all([
    takeLatest(types.ADD_LESSON, handleAddLesson),
    takeLatest(types.GET_LESSON, handleGetLesson),
    takeLatest(types.GET_LESSON_BY_ID, handleGetLessonById),
    takeLatest(types.DELETE_LESSON, handleDeleteLesson),
    takeLatest(types.EDIT_LESSON, handleEditLesson),
    takeLatest(types.GET_GENERATE_SUMMARY, handleGenerateSummary),
  ]);
}

function* handleAddLesson(action: any) {
  const { data }: AxiosResponse = yield call(
    addLessonService,
    action.payload.lesson
  );
  if (data) {
    action.payload.navigate(`/educator/lesson/${data.id}/assign-quiz`);
  }
}

function* handleGetLesson() {
  const { data }: AxiosResponse = yield call(getLessonService);
  if (data) {
    yield put(setLesson({ lessons: data }));
  }
}

function* handleGetLessonById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getLessonByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setLessonById({ lesson: data }));
  }
}

function* handleDeleteLesson(action: any) {
  const { data }: AxiosResponse = yield call(
    deleteLessonByIdService,
    action.payload.id
  );
  if (data) {
    action.payload.navigate("/educator/lesson");
    showNotification("success", "Lesson deleted successfully!");
  }
}

function* handleEditLesson(action: any) {
  const { data }: AxiosResponse = yield call(
    editLessonService,
    action.payload.id,
    action.payload.lesson
  );
  if (data) {
    action.payload.navigate("/success/lesson", {
      state: { isEdit: true },
    });
    yield put(resetStepper());
  }
}

function* handleGenerateSummary(action: any) {
  const { data }: AxiosResponse = yield call(
    generateSummaryService,
    action.payload.id
  );

  if (data) {
    yield put(setSummary(data));
  }
}
