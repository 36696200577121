import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../utils/types";
import { getStatisticsOverall } from "../../../redux/educator/actions";
import { selectStatisticsOverall } from "../../../redux/educator/selector";

const mapStateToProps = (state: StateType) => ({
  statistics: selectStatisticsOverall(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getStatisticsOverall,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
