import { createSelector } from "@reduxjs/toolkit";

import { StateType } from "../../../utils/types/State";
import { HeaderStateType } from "../../../utils/types/Header/HeaderState";

const selectStepperReducer = (state: StateType): HeaderStateType =>
  state.headerReducer;

export const selectStepper = createSelector(
  selectStepperReducer,
  (state: HeaderStateType) => state.steps
);

export const selectTitles = createSelector(
  selectStepperReducer,
  (state: HeaderStateType) => state.titles
);
