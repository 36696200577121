const adminRole = "ADMIN";
const userRole = "USER";
const educatorRole = "EDUCATOR";

const userRoles = {
  adminRole,
  userRole,
  educatorRole,
};

export { userRoles, userRole, educatorRole, adminRole };
