import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import {
  getCourseById,
  restartCourseById,
} from "../../../../redux/learner/actions";
import { StateType } from "../../../../utils/types";
import {
  selectCourse,
  selectCoursesLoading,
} from "../../../../redux/learner/selector";

const mapStateToProps = (state: StateType) => ({
  course: selectCourse(state),
  loading: selectCoursesLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getCourseById,
      restartCourseById,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
