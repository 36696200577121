import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { StateType } from "../../../../utils";
import {
  getCourseById,
  markAsComplete as markLessonAsCompleted,
} from "../../../../redux/learner/actions";
import {
  setCurrentStep,
  setHeaderTitles,
} from "../../../../redux/shared/actions";
import {
  selectCourse,
  selectLessonPresignedUrl,
} from "../../../../redux/learner/selector";
import {
  selectLesson,
  selectLessonLoading,
} from "../../../../redux/educator/selector";
import {
  generateSummary,
  getLessonById,
} from "../../../../redux/educator/actions";
import {
  getPresignedUrl,
  removePresignedUrl,
} from "../../../../redux/learner/actions";
const mapStateToProps = (state: StateType) => ({
  course: selectCourse(state),
  lessonSummary: selectLesson(state)?.summary,
  loading: selectLessonLoading(state),
  presignedUrl: selectLessonPresignedUrl(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getCourseById,
      getLessonById,
      markLessonAsCompleted,
      setCurrentStep,
      setHeaderTitles,
      generateSummary,
      getPresignedUrl,
      removePresignedUrl,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
