import { Typography, Button, Card, Image, Skeleton } from "antd";
import { useState, useEffect } from "react";
import { ReactComponent as CourseIcon } from "../../../../assets/images/reusable-items/course.svg";
import EmptyState from "../../../shared/EmptyState";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const EducatorModulesScreen = (props: any) => {
  const {
    modules,
    loading,
    actions: { getModule },
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredModules, setFilteredModules] = useState(modules);
  const navigate = useNavigate();

  useEffect(() => {
    getModule();
  }, []);

  useEffect(() => {
    setFilteredModules(
      modules.filter((module: any) =>
        module.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, modules]);

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {modules && modules.length > 0 ? (
        <div className="flex">
          <div className="w-1/3 md:p-4 xl:p-0">
            <div className="flex flex-col flex-wrap gap-3 max-w-[200px]">
              <Text className="text-neutral-500  text-base font-normal flex flex-col gap-8">
                <span> Here You can see all your Modules.</span>

                <span>You can also create new Module at any time.</span>
                <Button
                  type="primary"
                  className="w-full xl:w-64 h-[50px] text-base leading-[18px] font-semibold"
                  onClick={() => navigate("/educator/module/create")}
                >
                  Create New Module
                </Button>
              </Text>
            </div>
          </div>
          <div className="w-2/3">
            <div className="flex flex-wrap gap-4 overflow-y-scroll max-h-[calc(100vh-175px)]">
              {filteredModules &&
                filteredModules.map((content: any, index: any) => (
                  <Card
                    key={index}
                    className="w-[calc(50%-0.5rem)] p-3 shadow-md"
                  >
                    <div className="flex gap-4 h-full">
                      <div className="w-5/12 h-[130px]">
                        <Image
                          src={
                            `${process.env.REACT_APP_CLOUDFRONT_KEY}/${content.coverImageKey}` ||
                            ""
                          }
                          preview={false}
                          className="rounded-lg object-cover"
                        />
                      </div>
                      <div className="flex flex-col justify-between w-7/12 gap-4">
                        <Text className="text-base text-neutral-600 font-semibold leading-[18px]">
                          {content.title}
                        </Text>
                        <div>
                          <div className=" flex gap-2 mb-2 ml-1">
                            <CourseIcon
                              height={16}
                              className="fill-primary-400"
                            />

                            <Text className="text-sm text-neutral-500">
                              {content.courseCount} courses
                            </Text>
                          </div>
                          <div className="flex w-full gap-4">
                            <div className="w-full bg-rose-400 border-none text-white rounded-lg h-6 flex flex-row items-baseline gap-1 justify-center">
                              <Text className="text-white text-sm leading-6 font-semibold">
                                {content.points}
                              </Text>
                              <Text className="text-white  text-[10px] leading-3">
                                PTS
                              </Text>
                            </div>
                            <Button
                              onClick={() =>
                                navigate(`/educator/module/${content.id}`)
                              }
                              type="primary"
                              className="h-[24px] w-full bg-primary-600 font-semibold text-sm rounded-lg leading-[16px]"
                            >
                              View
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </>
  );
};

export default EducatorModulesScreen;
