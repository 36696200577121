import { useState, useEffect } from "react";
import { Skeleton } from "antd";
import { useParams } from "react-router-dom";
import CourseHeader from "./components/Header";
import Quiz from "./components/Quiz";
import Lesson from "./components/Lesson";
import { useNavigate } from "react-router-dom";

const Watch = (props: any) => {
  const {
    course,
    lessonSummary,
    loading,
    presignedUrl,
    actions: {
      getCourseById,
      markLessonAsCompleted,
      setCurrentStep,
      setHeaderTitles,
      generateSummary,
      getLessonById,
      getPresignedUrl,
      removePresignedUrl,
    },
  } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const [showQuiz, setShowQuiz] = useState(false);
  const [videoWatched, setVideoWatched] = useState(false);
  const [documentOpened, setDocumentOpened] = useState(false);
  const [currentLesson, setCurrentLesson] = useState<any>(null);
  const [showLessonSummary, setShowLessonSummary] = useState<boolean>(false);
  const [summary, setSummary] = useState("");

  useEffect(() => {
    getCourseById({ id });
  }, [id, getCourseById]);

  useEffect(() => {
    setSummary(lessonSummary);
  }, [lessonSummary]);

  useEffect(() => {
    if (course && course.lessons) {
      const firstUnfinishedLessonIndex = course.lessons.findIndex(
        (lesson: any) => !lesson.isFinished
      );
      const initialIndex =
        firstUnfinishedLessonIndex !== -1 ? firstUnfinishedLessonIndex : 0;
      setCurrentLessonIndex(initialIndex);
      setCurrentLesson(course.lessons[initialIndex]);
      setCurrentStep({
        steps: course.lessons.length,
        currentStep: initialIndex + 1,
      });
      setHeaderTitles({
        titles: {
          course: course.title,
          lesson: course.lessons[initialIndex].title,
          quiz: null,
        },
      });

      getLessonById({ id: course.lessons[initialIndex]?.id });
    }
  }, [course]);

  useEffect(() => {
    if (course && course.lessons) {
      setCurrentLesson(course.lessons[currentLessonIndex]);
      setHeaderTitles({
        titles: {
          course: course.title,
          lesson: course.lessons[currentLessonIndex].title,
          quiz: null,
        },
      });
      setCurrentStep({
        steps: course.lessons.length,
        currentStep: currentLessonIndex + 1,
      });
    }
  }, [currentLessonIndex, course]);

  const handleNextLesson = () => {
    markLessonAsCompleted({
      id: currentLesson.id,
      isLastLesson: currentLessonIndex === course.lessons.length - 1,
      points: course.points,
      navigate,
    });
    setShowQuiz(false);
    setCurrentLessonIndex((prev) => (prev + 1) % course.lessons.length);
    setVideoWatched(false);
    setDocumentOpened(false);
  };

  const handleQuizToggle = () => {
    if (!showQuiz) {
      setHeaderTitles({
        titles: {
          course: course.title,
          lesson: null,
          quiz: currentLesson.quiz.title,
        },
      });
    }
    setShowQuiz(!showQuiz);
  };

  const handleVideoEnd = () => {
    setVideoWatched(true);
  };

  const handleDocumentOpen = () => {
    setDocumentOpened(true);
  };

  const handleGetSummary = () => {
    !summary && generateSummary({ id: currentLesson?.id });
    setShowLessonSummary(true);
  };

  if (!course || !currentLesson) {
    return <Skeleton active />;
  }

  return (
    <div>
      <CourseHeader
        title={course.title}
        lessonCount={course.lessonCount}
        duration={course.duration}
        points={course.points}
      />
      {showQuiz && id ? (
        <Quiz
          id={currentLesson.quiz.id}
          courseId={id}
          isLastLesson={currentLessonIndex === course.lessons.length - 1}
          coursePoints={course.points}
        />
      ) : (
        <Lesson
          lesson={currentLesson}
          handleVideoEnd={handleVideoEnd}
          handleDocumentOpen={handleDocumentOpen}
          handleQuizToggle={handleQuizToggle}
          handleNextLesson={handleNextLesson}
          handleGetSummary={handleGetSummary}
          lessonSummary={summary}
          loading={loading}
          showLessonSummary={showLessonSummary}
          videoWatched={videoWatched}
          documentOpened={documentOpened}
          isLastLesson={currentLessonIndex === course.lessons.length - 1}
          presignedUrl={presignedUrl}
          getPresignedUrl={getPresignedUrl}
          removePresignedUrl={removePresignedUrl}
        />
      )}
    </div>
  );
};

export default Watch;
