export default [
  {
    path: "/module",
    entities: "Courses",
    widthClass: "w-[calc(50%-0.5rem)] max-w-[583px]",
    heightClass: "h-[227px]",
  },
  {
    path: "/course",
    entities: "Lessons",
    widthClass: "w-[calc(50%-0.5rem)] max-w-[583px]",
    heightClass: "max-h-[227px]",
  },
  {
    path: "/lesson",
    entities: "Lessons",
    widthClass: "w-[calc(50%-0.5rem)] max-w-[583px]",
    heightClass: "max-h-[227px]",
  },
  {
    path: "/assign-lessons",
    entities: "",
    icon: "",
    widthClass: "w-[calc(50%-0.5rem)] max-w-[583px]",
    heightClass: "h-[180px]",
  },
];
