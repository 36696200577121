import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Tooltip,
  Divider,
  Col,
  Row,
  Upload,
  UploadFile,
} from "antd";
import {
  PlusCircleFilled,
  CheckCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { ReactComponent as UploadIcon } from "../../../../../assets/images/upload.svg";
import showNotification from "../../../../../services/notificationService";

const { Text } = Typography;

const QuizForm = (props: any) => {
  const {
    form,
    setQuizStep,
    setNextStep,
    isEdit,
    quiz,
    setDeletedImages,
    generateQuizButton,
    isQuizzie,
  } = props;
  const [formValues, setFormValues] = useState({});
  const [fileList, setFileList] = useState<any>([]);

  useEffect(() => {
    if (isEdit && quiz) {
      const imageList = quiz?.questions?.reduce(
        (arr: any, question: any, index: number) => {
          if (question?.imageKey) {
            const image = {
              url: `${process.env.REACT_APP_CLOUDFRONT_KEY}/${question.imageKey}`,
            };
            arr[index] = [image];
          }
          return arr;
        },
        {}
      );
      setFileList(imageList);
    }
  }, [isEdit]);

  const nextStepQuizCreation = () => {
    form
      .validateFields()
      .then(() => {
        const quiz = form.getFieldsValue();

        const isValidFirstStep = validateQuizAnswers(quiz);
        if (isValidFirstStep) {
          setQuizStep(2);
          setNextStep();
        }
      })
      .catch((err: any) => console.error(err));
  };

  const validateQuizAnswers = (quiz: any) => {
    const { questions } = quiz;
    let quizValid = true;

    questions?.forEach((question: any, index: number) => {
      if (!question?.answers || question?.answers?.length === 0) {
        showNotification(
          "error",
          `Question ${index + 1} must have at least one answer.`
        );

        quizValid = false;
        return;
      }

      const hasCorrectAnswer = question.answers.some(
        (answer: any) => answer.isCorrect
      );

      if (!hasCorrectAnswer) {
        showNotification(
          "error",
          `Question ${index + 1} must have at least one correct answer.`
        );

        quizValid = false;
        return;
      }
    });

    return quizValid;
  };

  const toggleisCorrectAnswer = (
    questionIndex: number,
    answerIndex: number
  ) => {
    const formValues = form.getFieldsValue();

    const answers = formValues.questions[questionIndex].answers;
    const answersMapped = answers.map((answer: any, index: number) =>
      index === answerIndex
        ? { ...answer, isCorrect: !answer.isCorrect }
        : answer
    );

    formValues.questions[questionIndex].answers = answersMapped;
    form.setFieldsValue(formValues);
    setFormValues(formValues);
  };

  const setQuestionImage = (
    { fileList }: { fileList: UploadFile[] },
    questionIndex: number
  ) => {
    if (isEdit) {
      if (fileList.length === 0 && quiz?.questions[questionIndex]?.imageKey) {
        setDeletedImages((prevState: any) => [...prevState, questionIndex]);
      } else if (fileList.length > 0) {
        setDeletedImages((prevState: any) =>
          prevState.filter((index: number) => index !== questionIndex)
        );
      }
    }
    setFileList((prevState: any) => ({
      ...prevState,
      [questionIndex]: fileList,
    }));
  };

  return (
    <div style={{ width: "100%", maxWidth: 600 }}>
      <Text className="text-neutral-600 text-base font-semibold">
        Quiz title
      </Text>
      <Form.Item
        name="title"
        rules={[
          {
            required: true,
            message: "Please input your quiz title!",
          },
        ]}
        className="mt-2"
      >
        <Input type="string" placeholder="Type the question title here" />
      </Form.Item>

      <Form.List name="questions">
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Text className="text-neutral-600 text-sm font-semibold flex justify-between">
                  <div>{index + 1}. Question</div>
                  {fields?.length > 1 && (
                    <Tooltip title="Delete question">
                      <Button
                        type="text"
                        className="text-danger-500 mr-3 "
                        shape="circle"
                        icon={<DeleteOutlined style={{ fontSize: "18px" }} />}
                        onClick={() => remove(index)}
                      />
                    </Tooltip>
                  )}
                </Text>
                <Form.Item
                  name={[field.name, "question"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input your question!",
                    },
                  ]}
                  className="mt-2"
                >
                  <Input type="string" placeholder="Type the question here" />
                </Form.Item>

                <Text className="text-neutral-600 text-sm font-semibold">
                  Cover Image
                </Text>

                <Form.Item name={[field.name, "image"]}>
                  <Upload
                    name="image"
                    listType="picture-card"
                    maxCount={1}
                    beforeUpload={() => false}
                    accept="image/png, image/jpeg, image/gif"
                    className="bg-white rounded-md h-[200px] mt-2"
                    fileList={fileList[index]}
                    onChange={(image) => setQuestionImage(image, field.name)}
                  >
                    {(!fileList[index] || fileList[index].length === 0) && (
                      <div className="flex flex-col items-center justify-center">
                        <UploadIcon />
                        <p className="text-neutral-600 text-base font-medium">
                          Upload a cover image
                        </p>
                        <p className="text-xs text-neutral-500 font-normal">
                          PNG, JPG, GIF up to 5MB
                        </p>
                      </div>
                    )}
                  </Upload>
                </Form.Item>

                <Form.List name={[field.name, "answers"]}>
                  {(subFields, subOpt) => (
                    <div>
                      {subFields.map((subField, subIndex) => (
                        <div key={subField.key}>
                          <Text className="text-neutral-600 text-xs font-semibold flex justify-between">
                            <div>Add answer (select only correct answer)</div>
                            <div>
                              {subFields?.length > 1 && (
                                <Tooltip title="Delete answer">
                                  <Button
                                    type="text"
                                    className="text-danger-500 mr-3 "
                                    shape="circle"
                                    icon={
                                      <DeleteOutlined
                                        style={{ fontSize: "18px" }}
                                      />
                                    }
                                    onClick={() => subOpt.remove(subIndex)}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          </Text>
                          <Form.Item
                            name={[subField.name, "answer"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input your answer!",
                              },
                            ]}
                            className="mt-2"
                          >
                            <Input
                              type="string"
                              placeholder="Type the answer here"
                              suffix={
                                <Tooltip title="Set as correct answer">
                                  <CheckCircleFilled
                                    className="text-xl"
                                    style={{
                                      color: form.getFieldValue([
                                        "questions",
                                        field.name,
                                        "answers",
                                        subField.name,
                                        "isCorrect",
                                      ])
                                        ? "#16A34A"
                                        : "gray",
                                    }}
                                    onClick={() =>
                                      toggleisCorrectAnswer(
                                        field.name,
                                        subField.name
                                      )
                                    }
                                  />
                                </Tooltip>
                              }
                            />
                          </Form.Item>
                        </div>
                      ))}
                      <div className="w-full flex justify-center items-center">
                        <Button
                          onClick={() =>
                            subOpt.add({ answer: "", isCorrect: false })
                          }
                          icon={<PlusCircleFilled />}
                          type="link"
                        >
                          Add new answer
                        </Button>
                      </div>
                      <Divider style={{ borderColor: "#BECBFE" }}></Divider>
                    </div>
                  )}
                </Form.List>
              </div>
            ))}
            <Row gutter={[24, 24]} className="my-4">
              <Col sm={24} lg={24}>
                {generateQuizButton}
              </Col>
              <Col sm={24} lg={12}>
                <Button
                  className="w-full h-[50px] text-base  text-primary-600 border-primary-200 font-semibold rounded-lg"
                  onClick={() =>
                    add({
                      answers: [{ answer: "", isCorrect: false }],
                    })
                  }
                >
                  Add new question
                </Button>
              </Col>
              <Col sm={24} lg={12}>
                <Button
                  className="w-full h-[50px] text-base bg-primary-600 font-semibold rounded-lg"
                  type="primary"
                  onClick={nextStepQuizCreation}
                >
                  {isQuizzie
                    ? "Finish quizzie"
                    : isEdit
                    ? "Finish edit quiz"
                    : "Finish quiz"}
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Form.List>
    </div>
  );
};

export default QuizForm;
