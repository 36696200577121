import { Button, Input, Typography, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined, UserOutlined } from "@ant-design/icons";

const { Text } = Typography;

const ItemCard = (props: any) => {
  const {
    item,
    isEditing,
    setEditingItem,
    editingName,
    onEdit,
    onSave,
    onDelete,
    onViewUsers,
    onChangeName,
  } = props;
  return (
    <div className="w-full h-[50px] mb-1">
      {isEditing ? (
        <div className="flex items-center w-full border border-primary-300 rounded-lg">
          <Input
            value={editingName}
            onChange={onChangeName}
            className="flex-1 rounded-lg"
          />
          <div>
            <Button
              onClick={() => setEditingItem(null)}
              className="border-none shadow-none h-[50px]"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={onSave}
              className="border-none shadow-none h-[50px]"
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div className="w-full h-[50px] bg-neutral-100 rounded-lg flex items-center justify-between px-4">
          <Text className="text-neutral-600 font-semibold text-sm leading-4">
            {item.name}
          </Text>
          <div className="flex gap-2">
            <Tooltip title="View Users">
              <Button
                icon={<UserOutlined />}
                onClick={onViewUsers}
                className="border-none shadow-none bg-transparent"
              />
            </Tooltip>
            <Tooltip title="Edit">
              <Button
                icon={<EditOutlined />}
                onClick={onEdit}
                className="border-none shadow-none bg-transparent"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                icon={<DeleteOutlined />}
                onClick={onDelete}
                className="border-none shadow-none bg-transparent"
              />
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemCard;
