import { createSelector } from "@reduxjs/toolkit";

import { LearnerAlertStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectAlertState = (state: StateType): LearnerAlertStateType =>
  state.learnerAlertReducer;

export const selectAlerts = createSelector(
  selectAlertState,
  (state: LearnerAlertStateType) => state.alerts
);

export const selectAlertsLoading = createSelector(
  selectAlertState,
  (state: LearnerAlertStateType) => state.loading
);

export const selectAlertsLoaded = createSelector(
  selectAlertState,
  (state: LearnerAlertStateType) => state.loaded
);
