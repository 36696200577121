import { createSelector } from "reselect";

import { StateType } from "../../../utils/types/State";
import { AuthenticationStateType } from "../../../utils/types/Auth";

const selectAuthState = (state: StateType): AuthenticationStateType =>
  state.authReducer;

export const selectUser = createSelector(
  selectAuthState,
  (state: AuthenticationStateType) => state.user
);

export const selectUserToken = createSelector(
  selectAuthState,
  (state: AuthenticationStateType) => state.token
);
