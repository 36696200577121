import harryBusiness from "./avatar/harry-business.png";
import harryCasual from "./avatar/harry-casual.png";
import harryYouthful from "./avatar/harry-youthful.png";
import jeffBusiness from "./avatar/jeff-business.png";
import jeffFormal from "./avatar/jeff-formal.png";
import lisaCasualSitting from "./avatar/lisa-casual-sitting.png";
import lisaGracefulSitting from "./avatar/lisa-graceful-sitting.png";
import lisaGracefulStanding from "./avatar/lisa-graceful-standing.png";
import lisaTechnicalSitting from "./avatar/lisa-technical-sitting.png";
import lisaTechnicalStanding from "./avatar/lisa-technical-standing.png";
import loriCasual from "./avatar/lori-casual.png";
import loriFormal from "./avatar/lori-formal.png";
import loriGraceful from "./avatar/lori-graceful.png";
import maxBusiness from "./avatar/max-business.png";
import maxCasual from "./avatar/max-casual.png";
import maxFormal from "./avatar/max-formal.png";
import megBusiness from "./avatar/meg-business.png";
import megCasual from "./avatar/meg-casual.png";
import megFormal from "./avatar/meg-formal.png";

export default {
  harryBusiness,
  harryCasual,
  harryYouthful,
  jeffBusiness,
  jeffFormal,
  lisaCasualSitting,
  lisaGracefulSitting,
  lisaGracefulStanding,
  lisaTechnicalSitting,
  lisaTechnicalStanding,
  loriCasual,
  loriFormal,
  loriGraceful,
  maxBusiness,
  maxCasual,
  maxFormal,
  megBusiness,
  megCasual,
  megFormal,
};
