import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  quizzieTypes as types,
  submissionComplete,
  setQuizzies,
  setQuizzieById,
  getQuizzie,
} from "../actions/quizzie";
import { EducatorAddQuizzieActionType, Quizzie } from "../../../utils/types";
import { AxiosResponse } from "axios";
import { resetStepper } from "../../shared/actions";
import {
  addQuizzieService,
  deleteQuizzieByIdService,
  getQuizzieService,
  getQuizzieByIdService,
} from "../../../services/educator/quizzie";
import { EntityIdPayload } from "../../../utils";
import showNotification from "../../../services/notificationService";

export default function* educatorQuizzieSagas() {
  yield all([
    takeLatest(types.ADD_QUIZZIE, handleAddQuizzie),
    takeLatest(types.GET_QUIZZIE, handleGetQuizzie),
    takeLatest(types.DELETE_QUIZZIE, handleDeleteQuizzie),
    takeLatest(types.GET_QUIZZIE_BY_ID, handleGetQuizzieById),
  ]);
}

function* handleAddQuizzie(action: EducatorAddQuizzieActionType) {
  const formData = generateQuizzieFormData(action.payload.quizzie);

  const { data }: AxiosResponse = yield call(addQuizzieService, formData);
  if (data) {
    yield put(submissionComplete());
    action.payload.navigate("/success/quizzie");
    yield put(resetStepper());
  }
}

function* handleDeleteQuizzie(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    deleteQuizzieByIdService,
    action.payload.id
  );
  if (data) {
    showNotification("success", "Quizzie deleted successfully!");
    yield put(getQuizzie());
  }
}

function* handleGetQuizzie() {
  const { data }: AxiosResponse = yield call(getQuizzieService);
  if (data) {
    yield put(setQuizzies({ quizzies: data }));
  }
}

function* handleGetQuizzieById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getQuizzieByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setQuizzieById({ quizzie: data }));
  }
}

const generateQuizzieFormData = (quiz: Quizzie) => {
  const formData = new FormData();

  formData.append("title", quiz.title);
  formData.append("points", quiz.points.toString());
  formData.append(
    "minimumCorrectAnswers",
    quiz.minimumCorrectAnswers.toString()
  );
  if (quiz?.departmentIds && quiz?.departmentIds?.length > 0) {
    quiz.departmentIds.forEach((department) =>
      formData.append("departmentIds[]", department)
    );
  }

  if (quiz?.positionIds && quiz?.positionIds?.length > 0) {
    quiz.positionIds.forEach((position) =>
      formData.append("positionIds[]", position)
    );
  }

  if (quiz?.userIds && quiz?.userIds?.length > 0) {
    quiz.userIds.forEach((user) => formData.append("userIds[]", user));
  }

  quiz.questions.forEach((q, index) => {
    formData.append(`questions[${index}][question]`, q.question);
    q.answers.forEach((a, i) => {
      formData.append(`questions[${index}][answers][${i}][answer]`, a.answer);
      formData.append(
        `questions[${index}][answers][${i}][isCorrect]`,
        a.isCorrect ? "true" : "false"
      );
    });

    if (q?.image?.file) {
      const extension = q?.image?.fileList[0]?.type.split("/")[1];
      const imageName = `q${index + 1}.${extension}`;
      const renamedFile = new File(
        [q?.image?.fileList[0].originFileObj],
        imageName,
        {
          type: q?.image?.fileList[0]?.type,
        }
      );

      formData.append("images", renamedFile);
    }
  });

  return formData;
};
