import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import {
  setInitialSteps,
  setNextStep,
  setPreviousStep,
} from "../../../../redux/shared/actions";
import { StateType } from "../../../../utils/types";
import {
  getQuizById,
  editQuiz,
  generateQuiz,
} from "../../../../redux/educator/actions";
import {
  selectQuiz,
  selectQuizzesLoading,
  selectQuizzesGenerateLoading,
} from "../../../../redux/educator/selector";

const mapStateToProps = (state: StateType) => ({
  quiz: selectQuiz(state),
  loading: selectQuizzesLoading(state),
  generateLoading: selectQuizzesGenerateLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      setInitialSteps,
      setNextStep,
      setPreviousStep,
      getQuizById,
      editQuiz,
      generateQuiz,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
