import {
  Form,
  Input,
  Upload,
  Button,
  Typography,
  UploadFile,
  Row,
  Col,
} from "antd";
import { ReactComponent as UploadIcon } from "../../../../assets/images/upload.svg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import DepartmentSelector from "../../../shared/DepartmentSelector";
import PositionSelector from "../../../shared/PositionSelector";

const { Text } = Typography;
const { TextArea } = Input;

const CreateCourseScreen = (props: any) => {
  const {
    actions: { addCourse, setInitialSteps },
  } = props;

  const [fileList, setFileList] = useState<any>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setInitialSteps(2);
  }, []);

  const handleSubmit = ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    if (fileList.length > 0) {
      formData.append("coverImage", fileList[0].originFileObj as File);
    }

    selectedDepartments.forEach((departmentId: string) => {
      formData.append("departmentIds", departmentId);
    });
    selectedPositions.forEach((positionId: string) => {
      formData.append("positionIds", positionId);
    });

    addCourse({ course: formData, navigate });
  };

  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };

  const handleDepartmentChange = (departments: string[]) => {
    setSelectedDepartments(departments);
  };

  const handlePositionChange = (positions: string[]) => {
    setSelectedPositions(positions);
  };

  return (
    <Row className="h-full pb-[60px]">
      <Col lg={8}>
        <CreateEntityInfo
          title=""
          text="Create a New Course by adding title, description and a cover image."
        />
      </Col>
      <Col
        lg={16}
        className="h-full flex flex-col justify-between max-w-[600px]"
      >
        <Form layout="vertical" onFinish={handleSubmit}>
          <Text className="text-neutral-600 text-sm font-semibold">Title</Text>
          <Form.Item
            name="title"
            className="mt-2"
            rules={[{ required: true, message: "Please input the title!" }]}
          >
            <Input placeholder="Type the title here" />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Description
          </Text>
          <Form.Item
            name="description"
            className="mt-2"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
          >
            <TextArea placeholder="Add description here" rows={10} />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Cover Image
          </Text>

          <Upload
            name="coverImage"
            listType="picture-card"
            maxCount={1}
            beforeUpload={() => false}
            accept="image/png, image/jpeg, image/gif"
            className="bg-white rounded-md h-[310px] mt-2 mb-6"
            fileList={fileList}
            onChange={handleChange}
          >
            {fileList.length === 0 && (
              <div className="flex flex-col items-center justify-center">
                <UploadIcon />
                <p className="text-neutral-600 text-base font-medium">
                  Upload a cover image
                </p>
                <p className="text-xs text-neutral-500 font-normal">
                  PNG, JPG, GIF up to 5MB
                </p>
              </div>
            )}
          </Upload>

          <Text className="text-neutral-600 text-sm font-semibold">
            Assign Course
          </Text>

          <div className="mt-2 flex flex-col gap-2">
            <DepartmentSelector
              onSelectionChange={handleDepartmentChange}
              selectedDepartments={selectedDepartments}
              setSelectedDepartments={setSelectedDepartments}
            />
            <PositionSelector
              onSelectionChange={handlePositionChange}
              selectedPositions={selectedPositions}
              setSelectedPositions={setSelectedPositions}
            />
          </div>

          <div className="w-full flex justify-end mt-5">
            <Form.Item className="w-2/4">
              <Button
                type="primary"
                htmlType="submit"
                className="w-full bg-primary-600 h-[50px] font-semibold text-base mt-3"
              >
                Continue
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default CreateCourseScreen;
