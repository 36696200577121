import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  assignQuiz,
  editLesson,
  getLessonById,
  getQuiz,
} from "../../../../redux/educator/actions";
import { StateType } from "../../../../utils/types";
import {
  selectLesson,
  selectQuizzes,
  selectLessonLoading,
  selectQuizzesLoading,
} from "../../../../redux/educator/selector";
import { setInitialSteps } from "../../../../redux/shared/actions";

const mapStateToProps = (state: StateType) => ({
  lesson: selectLesson(state),
  quizzes: selectQuizzes(state),
  lessonLoading: selectLessonLoading(state),
  quizzesLoading: selectQuizzesLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getLessonById,
      setInitialSteps,
      assignQuiz,
      getQuiz,
      editLesson,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
