import { ReactComponent as Course } from "./reusable-items/course.svg";
import { ReactComponent as Lesson } from "./reusable-items/lesson.svg";
import { ReactComponent as Module } from "./reusable-items/module.svg";
import { ReactComponent as Quiz } from "./reusable-items/quiz.svg";
import { ReactComponent as CompanyStructure } from "./reusable-items/company-structure.svg";
import { ReactComponent as Alert } from "./reusable-items/alert.svg";
import { ReactComponent as Home } from "./reusable-items/home.svg";
import { ReactComponent as Dashboard } from "./reusable-items/dashboard.svg";
import { ReactComponent as Quizzie } from "./reusable-items/quizzie.svg";
import { LineChartOutlined as Benchmark } from "@ant-design/icons";
import { ReactComponent as AI } from "./reusable-items/ai.svg";

const HeaderItems = {
  Quiz,
  Course,
  Lesson,
  Module,
  CompanyStructure,
  Alert,
  Home,
  Quizzie,
  Dashboard,
  Benchmark,
  AI,
};

export default HeaderItems;
