export const lessonTypes = {
  MARK_AS_COMPLETE: "MARK_AS_COMPLETE",
  GET_PRESIGNED_URL: "GET_PRESIGNED_URL",
  SET_PRESIGNED_URL: "SET_PRESIGNED_URL",
  REMOVE_PRESIGNED_URL: "REMOVE_PRESIGNED_URL",
};

export const markAsComplete = (payload: any) => ({
  type: lessonTypes.MARK_AS_COMPLETE,
  payload,
});

export const getPresignedUrl = (payload: any) => ({
  type: lessonTypes.GET_PRESIGNED_URL,
  payload,
});

export const setPresignedUrl = (payload: any) => ({
  type: lessonTypes.SET_PRESIGNED_URL,
  payload,
});

export const removePresignedUrl = () => ({
  type: lessonTypes.REMOVE_PRESIGNED_URL,
});
