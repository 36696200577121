import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const getLearnerNewsFeedService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.newsFeed));

export const deleteLearnerNewsFeedTypeService = async (type: string) =>
  handleApiCall(() =>
    ApiService.patch(apiRoutes.newsFeedByType.replace(":type", type))
  );
