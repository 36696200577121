import { Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import CreateCard from "../../shared/CreateCard";
import educatorCreateCard from "../../../utils/constants/homeCreateCard";
import educatorStatisticsCard from "../../../utils/constants/statisticsCard";
import StatisticsSection from "./StatisticsSection";
import type {
  EducatorHomeScreenProps,
  StatisticsCardProps,
} from "../../../utils/types";

const { Text } = Typography;

const EducatorHomeScreen = (props: EducatorHomeScreenProps) => {
  const {
    statistics,
    actions: { getStatisticsOverall },
  } = props;
  const [statisticsData, setStatisticsData] = useState<StatisticsCardProps[]>(
    []
  );

  useEffect(() => {
    getStatisticsOverall();
  }, []);

  useEffect(() => {
    let data: StatisticsCardProps[] = [];
    educatorStatisticsCard.forEach((item, index) => {
      data.push({ ...item, progress: "" });
    });
    if (statistics) {
      data[0].progress = `${statistics.completedCourses}/${statistics.totalCourses}`;
      data[1].progress = `${statistics.completedLessons}/${statistics.totalLessons}`;
      data[2].progress = `${statistics.completedQuizzes}/${statistics.totalQuizzes}`;
      data[3].progress = `${statistics.totalUsers}`;
      data[4].progress = `${statistics.totalDepartments}`;
      data[5].progress = `${statistics.totalPositions}`;
    }
    setStatisticsData(data);
  }, [statistics]);

  return (
    <div className="flex flex-col gap-10">
      <div className="border-b border-b-primary-200 pb-10">
        <Text className="text-neutral-600 font-semibold text-lg">Create</Text>
        <Row gutter={[16, 16]} className="mt-4">
          {educatorCreateCard.map((content, index) => (
            <Col key={index} xs={24} sm={12} lg={8}>
              <CreateCard
                icon={content.icon}
                title={content.title}
                path={content.path}
              />
            </Col>
          ))}
        </Row>
      </div>
      <div>
        <div className="flex flex-row items-center justify-between">
          <Text className="text-neutral-600 font-semibold text-lg">
            Analytics
          </Text>
          <Link to="/educator/dashboard/module">
            <Text className="text-primary-600 hover:text-primary-800 text-sm font-semibold">
              View All
            </Text>
          </Link>
        </div>
        {statisticsData && (
          <Row className="mt-4" gutter={[24, 24]}>
            <Col lg={12} md={24}>
              <StatisticsSection
                title="My Courses"
                overAllStatistics={statisticsData.slice(0, 3)}
              />
            </Col>
            <Col lg={12} md={24}>
              <StatisticsSection
                title="My Company"
                overAllStatistics={statisticsData.slice(3, 6)}
              />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default EducatorHomeScreen;
