import { ReactComponent as Home } from "./reusable-items/home.svg";
import { ReactComponent as Dashboard } from "./reusable-items/dashboard.svg";
import { ReactComponent as Module } from "./reusable-items/module.svg";
import { ReactComponent as Course } from "./reusable-items/course.svg";
import { ReactComponent as Lesson } from "./reusable-items/lesson.svg";
import { ReactComponent as Alert } from "./reusable-items/alert.svg";
import { ReactComponent as Quiz } from "./reusable-items/quiz.svg";
import { ReactComponent as Quizzie } from "./reusable-items/quizzie.svg";
import { ReactComponent as Create } from "./reusable-items/create.svg";
import { ReactComponent as CompanyStructure } from "./reusable-items/company-structure.svg";
import { ReactComponent as Department } from "./reusable-items/department.svg";
import { ReactComponent as Position } from "./reusable-items/position.svg";
import { ReactComponent as Users } from "./reusable-items/users.svg";
import { ReactComponent as User } from "./reusable-items/user.svg";
import { ReactComponent as AI } from "./reusable-items/ai.svg";
import { ReactComponent as CompanyInfo } from "./reusable-items/company-info.svg";

const MenuItems = {
  Alert,
  CompanyStructure,
  Course,
  Create,
  Dashboard,
  Department,
  Home,
  Lesson,
  Module,
  Position,
  Quizzie,
  Users,
  User,
  Quiz,
  CompanyInfo,
  AI,
};

export default MenuItems;
