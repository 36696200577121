import { Button } from "antd";

const styles = {
  button: {
    background:
      "linear-gradient(#fff, #fff) padding-box ,linear-gradient(-20deg, #d53a9d, rgb(94 334 312)) border-box",
    color: "#4B5563",
    border: "7px solid transparent",
    display: "flex",
  },
};

const AiButton = ({
  disabled,
  handleOnClick,
  content,
  classes,
}: {
  disabled: boolean;
  handleOnClick: () => void;
  content: any;
  classes?: string;
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={handleOnClick}
      style={styles.button}
      className={`text-neutral-600 w-[210px] rounded-full py-5 font-semibold text-lg hover:shadow-[0_0_8px_rgb(79_70_229)] ${classes}`}
    >
      {content}
    </Button>
  );
};

export default AiButton;
