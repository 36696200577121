export const courseTypes = {
  GET_COURSE: "LEARNER_GET_COURSE",
  GET_COURSE_BY_ID: "LEARNER_GET_COURSE_BY_ID",
  SET_COURSE: "LEARNER_SET_COURSE",
  SET_COURSE_BY_ID: "LEARNER_SET_COURSE_BY_ID",
  GET_LATEST_COURSE: "GET_LATEST_COURSE",
  SET_LATEST_COURSE: "SET_LATEST_COURSE",
  RESTART_COURSE_BY_ID: "RESTART_COURSE_BY_ID",
};

export const getCourse = () => ({
  type: courseTypes.GET_COURSE,
});

export const setCourse = (payload: any) => ({
  type: courseTypes.SET_COURSE,
  payload,
});

export const getCourseById = (id: any) => ({
  type: courseTypes.GET_COURSE_BY_ID,
  payload: id,
});

export const setCourseById = (payload: any) => ({
  type: courseTypes.SET_COURSE_BY_ID,
  payload,
});

export const getLatestCourse = () => ({
  type: courseTypes.GET_LATEST_COURSE,
});

export const setLatestCourse = (payload: any) => ({
  type: courseTypes.SET_LATEST_COURSE,
  payload,
});

export const restartCourseById = (id: any) => ({
  type: courseTypes.RESTART_COURSE_BY_ID,
  payload: id,
});
