import { Button, Typography, Card, Skeleton } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { QuizProps } from "../../../../utils/types";
import { ReactComponent as QuizIcon } from "../../../../assets/images/reusable-items/quizzie.svg";

const { Text } = Typography;

const AssignQuizScreen = (props: any) => {
  const {
    quizzes,
    loading,
    actions: { setNextStep, assignQuiz, getQuiz },
  } = props;

  const [showQuizzes, setShowQuizzes] = useState(false);
  const [selectedQuizId, setSelectedQuizId] = useState<string | null>(null);
  const { id: lessonId } = useParams();
  const navigate = useNavigate();

  const handleSelectQuiz = (quizId: string) => {
    setSelectedQuizId(quizId);
  };

  useEffect(() => {
    setNextStep();
    getQuiz();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAssignQuiz = () => {
    assignQuiz({ lessonId, quizId: selectedQuizId, navigate });
  };

  if (loading) return <Skeleton active />;

  return (
    <>
      {!showQuizzes ? (
        <div className="h-full w-full flex items-center justify-center">
          <div className="bg-white rounded-lg w-[582px] h-[606px] p-[100px] flex flex-col items-center justify-between">
            <QuizIcon className="h-[80px] fill-primary-600" />
            <div className="w-[382px] flex flex-col gap-6 items-center">
              <Text className="text-neutral-600 text-base font-medium">
                Do you want to add Quiz to your Lesson?
              </Text>
              <Button
                onClick={() => setShowQuizzes(true)}
                className="w-full h-[50px] border-primary-200 text-primary-600 font-semibold text-base"
              >
                Yes, Add a Quiz
              </Button>
              <Button
                className="w-full h-[50px] bg-primary-600 text-white font-semibold text-base"
                onClick={() => navigate(`/success/lesson`)}
              >
                Publish Lesson without Quiz
              </Button>
            </div>
            <div className="w-[270px] text-center">
              <Text className=" text-neutral-500 text-sm font-normal">
                <b className="font-semibold">Note: </b>If you publish the Lesson
                without the Quiz, you can always add the Quiz later. But without
                the quiz you will not be able to test users (students).
              </Text>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Text className="text-neutral-600 text-lg font-semibold mb-4">
            Select a Quiz to Add
          </Text>
          <div className="grid grid-cols-3 gap-4 mt-4">
            {quizzes &&
              quizzes.map((quiz: QuizProps) => (
                <Card
                  key={quiz.id}
                  className="max-w-[350px]  p-4 flex items-center"
                >
                  <div className="flex flex-col justify-between h-full">
                    <Text className="text-base font-medium">{quiz.title}</Text>

                    <Text className="text-sm text-neutral-600">
                      Questions: {quiz.questions.length}
                    </Text>
                    <Text className="text-sm text-neutral-600">
                      Minimum Correct Answers: {quiz.minimumCorrectAnswers}
                    </Text>
                    <Text className="text-sm text-neutral-600">
                      Mandatory: {quiz.isMandatory ? "Yes" : "No"}
                    </Text>

                    <Button
                      className={`mt-4 ${
                        selectedQuizId === quiz.id
                          ? "bg-rose-400 text-white"
                          : "bg-primary-600 text-white"
                      }`}
                      onClick={() => handleSelectQuiz(quiz.id)}
                    >
                      {selectedQuizId === quiz.id ? "Selected" : "Select"}
                    </Button>
                  </div>
                </Card>
              ))}
          </div>
          <div className="w-full flex justify-end mt-6">
            <Button
              size="large"
              disabled={!selectedQuizId}
              type="primary"
              onClick={handleAssignQuiz}
            >
              Add Quiz
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AssignQuizScreen;
