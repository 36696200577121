import React from "react";
import { Card } from "antd";

import Statistics from "../Home/Statistics";
import Course from "../Home/Course";
import Module from "../Home/Module";
import Analytics from "../Home/Analytics";
import LatestCourse from "../Home/LatestCourse";

const UserHomeScreen = () => {
  return (
    <div>
      <Card className="shadow-md p-5">
        <Statistics />
      </Card>

      <div className="flex gap-7 my-7">
        <Card className="shadow-md p-5 w-full">
          <Module />
        </Card>
        <Card className="shadow-md p-5 w-full">
          <Course />
        </Card>
      </div>

      <div className="flex gap-7 mb-7">
        <Card className="shadow-md p-5 w-full">
          <Analytics />
        </Card>
        <Card className="shadow-md p-5 w-full">
          <LatestCourse />
        </Card>
      </div>
    </div>
  );
};

export default UserHomeScreen;
