import MenuItems from "../../assets/images/menu-items";

const educatorCreateCard = [
  {
    icon: MenuItems.Module,
    title: "Module",
    path: "/educator/module/create",
  },
  {
    icon: MenuItems.Course,
    title: "Course",
    path: "/educator/course/create",
  },
  {
    icon: MenuItems.Lesson,
    title: "Lesson",
    path: "/educator/lesson/create",
  },
  {
    icon: MenuItems.Quizzie,
    title: "Quizzie",
    path: "/educator/quizzie/create",
  },
  {
    icon: MenuItems.Alert,
    title: "Alerts",
    path: "/educator/alert/create",
  },
  {
    icon: MenuItems.Quiz,
    title: "Quiz",
    path: "/educator/quiz/create",
  },
];

export default educatorCreateCard;
