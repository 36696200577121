import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import {
  selectLearnerStatistics,
  selectLearnerStatisticsLoading,
} from "../../../../redux/learner/selector";
import { getLearnerStatistics } from "../../../../redux/learner/actions";

const mapStateToProps = (state: StateType) => ({
  statistics: selectLearnerStatistics(state),
  loading: selectLearnerStatisticsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getLearnerStatistics,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
