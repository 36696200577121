import { combineReducers, Action } from "redux";
import authReducer from "./auth/reducer";
import educatorQuizReducer from "./educator/reducer/quiz";
import educatorQuizzieReducer from "./educator/reducer/quizzie";
import educatorModuleReducer from "./educator/reducer/module";
import educatorCourseReducer from "./educator/reducer/course";
import educatorCompanyReducer from "./educator/reducer/company";
import educatorDepartmentReducer from "./educator/reducer/department";
import educatorPositionReducer from "./educator/reducer/position";
import educatorLessonReducer from "./educator/reducer/lesson";
import educatorUserReducer from "./educator/reducer/user";
import educatorAlertReducer from "./educator/reducer/alert";
import educatorReducer from "./educator/reducer/statistics";
import educatorAnalyticsReducer from "./educator/reducer/analytics";
import educatorAIContentReducer from "./educator/reducer/ai-content";
import learnerQuizReducer from "./learner/reducer/quiz";
import learnerStatisticsReducer from "./learner/reducer/statistics";
import learnerAnalyticsReducer from "./learner/reducer/analytics";
import learnerCourseReducer from "./learner/reducer/course";
import learnerQuizzieReducer from "./learner/reducer/quizzie";
import learnerAlertReducer from "./learner/reducer/alert";
import learnerCourseStatisticsReducer from "./learner/reducer/courseStatistics";
import learnerModuleReducer from "./learner/reducer/module";
import learnerNewsFeedReducer from "./learner/reducer/newsFeed";
import learnerLessonReducer from "./learner/reducer/lesson";
import headerReducer from "./shared/reducer";

const appReducer = combineReducers({
  authReducer,
  headerReducer,
  educatorQuizReducer,
  educatorQuizzieReducer,
  educatorModuleReducer,
  educatorCourseReducer,
  educatorCompanyReducer,
  educatorDepartmentReducer,
  educatorPositionReducer,
  educatorLessonReducer,
  educatorUserReducer,
  educatorAlertReducer,
  educatorReducer,
  educatorAnalyticsReducer,
  educatorAIContentReducer,
  learnerQuizReducer,
  learnerStatisticsReducer,
  learnerAnalyticsReducer,
  learnerCourseReducer,
  learnerQuizzieReducer,
  learnerAlertReducer,
  learnerModuleReducer,
  learnerCourseStatisticsReducer,
  learnerNewsFeedReducer,
  learnerLessonReducer,
});

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: Action
) => {
  if (action.type === "LEARNER_LOGOUT" || action.type === "LOGOUT_EDUCATOR") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
