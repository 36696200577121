import { Card, Typography } from "antd";
import StatisticsCard from "../../shared/StatisticsCard";
import type { StatisticsSectionProps } from "../../../utils/types";

const { Text } = Typography;

const StatisticsSection = (props: StatisticsSectionProps) => {
  const { overAllStatistics, title } = props;
  return (
    <Card className="bg-gradient-to-br from-[#FDA4AF] to-[#5063EE]">
      <div className="p-6 flex flex-col gap-4 ">
        <Text className="text-white font-semibold text-sm">{title}</Text>
        <div className="flex gap-2">
          {overAllStatistics.length &&
            overAllStatistics.map((content, index) => (
              <StatisticsCard
                key={index}
                icon={content.icon}
                title={content.title}
                progress={content.progress}
              />
            ))}
        </div>
      </div>
    </Card>
  );
};

export default StatisticsSection;
