import { call, all, put, takeLatest } from "redux-saga/effects";

import { AxiosResponse } from "axios";
import { statisticsTypes as types, setStatisticsOverall } from "../actions";
import { statisticsOverallService } from "../../../services/educator";

export default function* authSagas() {
  yield all([
    takeLatest(types.GET_STATISTICS_OVERALL_ACTION, handleGetStatisticsOverall),
  ]);
}

function* handleGetStatisticsOverall() {
  const { data }: AxiosResponse = yield call(statisticsOverallService);
  yield put(setStatisticsOverall(data));
}
