import { Col } from "antd";
import { StatisticsCardProps } from "../../../../utils";

const StatisticsSingleCard = ({ statistics }: { statistics: any }) => {
  const { icon: Icon, title, progress } = statistics;
  return (
    <Col className="w-full h-[150px] bg-neutral-100 rounded-2xl py-2 px-5 flex items-center justify-center">
      <div className="flex gap-10">
        <div className="flex items-center fill-primary-600">
          <Icon height={70} />
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="text-neutral-600">{title}</div>

          <div className="text-primary-500 font-semibold">{progress}</div>
        </div>
      </div>
    </Col>
  );
};

export default StatisticsSingleCard;
