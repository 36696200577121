import { call, all, put, takeLatest, take } from "redux-saga/effects";

import { setModule, setModuleById, moduleTypes as types } from "../actions";
import { AxiosResponse } from "axios";
import {
  getModuleService,
  getModuleByIdService,
} from "../../../services/learner/module";
import { EntityIdPayload } from "../../../utils";

export default function* learnerModuleSagas() {
  yield all([
    takeLatest(types.GET_MODULE, handleGetModule),
    takeLatest(types.GET_MODULE_BY_ID, handleGetModuleById),
  ]);
}

function* handleGetModule() {
  const { data }: AxiosResponse = yield call(getModuleService);
  if (data) {
    yield put(setModule({ modules: data }));
  }
}

function* handleGetModuleById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getModuleByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setModuleById({ module: data }));
  }
}
