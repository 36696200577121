import { Typography } from "antd";

import type { CreateEntityInfoProps } from "../../utils/types";

const { Text } = Typography;

const CreateEntityInfo = (props: CreateEntityInfoProps) => {
  const { title, text } = props;

  return (
    <div className="flex flex-col flex-wrap gap-3 max-w-[200px]">
      {title && (
        <Text className="text-neutral-500  font-semibold text-lg">{title}</Text>
      )}
      {text && (
        <Text className="text-neutral-500  text-base font-normal">{text}</Text>
      )}
    </div>
  );
};

export default CreateEntityInfo;
