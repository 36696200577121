import { produce } from "immer";

import { courseStatisticsTypes as types } from "../actions";
import { CourseStatistics } from "../../../utils/types/Learner";

const defaultState: {
  courseStatistics: CourseStatistics;
  loading: boolean;
} = {
  courseStatistics: {
    completedLessons: "",
    courseDuration: null,
    courseId: "",
    courseTitle: "",
    pointsCompleted: "",
    progress: 0,
    remainingLessons: "",
    totalLessons: "",
    totalPoints: "",
  },
  loading: false,
};

const handleSetCourseStatistics = (
  state: { courseStatistics: CourseStatistics; loading: boolean },
  payload: CourseStatistics
) => {
  state.courseStatistics = payload;
  state.loading = false;
  return state;
};

export default produce(
  (
    state: {
      courseStatistics: CourseStatistics;
      loading: boolean;
    } = defaultState,
    action
  ) => {
    switch (action.type) {
      case types.SET_COURSE_STATISTICS_ACTION:
        return handleSetCourseStatistics(state, action.payload);
      case types.GET_COURSE_STATISTICS_ACTION:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
