import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { StateType } from "../../../utils/types";
import { selectCompanyInfo } from "../../../redux/educator/selector";
import {
  getCompanyInfoById,
  editCompanyInfo,
} from "../../../redux/educator/actions";
import { setCurrentStep } from "../../../redux/shared/actions";

const mapStateToProps = (state: StateType) => ({
  companyInfo: selectCompanyInfo(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getCompanyInfoById,
      editCompanyInfo,
      setCurrentStep,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
