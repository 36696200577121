import axios from "axios";
import StorageService from "./storageService";

const BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

const blobOptions = (url: string | undefined): any => ({
  baseURL: `${url}`,
  responseType: "arraybuffer",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/pdf",
  },
});

const BlobApiService = axios.create(blobOptions(BASE_URL));

BlobApiService.interceptors.request.use((req) => {
  if (req.headers) {
    req.headers.Authorization = `Bearer ${
      StorageService.getStringItem("accessToken") as string
    }`;
    if (req.data instanceof FormData) {
      delete req.headers["Content-Type"];
    } else {
      req.headers["Content-Type"] = "application/json";
    }
  }
  return req;
});

export default BlobApiService;
