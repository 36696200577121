import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  getQuizzieById,
  submitAnswers,
} from "../../../../redux/learner/actions";
import {
  setInitialSteps,
  setNextStep,
  setPreviousStep,
} from "../../../../redux/shared/actions";
import { StateType } from "../../../../utils/types";
import {
  selectQuizzie,
  selectQuizziesLoading,
} from "../../../../redux/learner/selector";

const mapStateToProps = (state: StateType) => ({
  quizzie: selectQuizzie(state),
  loading: selectQuizziesLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getQuizzieById,
      submitAnswers,
      setInitialSteps,
      setNextStep,
      setPreviousStep,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
