const apiRoutes = {
  login: "/auth/login",
  logout: "/auth/logout",
  register: "/auth/register",
  singUp: "/auth/send-verification-email",
  resetPassword: "/auth/reset-password",
  sendResetPassword: "/auth/send-reset-password-email",

  loggedInUser: "/me",

  educatorAnalytics: "/analytics/educator?type=:type",
  user: "/user",
  userById: "/user/:id",
  courseAnalytics: "/analytics/educator/course/:id",
  moduleAnalytics: "/analytics/educator/module/:id",
  lessonAnalytics: "/analytics/educator/lesson/:id",
  exportAnalytics: "/analytics/generate-excel?:query",
  statisticsOverall: "/statistics/overall",
  webAnalytics: "/analytics/web",

  educatorModule: "/module",
  educatorModuleById: "/module/:id?includeStructure=true",
  educatorAssignCourses: "/module/:id/assign-course",
  educatorCourse: "/course",
  educatorCourseById: "/course/:id?includeStructure=true",
  educatorLatestCourse: "/course/latest",
  educatorAassignLessons: "/course/:id/assign-lesson",
  educatorLesson: "/lesson",
  educatorLessonById: "/lesson/:id",
  educatorAssignQuiz: "/lesson/:lessonId/assign-quiz/:quizId",
  educatorQuiz: "/quiz",
  educatorQuizById: "/quiz/:id",
  educatorQuizzie: "/quizzie",
  educatorQuizzieById: "/quizzie/:id",
  getDepartments: "/department",
  addDepartment: "/department",
  getPositions: "/position",
  addPosition: "/position",
  getDepartmentsWithUsers: "/department/users",
  getPositionsWithUsers: "/position/users",
  editDepartment: "/department/:id",
  deleteDepartment: "/department/:id",
  editPosition: "/position/:id",
  deletePosition: "/position/:id",
  getUsers: "/user",
  deleteUser: "/user/:id",
  editUser: "/user/:id",
  getUser: "/user/:id",
  getAlerts: "/notification",
  addAlert: "/notification",
  educatorCompanyInfo: "/company/:id",
  educatorDepartment: "/department",
  educatorDepartmentUsers: "/department/users",
  educatorDepartmentById: "/department/:id",
  educatorPosition: "/position",
  educatorPositionById: "/position/:id",
  educatorPositionUsers: "/position/users",
  educatorUser: "/user",
  educatorUserById: "/user/:id",
  educatorAlert: "/notification",
  educatorAlertById: "/notification/:id",

  learnerStatistics: "/statistics/user",
  learnerStatisticsDetails: "/statistics/user/details",
  learnerAnalytics: "/analytics/user",

  module: "/module",
  moduleById: "/module/:id",
  moduleByIdIncludeStructure: "/module/:id?includeStructure=true",
  moduleAssignCourses: "/module/:id/assign-course",

  course: "/course",
  courseById: "/course/:id",
  courseByIdIncludeStructure: "/course/:id?includeStructure=true",
  courseAssignLessons: "/course/:id/assign-lesson",
  courseLatest: "/course/latest",
  courseRestart: "/course/:id/restart",

  lesson: "/lesson",
  lessonById: "/lesson/:id",
  lessonAssignQuiz: "/lesson/:lessonId/assign-quiz/:quizId",
  markLessonAsCompleted: "/lesson/:id/completed",
  generateLessonSummary: "/lesson/generate-summary",
  generateQuiz: "/lesson/generate-quiz",
  getPresignedUrl: "/lesson/file",

  quiz: "/quiz",
  quizById: "/quiz/:id",
  quizSubmitAnswers: "/quiz/:id/submit-answers",

  quizzie: "/quizzie",
  quizzieById: "/quizzie/:id",
  quizzieSubmitAnswers: "/quizzie/:id/submit-answers",

  department: "/department",
  departmentById: "/department/:id",
  departmentUsers: "/department/users",

  position: "/position",
  positionById: "/position/:id",
  positionUsers: "/position/users",

  alert: "/notification",
  alertById: "/notification/:id",

  newsFeed: "/news-feed",
  newsFeedByType: "/news-feed/:type",

  aiContent: "/ai-content",
  aiContentById: "/ai-content/:id",
  extractText: "/ai-content/extract-text",
};
export default apiRoutes;
