import {
  IdPayload,
  SetQuizByIdPayload,
  SetQuizzesPayload,
} from "../../../utils";
import { EducatorAddQuizActionType } from "../../../utils/types";

export const quizTypes = {
  ADD_QUIZ: "ADD_QUIZ",
  GET_QUIZ: "GET_QUIZ",
  GET_QUIZ_BY_ID: "GET_QUIZ_BY_ID",
  DELETE_QUIZ: "DELETE_QUIZ",
  SET_QUIZ: "SET_QUIZ",
  SET_QUIZ_BY_ID: "SET_QUIZ_BY_ID",
  EDIT_QUIZ: "EDIT_QUIZ",
  ASSIGN_QUIZ: "ASSIGN_QUIZ",
  GENERATE_QUIZ: "GENERATE_QUIZ",
  SET_GENERATED_QUIZ: "SET_GENERATED_QUIZ",
  QUIZ_SUBMISSION_COMPLETE: "QUIZ_SUBMISSION_COMPLETE",
};

export const addQuiz = (payload: EducatorAddQuizActionType) => ({
  type: quizTypes.ADD_QUIZ,
  payload,
});

export const getQuiz = () => ({
  type: quizTypes.GET_QUIZ,
});

export const setQuiz = (payload: SetQuizzesPayload) => ({
  type: quizTypes.SET_QUIZ,
  payload,
});

export const getQuizById = (id: IdPayload) => ({
  type: quizTypes.GET_QUIZ_BY_ID,
  payload: id,
});

export const setQuizById = (payload: SetQuizByIdPayload) => ({
  type: quizTypes.SET_QUIZ_BY_ID,
  payload,
});

export const deleteQuiz = (payload: IdPayload) => ({
  type: quizTypes.DELETE_QUIZ,
  payload,
});

export const editQuiz = (payload: SetQuizByIdPayload) => ({
  type: quizTypes.EDIT_QUIZ,
  payload,
});

export const assignQuiz = (payload: any) => ({
  type: quizTypes.ASSIGN_QUIZ,
  payload,
});

export const generateQuiz = (payload: any) => ({
  type: quizTypes.GENERATE_QUIZ,
  payload,
});

export const setGeneratedQuiz = (payload: any) => ({
  type: quizTypes.SET_GENERATED_QUIZ,
  payload,
});

export const quizSubmissionComplete = () => ({
  type: quizTypes.QUIZ_SUBMISSION_COMPLETE,
});
