import { Button, Tooltip, Row, Col, Input, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import { useEffect, useState } from "react";
import ProgressInfoBox from "../components/ProgressInfoBox";
import FilterButtons from "../../../shared/FilterButtons";
import Chart from "../components/Chart";
import TimelineSelection from "../components/TimelineSelection";
import MenuItems from "../../../../assets/images/menu-items";
import ListHeader from "../components/ListHeader";
import CardProgress from "../components/CardProgress";
import PerformanceListItem from "../components/PerformanceUserListItem";
import { DownloadOutlined } from "@ant-design/icons";

const EducatorDashboardLessonsScreen = (props: any) => {
  const {
    lessons,
    singleLesson,
    benchmarkLoaded,
    benchmark,
    actions: {
      getAnalytics,
      getAnalyticsLessonById,
      getBenchmarkAnalytics,
      resetSingleLessonAnalytics,
      exportExcel,
    },
  } = props;

  const navigate = useNavigate();

  const [filter, setFilter] = useState("ALL");
  const [timeLineValue, setTimeLineValue] = useState();
  const [singleLessonActive, setSingleLessonActive] = useState(false);
  const [lessonsChartData, setLessonsChartData] = useState([]);
  const [statistics, setStatistics] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLessons, setFilteredLessons] = useState(lessons?.data);
  const [filteredSingleLessonData, setFilteredSingleLessonData] = useState(
    singleLesson?.data
  );
  const [singleLessonTitle, setSingleLessonTitle] = useState("");

  const filterProps = [
    { key: "All", value: "ALL" },
    { key: "Finished", value: "FINISHED" },
    { key: "In Progress", value: "INPROGRESS" },
  ];

  const timeLineOptions = [
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
    { value: "year", label: "Year" },
  ];

  useEffect(() => {
    document.body.classList.add("educator-dashboard-layout");

    return () => {
      document.body.classList.remove("educator-dashboard-layout");
    };
  }, []);

  useEffect(() => {
    getAnalytics({
      type: "lesson",
    });
  }, []);

  useEffect(() => {
    if (lessons.loaded) {
      const completedLessons =
        lessons?.data?.filter((lesson: any) => lesson.progress === "100")
          ?.length || 0;
      const completedLessonsPercentage = Math.round(
        (completedLessons / lessons?.data?.length) * 100
      );
      const inProgressLessons =
        lessons?.data?.filter((lesson: any) => lesson.progress !== "100")
          ?.length || 0;
      const inProgressLessonsPercentage = Math.round(
        (inProgressLessons / lessons?.data?.length) * 100
      );
      setStatistics({
        completedLessons,
        completedLessonsPercentage,
        inProgressLessons,
        inProgressLessonsPercentage,
      });
    }
  }, [lessons]);

  useEffect(() => {
    if (singleLessonActive) {
      let filtered = singleLesson?.data;

      if (filter === "FINISHED") {
        filtered = filtered.filter((user: any) => user.completed);
      } else if (filter === "INPROGRESS") {
        filtered = filtered.filter((user: any) => !user.completed);
      }

      if (searchTerm) {
        filtered = filtered.filter((user: any) =>
          user.userName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      setFilteredSingleLessonData(filtered);
    } else {
      let filtered = lessons?.data;

      if (filter === "FINISHED") {
        filtered = filtered.filter((lesson: any) => lesson.progress === "100");
      } else if (filter === "INPROGRESS") {
        filtered = filtered.filter((lesson: any) => lesson.progress !== "100");
      }

      if (searchTerm) {
        filtered = filtered.filter((lesson: any) =>
          lesson.lessonTitle.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      setFilteredLessons(filtered);
    }
  }, [searchTerm, filter, lessons, singleLesson]);

  useEffect(() => {
    if (!benchmarkLoaded) {
      getBenchmarkAnalytics();
    }
  }, []);

  useEffect(() => {
    if (benchmarkLoaded) {
      lessonsWeekData();
    }
  }, [benchmarkLoaded]);

  const handleChangeTimeline = (value: string) => {
    if (value === "week") {
      lessonsWeekData();
      return;
    }
    if (value === "month") {
      lessonsMonthData();
      return;
    }
    if (value === "year") {
      lessonsYearData();
      return;
    }
  };

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
  };

  const lessonsWeekData = () => {
    const weekLessonsData = benchmark?.lessons?.slice(0, 7);
    const chartData = getChartDataForWeek(weekLessonsData);
    setLessonsChartData(chartData);
  };

  const lessonsMonthData = () => {
    const monthLessonsData = benchmark?.lessons?.slice(0, 30);
    const chartData = getChartDataForMonth(monthLessonsData);
    setLessonsChartData(chartData);
  };

  const lessonsYearData = () => {
    const yearLessonsData = benchmark?.lessons?.slice(0, 365);
    const chartData = getChartDataForYear(yearLessonsData);
    setLessonsChartData(chartData);
  };

  const getChartDataForWeek = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).toLocaleDateString("en-US", {
        weekday: "short",
      }),
    }));
  };

  const getChartDataForMonth = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).getDate(),
    }));
  };

  const getChartDataForYear = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).toLocaleDateString("en-US", {
        month: "short",
      }),
    }));
  };

  const handleLessonSelection = (lesson: any) => {
    getAnalyticsLessonById({ id: lesson.lessonId });
    setSingleLessonActive(true);
    setSingleLessonTitle(lesson?.lessonTitle);
  };

  const exportLessonsExcelFile = () => {
    exportExcel({
      query: `entity=lesson&timeFrame=all`,
    });
  };

  const exportCurrentStateExcelFile = () => {
    exportExcel({
      query: `entity=lesson&timeFrame=today`,
    });
  };

  const LessonsList = () => {
    if (!lessons || lessons?.loading) {
      return <Skeleton active />;
    }

    if (lessons?.loaded && filteredLessons?.length === 0) {
      return (
        <div className="flex flex-col gap-8 items-center">
          <div className="p-4">
            <MenuItems.Lesson className="h-20 w-20 fill-primary-600" />
          </div>
          <div className="text-neutral-600 text-xl fill-primary-600 font-bold text-center w-3/4">
            There are currently no available lessons.
          </div>

          <Button
            onClick={() => navigate("/educator/lesson/create")}
            className="w-full bg-primary-600 h-10 text-base font-semibold text-white"
          >
            Create New Lesson
          </Button>
        </div>
      );
    }

    if (lessons?.loaded) {
      return filteredLessons?.map((lesson: any, index: number) => (
        <CardProgress
          key={index}
          classes=""
          title={lesson.lessonTitle}
          progress={lesson.progress}
          isFinished={lesson?.progress === "100"}
          footerButton={
            <Button
              className="bg-primary-600 text-white border-none text-sm leading-4 font-semibold"
              onClick={() => handleLessonSelection(lesson)}
            >
              View Lesson
            </Button>
          }
          size="small"
        ></CardProgress>
      ));
    }
  };

  const SingleLessonList = () => {
    if (!singleLesson || singleLesson?.loading) {
      return <Skeleton active />;
    }

    if (singleLesson?.loaded && filteredSingleLessonData?.length === 0) {
      return (
        <div className="flex flex-col gap-8 items-center">
          <div className="p-4">
            <MenuItems.Lesson className="h-20 w-20 fill-primary-600" />
          </div>
          <div className="text-neutral-600 text-xl fill-primary-600 font-bold text-center w-3/4">
            There are currently no available users.
          </div>
        </div>
      );
    }

    if (singleLesson?.loaded && filteredSingleLessonData?.length > 0) {
      return filteredSingleLessonData?.map((user: any) => (
        <PerformanceListItem
          key={user.userId}
          user={user}
        ></PerformanceListItem>
      ));
    }
  };

  const backToAllLessons = () => {
    setSingleLessonActive(false);
    setSingleLessonTitle("");
    resetSingleLessonAnalytics();
  };

  return (
    <Row>
      <Col lg={12} className="pr-[80px]">
        <div>
          <div className="text-primary-600 text-xl font-semibold mb-2">
            Performance
          </div>
          <div className="text-neutral-500 text-sm">
            Monitor the performance of your Lessons and filter the data by time
            frame (week, month, year).
          </div>

          <div className="mt-5 flex justify-between gap-4">
            <div className="flex gap-4">
              <TimelineSelection
                selectedOption={timeLineValue}
                onSelectionChange={handleChangeTimeline}
                options={timeLineOptions}
              ></TimelineSelection>
              <Tooltip title="Export">
                <Button
                  onClick={exportLessonsExcelFile}
                  icon={<DownloadOutlined />}
                  className="text-primary-500 leading-4 font-bold bg-neutral-100 border-none"
                ></Button>
              </Tooltip>
            </div>
            <Button
              color="primary"
              type="link"
              onClick={() => navigate("/educator/benchmark")}
            >
              All Graphs
            </Button>
          </div>
          <div className="mt-3">
            <Chart
              chartData={lessonsChartData}
              xAxisDataKey="dateDay"
              type="performance"
            ></Chart>
          </div>
          <div className="py-7">
            <Row className="h-full" gutter={24}>
              <Col lg={12}>
                <ProgressInfoBox
                  percentage={statistics?.completedLessonsPercentage}
                  heading="Completed Lessons"
                  totalNumber={statistics?.completedLessons}
                  type="performance"
                ></ProgressInfoBox>
              </Col>
              <Col lg={12}>
                <ProgressInfoBox
                  percentage={statistics?.inProgressLessonsPercentage}
                  heading="In progress"
                  totalNumber={statistics?.inProgressLessons}
                  type="performance"
                ></ProgressInfoBox>
              </Col>
            </Row>
          </div>
        </div>
      </Col>

      <Col lg={12} className="pl-[80px]">
        {!singleLessonActive && (
          <Tooltip title="Export">
            <Button
              style={{
                position: "absolute",
                top: "-40px",
                right: "0px",
              }}
              onClick={exportCurrentStateExcelFile}
              icon={<DownloadOutlined />}
              className="text-primary-500 leading-4 font-bold bg-transparent border-none"
            ></Button>
          </Tooltip>
        )}
        {singleLessonActive && (
          <div
            onClick={() => backToAllLessons()}
            className="full-overview-selector text-primary-500 font-medium cursor-pointer"
          >
            &lt; Back to full overview
          </div>
        )}
        <div className="bg-primary-100 w-full">
          <div className="bg-white p-5 w-full analytics-selector flex flex-col gap-4 rounded-lg">
            <div>
              <ListHeader
                title={singleLessonActive ? singleLessonTitle : "Lessons"}
                Icon={MenuItems.Lesson}
              />
            </div>
            <div className="analyitcs-searchbox">
              <Input
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search"
                prefix={<SearchIcon className="" />}
                size="small"
              />
            </div>
          </div>
          <div className="flex justify-center mb-4">
            <FilterButtons
              selectedFilter={filter}
              handleFilterChange={handleFilterChange}
              filterOptions={filterProps}
            ></FilterButtons>
          </div>
          <div className="p-4 bg-white rounded-lg">
            <div className="flex flex-col gap-3 max-h-[calc(100vh-450px)] overflow-y-auto">
              <>
                {singleLessonActive ? (
                  <SingleLessonList></SingleLessonList>
                ) : (
                  <LessonsList></LessonsList>
                )}
              </>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default EducatorDashboardLessonsScreen;
