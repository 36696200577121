import { useState, useEffect } from "react";
import {
  Button,
  Card,
  Checkbox,
  CheckboxProps,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Typography,
} from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
const { Text } = Typography;

const QuizOptions = (props: any) => {
  const {
    form,
    formValues,
    setFormValues,
    setPreviousStep,
    setQuizStep,
    isEdit,
    loading,
  } = props;

  const [maxNumberOfAnswers, setMaxNumberOfAnswers] = useState([]);
  const [isMandatory, setIsMandatory] = useState(false);
  const [minimumCorrectAnswers, setMinimumCorrectAnswers] = useState<any>(null);
  const [correctAnswersOption, setCorrectAnswersOption] = useState<any>(null);

  useEffect(() => {
    calculateMaxNumberOfAnswers();
  }, []);

  useEffect(() => {
    if (isEdit) {
      setIsMandatory(formValues?.isMandatory);
    }
  }, [isEdit]);

  const handleGoBack = () => {
    setPreviousStep();
    setQuizStep(1);
  };

  const handlePublishQuiz = () => {
    form.submit();
  };

  const calculateMaxNumberOfAnswers = () => {
    const quiz = formValues;
    const { questions } = quiz;

    if (questions?.length > 0) {
      setMaxNumberOfAnswers(
        questions?.map((question: any, index: number) => ({
          label: `Answer${index === 0 ? "" : "s"} ${index + 1}`,
          value: index + 1,
        }))
      );
    }
  };

  const onChangeMandatory: CheckboxProps["onChange"] = (e) => {
    setIsMandatory(e.target.checked);

    setFormValues((prevState: any) => ({
      ...prevState,
      isMandatory: e.target.checked,
    }));
  };

  const onChangeAnswerOption = (e: RadioChangeEvent) => {
    setCorrectAnswersOption(e.target.value);

    if (e.target.value === "All") {
      setFormValues((prevState: any) => ({
        ...prevState,
        minimumCorrectAnswers: formValues?.questions?.length,
      }));
    }

    if (e.target.value === "Minimum") {
      setFormValues((prevState: any) => {
        setMinimumCorrectAnswers(formValues?.questions?.length);
        return {
          ...prevState,
          minimumCorrectAnswers: formValues?.questions?.length,
        };
      });
    }
  };

  const onChangeMinimumCorrectAnswers = (value: number) => {
    setMinimumCorrectAnswers(value);

    setFormValues((prevState: any) => ({
      ...prevState,
      minimumCorrectAnswers: value,
    }));
  };

  return (
    <div className="flex flex-col justify-around h-full">
      <div className="flex items-center flex-col gap-6">
        <CheckCircleFilled
          className="text-center"
          style={{ fontSize: "4rem", color: "#59ABB2" }}
        />
        <Text className="text-neutral-500  font-semibold text-xl text-center max-w-[310px]">
          Great. You completed the Quiz. Add options bellow, and then you can
          Publish Lesson
        </Text>

        <div className="flex flex-row gap-8">
          <Card className="p-6 educator-dashboard-box-shadow-cards rounded-md max-w-[428px]">
            <div className="flex flex-wrap gap-4">
              <Text className="text-neutral-600 font-semibold text-base">
                If the quiz is mandatory the user will have to watch the video
                again if all answers are not correct.
              </Text>

              <Checkbox checked={isMandatory} onChange={onChangeMandatory}>
                Yes, it is mandatory
              </Checkbox>
            </div>
          </Card>

          <Card className="p-6 educator-dashboard-box-shadow-cards rounded-md max-w-[428px]">
            <div className="flex flex-wrap gap-4">
              <Text className="text-neutral-600 font-semibold text-base">
                User must answer correctly
              </Text>

              <Radio.Group
                onChange={onChangeAnswerOption}
                value={correctAnswersOption}
              >
                <Space direction="vertical">
                  <Radio value="All">All questions</Radio>
                  <Radio value="Minimum">Select minimum correct answers</Radio>
                </Space>
              </Radio.Group>
              {correctAnswersOption === "Minimum" && (
                <Select
                  value={minimumCorrectAnswers}
                  style={{ width: 240 }}
                  options={maxNumberOfAnswers}
                  onChange={onChangeMinimumCorrectAnswers}
                />
              )}
            </div>
          </Card>
        </div>
      </div>
      <div className="flex justify-center gap-5">
        <Button
          onClick={handleGoBack}
          className="w-full max-w-[272px] h-[50px] text-base  text-primary-600 border-primary-200 font-semibold rounded-lg"
        >
          Back
        </Button>

        <Button
          className="w-full h-[50px] max-w-[272px] text-base bg-primary-600 font-semibold rounded-lg"
          type="primary"
          onClick={handlePublishQuiz}
          disabled={!correctAnswersOption}
          loading={loading}
        >
          Publish quiz
        </Button>
      </div>
    </div>
  );
};

export default QuizOptions;
