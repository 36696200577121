import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import {
  setInitialSteps,
  setNextStep,
  setPreviousStep,
} from "../../../../redux/shared/actions";
import { StateType } from "../../../../utils/types";
import { addQuiz } from "../../../../redux/educator/actions";
import { selectQuizzesSubmissionLoading } from "../../../../redux/educator/selector";

const mapStateToProps = (state: StateType) => ({
  submissionLoading: selectQuizzesSubmissionLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      setInitialSteps,
      setNextStep,
      setPreviousStep,
      addQuiz,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
