import { Card, Button, Typography, Image } from "antd";
import logoImage from "../../assets/images/creation-success-test.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import creationSuccess from "../../utils/constants/creationSuccess";

const { Text } = Typography;

const CreationSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [heading, setHeading] = useState("");
  const [firstButtonText, setFirstButtonText] = useState("");
  const [secondButtonText, setSecondButtonText] = useState("");
  const [firstButtonLink, setFirstButtonLink] = useState("/");
  const [secondButtonLink, setSecondButtonLink] = useState("/");
  const { isEdit = false } = location.state || {};

  useEffect(() => {
    const route = creationSuccess.find((r) =>
      location.pathname.endsWith(r.path)
    ) || {
      heading: "",
      firstButtonText: "",
      secondButtonText: "",
      firstButtonLink: "/",
      secondButtonLink: "/",
    };
    const headingText = isEdit
      ? route.heading.replace("created", "edited")
      : route.heading;

    setHeading(headingText);
    setFirstButtonText(route.firstButtonText);
    setSecondButtonText(route.secondButtonText);
    setFirstButtonLink(route.firstButtonLink);
    setSecondButtonLink(route.secondButtonLink);
  }, [location.pathname, creationSuccess]);

  const { points } = location.state || {};

  return (
    <Card className="w-[584px] h-[488px] p-[100px] shadow-md ">
      <div className="flex flex-col gap-6 items-center">
        <div className="h-[60px] w-[60px] text-center">
          <Image src={logoImage} preview={false} />
        </div>

        <Text className="text-neutral-600 text-xl font-bold text-center w-60">
          {points ? heading.replace("%POINTS%", points) : heading}
        </Text>

        <Button
          onClick={() => navigate(firstButtonLink)}
          className="border-primary-200 w-full text-primary-600 h-12 text-base font-semibold"
        >
          {firstButtonText}
        </Button>

        <Button
          onClick={() => navigate(secondButtonLink)}
          className="bg-primary-600 w-full h-12 text-white text-base font-semibold"
        >
          {secondButtonText}
        </Button>
      </div>
    </Card>
  );
};

export default CreationSuccess;
