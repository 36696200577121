import { Route, Routes, useLocation } from "react-router-dom";

import AuthenticationLayout from "../layout/AuthenticationLayout";
import Login from "../../modules/auth/login";
import Register from "../../modules/auth/Register";
import ResetPassword from "../../modules/auth/ResetPassword";
import EducatorLayout from "../layout/EducatorLayout";
import UserLayout from "../layout/UserLayout";
import SuccessPopUpLayout from "../layout/SuccessPopUpLayout";
import ChooseRole from "../../modules/auth/ChooseRole";
import Settings from "../../modules/shared/Settings";
import WildCardNavigation from "../../modules/shared/WildCardNavigation";

const App = () => {
  const location = useLocation();

  return (
    <>
      <Routes location={location}>
        <Route element={<AuthenticationLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>

        <Route path="/settings/:id" element={<Settings />} />

        <Route path="/choose-role" element={<ChooseRole />} />

        <Route path="/educator/*" element={<EducatorLayout />} />
        <Route path="/user/*" element={<UserLayout />} />

        <Route path="/success/*" element={<SuccessPopUpLayout />} />
        <Route path="*" element={<WildCardNavigation />} />
      </Routes>
    </>
  );
};

export default App;
