import { useEffect, useState } from "react";
import { ConnectedProps } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Image, Popconfirm, Skeleton, Typography } from "antd";
import testImage from "../../../../assets/images/default-cover-image.jpg";
import { ReactComponent as LessonIcon } from "../../../../assets/images/reusable-items/lesson.svg";
import { ReactComponent as DurationIcon } from "../../../../assets/images/reusable-items/duration.svg";
import LessonAccordion from "./LessonAccordion";
import connector from "./Container";
import { LessonProps } from "../../../../utils";

const { Text } = Typography;
const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const CourseDetailsScreen = (props: ConnectedProps<typeof connector>) => {
  const {
    course,
    loading,
    actions: { getCourseById, restartCourseById },
  } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [isCourseFinished, setIsCourseFinished] = useState(false);

  useEffect(() => {
    getCourseById({ id });
  }, []);

  useEffect(() => {
    setIsCourseFinished(
      !course?.lessons.some((lesson: LessonProps) => !lesson.isFinished)
    );
  }, [course]);

  const confirmRestartCourse = (id: string) => {
    restartCourseById({ id });
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {course && (
        <div className="flex flex-row gap-[18px] mb-6">
          <div className="w-1/2">
            <Card className="shadow-md p-7">
              <div className="h-[250px]">
                <Image
                  src={
                    course.coverImageKey
                      ? `${cloudfrontKey}/${course.coverImageKey}`
                      : testImage
                  }
                  preview={false}
                  className="rounded-lg object-cover"
                />
              </div>
              <Text className="text-xl text-neutral-600 font-semibold block pt-5 pb-3">
                {course?.title}
              </Text>
              <div className="w-3/4 flex-wrap flex gap-5  border-b border-b-neutral-200 pb-3 my-5">
                <div className="flex gap-2 items-center">
                  <div className="w-4 h-4">
                    <LessonIcon className="w-full h-full fill-primary-400" />
                  </div>

                  <Text className="text-base text-neutral-500">
                    {course?.lessonCount} Lessons
                  </Text>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4">
                    <DurationIcon className="w-full h-full fill-primary-400" />
                  </div>

                  <Text className="text-base text-neutral-500 ">
                    {course?.duration}
                  </Text>
                </div>
              </div>
              <Text className="text-base text-neutral-600 font-semibold block mb-3">
                About The Course
              </Text>
              <Text className="text-neutral-600 text-[13px] leading-5 block mb-4">
                {course?.description}
              </Text>

              <div className="bg-rose-400 h-[50px] w-[200px] flex items-center justify-center rounded-lg">
                <div>
                  <Text className="text-white font-semibold text-base">
                    {course.points}{" "}
                  </Text>
                  <Text className="text-white font-semibold text-xs">
                    Available PTS
                  </Text>
                </div>
              </div>
            </Card>
          </div>
          <div className="w-1/2">
            {course && course?.lessons.length > 0 ? (
              <Card className="shadow-md p-7">
                <Text className="text-lg text-neutral-600 font-semibold block mb-3">
                  Lessons
                </Text>
                {course.lessons.map((lesson: LessonProps, index: number) => (
                  <div key={index}>
                    <LessonAccordion
                      lesson={lesson}
                      previousLesson={course?.lessons[index - 1]}
                    />
                  </div>
                ))}
                {!isCourseFinished ? (
                  <Button
                    className="w-full h-[50px] bg-primary-600 text-white font-semibold rounded-lg mt-5"
                    onClick={() => navigate(`/user/course/${id}/lesson`)}
                  >
                    Start Now
                  </Button>
                ) : (
                  <div className="flex gap-4">
                    <Popconfirm
                      title="Restart course"
                      description="Are you sure to restart this course?"
                      onConfirm={() => confirmRestartCourse(course.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        danger
                        type="primary"
                        className="w-1/2 h-[50px] text-white font-semibold rounded-lg mt-5"
                      >
                        Restart
                      </Button>
                    </Popconfirm>

                    <Button
                      onClick={() => navigate(`/user/course/${id}/lesson`)}
                      type="primary"
                      className="w-1/2 h-[50px] bg-primary-600 text-white font-semibold rounded-lg mt-5"
                    >
                      Rewatch
                    </Button>
                  </div>
                )}
              </Card>
            ) : (
              <Card
                className={` 
                 
                shadow-md py-[60px]`}
              >
                <div className={`px-[40px]`}>
                  <div className="flex flex-col gap-10 items-center justify-center">
                    <div className="h-[60px]">
                      <LessonIcon className="w-full h-full fill-primary-400" />
                    </div>
                    <Text className="text-neutral-600 text-xl font-bold text-center">
                      There are currently no
                      <div>Lessons</div>
                    </Text>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CourseDetailsScreen;
