import { useEffect, useState } from "react";
import { Modal, Button, Typography, Checkbox } from "antd";
import { ReactComponent as DepartmentIcon } from "../../assets/images/reusable-items/department.svg";
import { CheckOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { selectDepartments } from "../../redux/educator/selector";
import { departmentTypes as types } from "../../redux/educator/actions";

const { Text } = Typography;

const DepartmentSelector = ({
  onSelectionChange,
  selectedDepartments,
  setSelectedDepartments,
}: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tempSelectedDepartments, setTempSelectedDepartments] = useState<
    string[]
  >([]);
  const departments = useSelector(selectDepartments);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!departments || departments?.length === 0) {
      dispatch({ type: types.GET_DEPARTMENT });
    }
  }, [departments, dispatch]);

  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedDepartments(tempSelectedDepartments);
    if (onSelectionChange) {
      onSelectionChange(tempSelectedDepartments);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setTempSelectedDepartments(selectedDepartments);
  };

  const handleSelect = (dept: any) => {
    setTempSelectedDepartments((prevSelected: string[]) =>
      prevSelected.includes(dept)
        ? prevSelected.filter((item) => item !== dept)
        : [...prevSelected, dept]
    );
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const allDepartmentIds = departments.map((dept: any) => dept.id);
      setTempSelectedDepartments(allDepartmentIds);
    } else {
      setTempSelectedDepartments([]);
    }
  };

  const openModal = () => {
    setTempSelectedDepartments(selectedDepartments);
    setIsModalVisible(true);
  };

  return (
    <>
      <div className="w-full bg-white px-6 py-4 rounded-lg shadow-md">
        <div className="flex justify-between items-center">
          <div className="flex gap-3 items-center">
            <DepartmentIcon className="fill-primary-400 w-[20px] h-[20px]" />
            <Text
              className={`text-neutral-600 text-sm ${
                selectedDepartments?.length > 0 ? "" : "font-semibold"
              }`}
            >
              {selectedDepartments?.length > 0
                ? `Selected Departments (${selectedDepartments?.length})`
                : "Department(s)"}
            </Text>
          </div>
          <Button
            onClick={openModal}
            className="border-none text-primary-600 text-base font-semibold shadow-none"
          >
            {selectedDepartments.length === 0 ? "Select" : "Add More"}
          </Button>
        </div>
        {selectedDepartments.length > 0 && (
          <div className="flex flex-col  border-t border-t-primary-200 pt-4 mt-2 gap-1">
            {selectedDepartments.map((deptId: string) => {
              const dept = departments.find((d: any) => d.id === deptId);
              return (
                <div
                  key={deptId}
                  className="flex items-center px-4 py-3 rounded-lg bg-neutral-100"
                >
                  <Text className="text-neutral-600 text-sm">{dept?.name}</Text>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={null}
      >
        <div className="flex justify-between items-center pb-4 mb-4 border-b border-b-neutral-200">
          <div className="flex gap-3 items-center">
            <DepartmentIcon className="fill-primary-400 w-[20px] h-[20px]" />
            <Text className="text-neutral-400 font-normal text-sm">
              Department(s)
            </Text>
          </div>
          <Checkbox
            onChange={(e) => handleSelectAll(e.target.checked)}
            checked={tempSelectedDepartments.length === departments.length}
          >
            <Text className="text-neutral-400 font-normal text-sm">
              Select All
            </Text>
          </Checkbox>
        </div>
        <div className="flex flex-col max-h-[calc(100vh-300px)] overflow-y-auto">
          {departments &&
            departments.map((dept: any, index: any) => (
              <div
                key={index}
                onClick={() => handleSelect(dept.id)}
                className={`flex items-center px-4 py-3 mb-2 rounded-lg cursor-pointer ${
                  tempSelectedDepartments.includes(dept.id)
                    ? "bg-primary-100"
                    : "bg-neutral-100"
                } hover:bg-primary-200 transition`}
              >
                <Text
                  className={`flex-1 text-neutral-600 text-sm ${
                    tempSelectedDepartments.includes(dept.id)
                      ? "font-semibold"
                      : ""
                  }`}
                >
                  {dept.name}
                </Text>
                {tempSelectedDepartments.includes(dept.id) && (
                  <CheckOutlined className="text-blue-500" />
                )}
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
};

export default DepartmentSelector;
