import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const getCompanyInfoByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.educatorCompanyInfo.replace(":id", id))
  );

export const editCompanyInfoService = async (id: string, companyInfo: any) =>
  handleApiCall(() =>
    ApiService.put(
      apiRoutes.educatorCompanyInfo.replace(":id", id),
      companyInfo
    )
  );
