import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import {
  deleteNewsFeed,
  getLearnerNewsFeed,
  getModule,
} from "../../../../redux/learner/actions";
import {
  selectLearnerNewsFeed,
  selectModules,
  selectModulesLoading,
} from "../../../../redux/learner/selector";

const mapStateToProps = (state: StateType) => ({
  modules: selectModules(state),
  modulesNewsFeed: selectLearnerNewsFeed(state)?.modules,
  loading: selectModulesLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getModule,
      getLearnerNewsFeed,
      deleteNewsFeed,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
