import { LearnerAnalyticsState } from "../../../utils/types/Learner";

export const analyticsTypes = {
  GET_LEARNER_ANALYTICS_ACTION: "GET_LEARNER_ANALYTICS_ACTION",
  SET_LEARNER_ANALYTICS_ACTION: "SET_LEARNER_ANALYTICS_ACTION",
};

export const getLearnerAnalytics = () => ({
  type: analyticsTypes.GET_LEARNER_ANALYTICS_ACTION,
});

export const setLearnerAnalytics = (payload: LearnerAnalyticsState) => ({
  type: analyticsTypes.SET_LEARNER_ANALYTICS_ACTION,
  payload,
});
