import { createSelector } from "@reduxjs/toolkit";

import { LearnerQuizStateType } from "../../../utils/types/Learner";
import { StateType } from "../../../utils";

const selectQuizState = (state: StateType): LearnerQuizStateType =>
  state.learnerQuizReducer;

export const selectQuiz = createSelector(
  selectQuizState,
  (state: LearnerQuizStateType) => state.quiz
);

export const selectQuizLoading = createSelector(
  selectQuizState,
  (state: LearnerQuizStateType) => state.loading
);

export const selectQuizLoaded = createSelector(
  selectQuizState,
  (state: LearnerQuizStateType) => state.loaded
);
