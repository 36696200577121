import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { getLesson } from "../../../../redux/educator/actions";
import {
  selectLessonLoading,
  selectLessons,
} from "../../../../redux/educator/selector";
import { StateType } from "../../../../utils/types";

const mapStateToProps = (state: StateType) => ({
  lessons: selectLessons(state),
  loading: selectLessonLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getLesson,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
