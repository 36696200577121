import { call, all, put, takeLatest } from "redux-saga/effects";

import { AxiosResponse } from "axios";
import { analyticsTypes as types, setLearnerAnalytics } from "../actions";
import { learnerAnalyticsService } from "../../../services/learner/analytics";

export default function* learnerAnalyticsSaga() {
  yield all([
    takeLatest(types.GET_LEARNER_ANALYTICS_ACTION, handleGetLearnerAnalytics),
  ]);
}

function* handleGetLearnerAnalytics() {
  const { data }: AxiosResponse = yield call(learnerAnalyticsService);

  yield put(setLearnerAnalytics(data));
}
