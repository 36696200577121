import { ReactComponent as Course } from "./reusable-items/course.svg";
import { ReactComponent as Lesson } from "./reusable-items/lesson.svg";
import { ReactComponent as Quiz } from "./reusable-items/quiz.svg";
import { ReactComponent as Position } from "./reusable-items/position.svg";
import { ReactComponent as Department } from "./reusable-items/department.svg";
import { ReactComponent as Users } from "./reusable-items/users.svg";

const DashboardItems = {
  Course,
  Lesson,
  Quiz,
  Position,
  Department,
  Users,
};

export default DashboardItems;
