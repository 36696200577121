import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  deleteLesson,
  getLessonById,
} from "../../../../redux/educator/actions";
import { StateType } from "../../../../utils/types";
import {
  selectLesson,
  selectLessonLoading,
} from "../../../../redux/educator/selector";

const mapStateToProps = (state: StateType) => ({
  lesson: selectLesson(state),
  loading: selectLessonLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getLessonById,
      deleteLesson,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
