import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";
import StorageService from "../../services/storageService";

export const logoutUserService = async () => {
  const refreshToken = StorageService.getStringItem("refreshToken");

  return handleApiCall(() =>
    ApiService.post(apiRoutes.logout, { refreshToken })
  );
};
