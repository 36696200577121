import { SetStatisticsOverallPayload } from "../../../utils";

export const statisticsTypes = {
  GET_STATISTICS_OVERALL_ACTION: "GET_STATISTICS_OVERALL_ACTION",
  SET_STATISTICS_OVERALL_ACTION: "SET_STATISTICS_OVERALL_ACTION",
};

export const getStatisticsOverall = () => ({
  type: statisticsTypes.GET_STATISTICS_OVERALL_ACTION,
});

export const setStatisticsOverall = (payload: SetStatisticsOverallPayload) => ({
  type: statisticsTypes.SET_STATISTICS_OVERALL_ACTION,
  payload,
});
