export const moduleTypes = {
  GET_MODULE: "LEARNER_GET_MODULE",
  GET_MODULE_BY_ID: "LEARNER_GET_MODULE_BY_ID",
  SET_MODULE: "LEARNER_SET_MODULE",
  SET_MODULE_BY_ID: "LEARNER_SET_MODULE_BY_ID",
};

export const getModule = () => ({
  type: moduleTypes.GET_MODULE,
});

export const setModule = (payload: any) => ({
  type: moduleTypes.SET_MODULE,
  payload,
});

export const getModuleById = (id: any) => ({
  type: moduleTypes.GET_MODULE_BY_ID,
  payload: id,
});

export const setModuleById = (payload: any) => ({
  type: moduleTypes.SET_MODULE_BY_ID,
  payload,
});
