import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ConnectedProps } from "react-redux";
import { Card, Image, Row, Skeleton, Typography } from "antd";

import connector from "./Container";
import DashboardCardWithImage from "../../../shared/DashboardCardWithImage";
import { ReactComponent as ModuleIcon } from "../../../../assets/images/reusable-items/module.svg";

const { Text } = Typography;

const HomeModule = (props: ConnectedProps<typeof connector>) => {
  const {
    modules,
    loading,
    actions: { getModule },
  } = props;

  useEffect(() => {
    getModule();
  }, []);

  if (loading) {
    return (
      <div className="h-[292px]">
        <Skeleton active paragraph={{ rows: 7 }} />
      </div>
    );
  }

  return (
    <>
      <Row justify={"space-between"} align={"middle"}>
        <div className="text-neutral-600 font-semibold text-base">Modules</div>
        {modules && modules.length > 0 && (
          <Link
            to={"/user/module"}
            className="text-primary-600 font-semibold text-[12px]"
          >
            See All
          </Link>
        )}
      </Row>
      <div className="pt-4 flex gap-4">
        {modules && modules.length > 0 ? (
          <>
            <DashboardCardWithImage content={modules[0]} />
            <DashboardCardWithImage content={modules[1]} />
          </>
        ) : (
          <div className="flex mt-10 justify-center w-full h-[212px]">
            <div className={`px-[40px]`}>
              <div className="flex flex-col gap-10 items-center justify-center">
                <div className="h-[60px]">
                  <ModuleIcon className="w-full h-full fill-primary-400" />
                </div>
                <Text className="text-neutral-600 text-xl font-bold text-center">
                  There are currently no
                  <div>Modules</div>
                </Text>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HomeModule;
