import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { StateType } from "../../../../utils/types";
import {
  selectAnalyticsForEntity,
  selectAnalyticsForSingleEntity,
  selectBenchmarkData,
  selectBenchmarkLoaded,
} from "../../../../redux/educator/selector";
import {
  getAnalytics,
  getAnalyticsLessonById,
  getBenchmarkAnalytics,
  resetSingleLessonAnalytics,
  exportExcel,
} from "../../../../redux/educator/actions";

const mapStateToProps = (state: StateType) => ({
  lessons: selectAnalyticsForEntity(state),
  singleLesson: selectAnalyticsForSingleEntity(state),
  benchmarkLoaded: selectBenchmarkLoaded(state),
  benchmark: selectBenchmarkData(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getAnalytics,
      getAnalyticsLessonById,
      getBenchmarkAnalytics,
      resetSingleLessonAnalytics,
      exportExcel,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
