import { Button } from "antd";

const FilterButtons = ({
  selectedFilter,
  handleFilterChange,
  filterOptions,
}: {
  selectedFilter: string;
  handleFilterChange: (newFilter: string) => void;
  filterOptions: { key: string; value: string }[];
}) => {
  return (
    <div className="flex gap-6 mt-4">
      {filterOptions.map(({ key, value }) => (
        <Button
          key={value}
          type="link"
          onClick={() => handleFilterChange(value)}
          className={
            selectedFilter === value
              ? "text-primary-600 font-semibold pl-0 pr-4"
              : "text-neutral-400 font-normal pl-0 pr-4"
          }
        >
          {key}
        </Button>
      ))}
    </div>
  );
};

export default FilterButtons;
