import DashboardItems from "../../assets/images/dashboard-items";

const educatorStatisticsCard = [
  {
    icon: DashboardItems.Course,
    title: "Courses",
  },
  {
    icon: DashboardItems.Lesson,
    title: "Lessons",
  },
  {
    icon: DashboardItems.Quiz,
    title: "Quizzes",
  },
  {
    icon: DashboardItems.Users,
    title: "Users",
  },
  {
    icon: DashboardItems.Department,
    title: "Departments",
  },
  {
    icon: DashboardItems.Position,
    title: "Positions",
  },
];

export default educatorStatisticsCard;
