export const alertTypes = {
  GET_ALERT: "LEARNER_GET_ALERT",
  SET_ALERT: "LEARNER_SET_ALERT",
};

export const getAlert = () => ({
  type: alertTypes.GET_ALERT,
});

export const setAlert = (payload: any) => ({
  type: alertTypes.SET_ALERT,
  payload,
});
