import { createSelector } from "@reduxjs/toolkit";

import { EducatorUserStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectUserState = (state: StateType): EducatorUserStateType =>
  state.educatorUserReducer;

export const selectUsers = createSelector(
  selectUserState,
  (state: EducatorUserStateType) => state.users
);

export const selectUsersLoaded = createSelector(
  selectUserState,
  (state: EducatorUserStateType) => state.loaded
);

export const selectUser = createSelector(
  selectUserState,
  (state: EducatorUserStateType) => state.user
);

export const selectUsersByRole = createSelector(
  selectUserState,
  (state: EducatorUserStateType) => state.usersByRole
);

export const selectUserLoading = createSelector(
  selectUserState,
  (state: EducatorUserStateType) => state.loading
);

export const selectUserLoaded = createSelector(
  selectUserState,
  (state: EducatorUserStateType) => state.loaded
);
