import { produce } from "immer";
import { quizTypes as types } from "../actions";
import { LearnerQuizStateType } from "../../../utils/types/Learner";

const defaultState: LearnerQuizStateType = {
  quizzes: [],
  quiz: null,
  loaded: false,
  loading: false,
};

const handleSetQuiz = (state: LearnerQuizStateType, payload: any) => {
  state.quiz = payload.quiz;
  state.loading = false;
  state.loaded = true;
  return state;
};

export default produce((state: LearnerQuizStateType = defaultState, action) => {
  switch (action.type) {
    case types.SET_QUIZ_BY_ID:
      return handleSetQuiz(state, action.payload);
    case types.GET_QUIZ_BY_ID:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
});
