import { Button, Form, Typography, Row, Col, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Question from "../../../shared/Question";
import Answers from "../../../shared/Answers";
import showNotification from "../../../../services/notificationService";

const { Text } = Typography;

const QuizzieScreen = (props: any) => {
  const {
    quizzie,
    loading,
    actions: {
      getQuizzieById,
      submitAnswers,
      setInitialSteps,
      setNextStep,
      setPreviousStep,
    },
  } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  const [userAnswers, setUserAnswers] = useState<{ [key: number]: string[] }>(
    {}
  );
  const [currentQuestion, setCurrentQuestion] = useState({ answers: [] });

  useEffect(() => {
    getQuizzieById({ id });
  }, [id, getQuizzieById]);

  useEffect(() => {
    if (quizzie && quizzie.questions) {
      setInitialSteps(quizzie.questions.length);
      setCurrentQuestion(quizzie.questions[currentIndex]);
    }
  }, [quizzie, setInitialSteps]);

  useEffect(() => {
    setSelectedAnswers(userAnswers[currentIndex] || []);
    if (quizzie && quizzie.questions)
      setCurrentQuestion(quizzie.questions[currentIndex]);
  }, [currentIndex, userAnswers]);

  const handleNextQuestion = () => {
    if (selectedAnswers.length > 0) {
      setUserAnswers({
        ...userAnswers,
        [currentIndex]: selectedAnswers,
      });
      setSelectedAnswers([]);
      setCurrentIndex(currentIndex + 1);
      setNextStep();
    } else {
      showNotification("error", "Please select an answer");
    }
  };

  const handlePreviousQuestion = () => {
    setUserAnswers({
      ...userAnswers,
      [currentIndex]: selectedAnswers,
    });
    setSelectedAnswers(userAnswers[currentIndex - 1] || []);
    setCurrentIndex(currentIndex - 1);
    setPreviousStep();
  };

  const handleSubmitQuiz = () => {
    if (selectedAnswers.length > 0) {
      const updatedUserAnswers = {
        ...userAnswers,
        [currentIndex]: selectedAnswers,
      };

      setUserAnswers(updatedUserAnswers);

      const answers = {
        answers: Object.keys(updatedUserAnswers).map((key) => ({
          questionId: quizzie.questions[parseInt(key)].id,
          answerIds: updatedUserAnswers[parseInt(key)],
        })),
      };

      submitAnswers({
        id,
        answers,
        quizzie: {
          ...quizzie,
          minimumCorrectQusetions:
            quizzie.minimumCorrectAnswers === quizzie.questions.length
              ? "all"
              : quizzie.minimumCorrectAnswers,
        },
        navigate,
      });
    } else {
      showNotification("error", "Please select an answer");
    }
  };

  if (!quizzie || quizzie.length === 0 || loading) {
    return <Skeleton active />;
  }

  return (
    <Col span={24}>
      <Row className="mb-10">
        <div className="w-full bg-white p-6 h-[74px] flex justify-between">
          <Text className="text-neutral-600 text-base font-semibold">
            Finish the Quizzie to collect the Points
          </Text>
          <div className="bg-rose-400 rounded-lg w-[73px] text-center">
            <Text className="font-semibold text-sm text-white leading-7">
              {quizzie.points} {""}
              <span className="text-[10px] font-normal">PTS</span>
            </Text>
          </div>
        </div>
      </Row>
      <Row gutter={[40, 0]}>
        <Col lg={12} sm={24}>
          <Question
            question={currentQuestion}
            currentQuestionIndex={currentIndex + 1}
            numberOfQuestions={quizzie.questions.length}
          />
        </Col>
        <Col lg={12} sm={24}>
          <div className="flex flex-col mb-4">
            <Text className="text-neutral-600 text-base font-semibold">
              Select correct answer/s
            </Text>
            <Text className="text-neutral-500 text-sm">
              Note: One or more answers could be correct
            </Text>
          </div>
          <Form className=" bg-white rounded-lg flex flex-col gap-2 p-6">
            <Answers
              answers={currentQuestion.answers}
              selectedAnswers={selectedAnswers}
              setSelectedAnswers={setSelectedAnswers}
            />
            <div className="flex justify-between mt-3 gap-4">
              {currentIndex > 0 && (
                <Button
                  className="flex-1  h-[50px] font-semibold text-base border-primary-200 text-primary-600"
                  onClick={handlePreviousQuestion}
                >
                  {"<"} Back
                </Button>
              )}

              {currentIndex + 1 !== quizzie.questions.length ? (
                <Button
                  type="primary"
                  className="flex-1 bg-primary-600 h-[50px] font-semibold text-base"
                  onClick={handleNextQuestion}
                >
                  Continue
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="flex-1 bg-primary-600 h-[50px] font-semibold text-base"
                  onClick={handleSubmitQuiz}
                >
                  Finish the Quizzie
                </Button>
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </Col>
  );
};

export default QuizzieScreen;
