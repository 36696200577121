import { useState, useEffect } from "react";
import { Col, Row, Form } from "antd";

import QuizForm from "./Form/QuizForm";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import QuizOptions from "./Options/QuizOptions";
import { useNavigate } from "react-router-dom";

const CreateQuiz = (props: any) => {
  const {
    actions: { setInitialSteps, setNextStep, setPreviousStep, addQuiz },
    submissionLoading,
  } = props;

  const [quizStep, setQuizStep] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    setInitialSteps(2);
  }, []);

  const onFinishQuiz = () => {
    addQuiz({ quiz: formValues, navigate });
  };

  return (
    <>
      <Form
        form={form}
        name="quizForm"
        autoComplete="off"
        size="small"
        className="h-full"
        initialValues={{
          questions: [
            {
              question: "",
              answers: [
                {
                  answer: "",
                  isCorrect: false,
                },
              ],
            },
          ],
        }}
        onFinish={onFinishQuiz}
        onValuesChange={() => setFormValues(form.getFieldsValue())}
      >
        {quizStep === 1 ? (
          <Row>
            <Col lg={8}>
              <CreateEntityInfo
                title="Test your students"
                text="Create a Quiz by adding the questions and related answers. Once you finalize all questions proceed to Finish Quiz."
              ></CreateEntityInfo>
            </Col>
            <Col lg={16}>
              <QuizForm
                form={form}
                setNextStep={setNextStep}
                setQuizStep={setQuizStep}
                isEdit={false}
                isQuizzie={false}
              ></QuizForm>
            </Col>
          </Row>
        ) : (
          <QuizOptions
            form={form}
            formValues={formValues}
            setFormValues={setFormValues}
            setQuizStep={setQuizStep}
            setPreviousStep={setPreviousStep}
            loading={submissionLoading}
          ></QuizOptions>
        )}
      </Form>
    </>
  );
};

export default CreateQuiz;
