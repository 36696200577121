import React from "react";
import { Avatar, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

const { Text } = Typography;

const ActivityUserListItem = ({ user }: any) => {
  return (
    <div className="flex gap-3">
      <div className="w-full h-[36px] bg-neutral-100 rounded-lg flex items-center px-4 mb-2 gap-2">
        <div className="flex items-center gap-2">
          <Avatar
            className="text-sm"
            style={{
              fontSize: "0.85rem",
              height: 26,
              width: 26,
            }}
            icon={<UserOutlined />}
            src={
              user.profilePictureKey
                ? `${process.env.REACT_APP_CLOUDFRONT_KEY}/${user.profilePictureKey}`
                : null
            }
          />
          <Text className="text-neutral-600 font-semibold text-sm leading-4 ml-3">
            {user.email}
          </Text>
        </div>
      </div>
      <div
        className={`flex mb-2 ${
          user?.isActive ? "bg-primary-100" : "bg-neutral-100"
        } rounded-lg px-4 items-center ${
          user?.isActive ? "text-primary-600" : "text-primary-600"
        }`}
      >
        {user?.isActive ? "A" : "P"}
      </div>
    </div>
  );
};

export default ActivityUserListItem;
