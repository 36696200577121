import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const addDepartmentService = async (departmentData: any) =>
  handleApiCall(() => ApiService.post(apiRoutes.department, departmentData));

export const getDepartmentService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.department));

export const getDepartmentUserService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.departmentUsers));

export const getDepartmentByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.departmentById.replace(":id", id))
  );

export const deleteDepartmentByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.departmentById.replace(":id", id))
  );

export const editDepartmentService = async (id: string, departmentData: any) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.departmentById.replace(":id", id), departmentData)
  );
