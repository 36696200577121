import { useEffect, useState } from "react";
import { Button, Typography, Skeleton, Input } from "antd";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import EmptyState from "../../../shared/EmptyState";
import QuizCard from "../../../shared/QuizCard";
import FilterButtons from "../../../shared/FilterButtons";
import { newsFeedType } from "../../../../utils/constants/newsFeedTypes";

const ViewQuizzie = (props: any) => {
  const {
    actions: { getQuizzie, getLearnerNewsFeed, deleteNewsFeed },
    quizzies,
    quizziesNewsFeed,
    loading,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("ALL");
  const [filteredQuizzies, setFilteredQuizzies] = useState(quizzies);
  const filterProps = [
    { key: "ALL", value: "ALL" },
    { key: "FINISHED", value: "FINISHED" },
    { key: "UNFINISHED", value: "UNFINISHED" },
  ];

  useEffect(() => {
    getQuizzie();
  }, [getQuizzie]);

  useEffect(() => {
    getLearnerNewsFeed();
  }, []);

  useEffect(() => {
    if (quizziesNewsFeed && quizziesNewsFeed > 0) {
      deleteNewsFeed({ type: newsFeedType.quizzie });
    }
  }, [quizziesNewsFeed]);

  useEffect(() => {
    let filtered = quizzies;

    if (filter === "FINISHED") {
      filtered = filtered.filter((q: any) => q.isFinished);
    } else if (filter === "UNFINISHED") {
      filtered = filtered.filter((q: any) => !q.isFinished);
    }

    if (searchTerm) {
      filtered = filtered.filter((course: any) =>
        course.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredQuizzies(filtered);
  }, [searchTerm, filter, quizzies]);

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
  };

  if (loading) return <Skeleton active />;

  return (
    <div className="h-full">
      {quizzies && quizzies.length > 0 ? (
        <>
          <div className="flex flex-col gap-3 mb-4">
            <Input
              placeholder="Search"
              prefix={<SearchIcon className="mx-3" />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-1/2"
            />
            <FilterButtons
              selectedFilter={filter}
              handleFilterChange={handleFilterChange}
              filterOptions={filterProps}
            />
          </div>

          {filteredQuizzies && filteredQuizzies.length > 0 ? (
            <div className="grid grid-cols-3 gap-10 max-h-[calc(100vh-300px)] overflow-auto">
              {filteredQuizzies.map((quizzie: any) => (
                <QuizCard key={quizzie.id} quizzie={quizzie} />
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <EmptyState />
            </div>
          )}
        </>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </div>
  );
};

export default ViewQuizzie;
