import { Button, Typography, Row, Col, Skeleton, Card, Image } from "antd";
import { useEffect } from "react";
import EntityCard from "../../../shared/EntityCard";
import { useNavigate, useParams } from "react-router-dom";
import EmptyState from "../../../shared/EmptyState";
import testImage from "../../../../assets/images/default-cover-image.jpg";
import { ReactComponent as CourseIcon } from "../../../../assets/images/reusable-items/course.svg";
import { ReactComponent as DurationIcon } from "../../../../assets/images/reusable-items/duration.svg";

const { Text } = Typography;

const ModuleDetailsScreen = (props: any) => {
  const {
    module,
    loading,
    actions: { getModuleById },
  } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getModuleById({ id });
  }, []);

  if (loading) return <Skeleton active />;

  return (
    <Row className="pb-[60px]" gutter={[40, 40]}>
      <Col span={12}>
        <Card className="shadow-md p-10">
          <div className="h-[250px]">
            <Image
              src={
                module?.coverImageKey
                  ? `${process.env.REACT_APP_CLOUDFRONT_KEY}/${module?.coverImageKey}`
                  : testImage
              }
              preview={false}
              className="rounded-lg object-cover"
            />
          </div>
          <Text className="text-xl text-neutral-600 font-semibold block pt-5 pb-3">
            {module?.title}
          </Text>
          <div className="w-3/4 flex-wrap flex gap-5  border-b border-b-neutral-200 pb-3 my-5">
            <div className="flex gap-2 items-center">
              <CourseIcon className="h-6 fill-primary-300" />
              <Text className="text-base text-neutral-500">
                {module?.courseCount} Courses
              </Text>
            </div>
            <div className="flex items-center gap-2">
              <DurationIcon className="h-6 fill-primary-300" />
              <Text className="text-base text-neutral-500 ">
                {module?.duration}
              </Text>
            </div>
          </div>
          <Text className="text-base text-neutral-600 font-semibold block mb-3">
            About The Module
          </Text>
          <Text className="text-neutral-600 text-[13px] leading-5 block mb-4">
            {module?.description}
          </Text>

          <div className="bg-rose-400 h-[50px] w-[200px] flex items-center justify-center rounded-lg">
            <div>
              <Text className="text-white font-semibold text-base">
                {module?.points}{" "}
              </Text>
              <Text className="text-white font-semibold text-xs">
                Available PTS
              </Text>
            </div>
          </div>
        </Card>
      </Col>
      <Col span={12}>
        <div className="w-full ">
          <div className="module-details-max-height flex flex-col gap-4">
            {module?.courses && module?.courses?.length > 0 ? (
              <>
                <Text className="text-neutral-600 font-semibold text-lg mt-3">
                  Courses
                </Text>
                {module?.courses.map((course: any) => (
                  <EntityCard
                    key={course.id}
                    title={course.title}
                    duration={course.duration || "0 hr 0 min"}
                    coverImageKey={course.coverImageKey || ""}
                    footer={
                      <Button
                        type="primary"
                        className="w-full h-6 bg-primary-600 "
                        key="view"
                        onClick={() => navigate(`/user/course/${course.id}`)}
                      >
                        View Course
                      </Button>
                    }
                    classes="w-full max-w-[507px] h-[200px]"
                  />
                ))}
              </>
            ) : (
              <Card
                className={` 
                shadow-md py-[67px]`}
              >
                <div className={`px-[40px]`}>
                  <div className="flex flex-col gap-10 items-center justify-center">
                    <div className="h-[60px]">
                      <CourseIcon className="w-full h-full fill-primary-400" />
                    </div>
                    <Text className="text-neutral-600 text-xl font-bold text-center">
                      There are currently no
                      <div>Courses</div>
                    </Text>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ModuleDetailsScreen;
