import { createSelector } from "@reduxjs/toolkit";

import { EducatorPositionStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectPositionState = (state: StateType): EducatorPositionStateType =>
  state.educatorPositionReducer;

export const selectPositions = createSelector(
  selectPositionState,
  (state: EducatorPositionStateType) => state.positions
);
export const selectPositionsLoaded = createSelector(
  selectPositionState,
  (state: EducatorPositionStateType) => state.loaded
);

export const selectPosition = createSelector(
  selectPositionState,
  (state: EducatorPositionStateType) => state.position
);

export const selectPositionWithUsers = createSelector(
  selectPositionState,
  (state: EducatorPositionStateType) => state.positionsWithUsers
);

export const selectPositionLoading = createSelector(
  selectPositionState,
  (state: EducatorPositionStateType) => state.loading
);

export const selectPositionLoaded = createSelector(
  selectPositionState,
  (state: EducatorPositionStateType) => state.loaded
);
