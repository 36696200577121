import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import { getLatestCourse } from "../../../../redux/learner/actions";
import {
  selectLatestCourse,
  selectCoursesLoading,
} from "../../../../redux/learner/selector";

const mapStateToProps = (state: StateType) => ({
  latestCourse: selectLatestCourse(state),
  loading: selectCoursesLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getLatestCourse,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
