import { produce } from "immer";

import { lessonTypes as types } from "../actions";
import { EducatorLessonStateType } from "../../../utils";

const defaultState: EducatorLessonStateType = {
  lessons: [],
  lesson: null,
  loaded: false,
  loading: false,
};

const handleSetLesson = (state: any, payload: any) => {
  state.lesson = payload.lesson;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetLessons = (state: any, payload: any) => {
  state.lessons = payload.lessons;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetGeneratedSummary = (state: any, payload: any) => {
  state.lesson = { ...state.lesson, summary: payload };
  state.loading = false;

  return state;
};

export default produce(
  (state: EducatorLessonStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_LESSON:
        return handleSetLessons(state, action.payload);
      case types.SET_LESSON_BY_ID:
        return handleSetLesson(state, action.payload);
      case types.SET_GENERATE_SUMMARY:
        return handleSetGeneratedSummary(state, action.payload);
      case types.GET_LESSON_BY_ID:
        return {
          ...state,
          loading: true,
        };
      case types.GET_LESSON:
        return {
          ...state,
          loading: true,
        };
      case types.GET_GENERATE_SUMMARY:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
