import { createSelector } from "@reduxjs/toolkit";

import { EducatorCourseStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectCourseState = (state: StateType): EducatorCourseStateType =>
  state.educatorCourseReducer;

export const selectCourses = createSelector(
  selectCourseState,
  (state: EducatorCourseStateType) => state.courses
);

export const selectCourse = createSelector(
  selectCourseState,
  (state: EducatorCourseStateType) => state.course
);

export const selectCoursesLoading = createSelector(
  selectCourseState,
  (state: EducatorCourseStateType) => state.loading
);

export const selectCoursesLoaded = createSelector(
  selectCourseState,
  (state: EducatorCourseStateType) => state.loaded
);
