export const companyInfoTypes = {
  GET_COMPANY_INFO_BY_ID: "GET_COMPANY_INFO_BY_ID",
  SET_COMPANY_INFO_BY_ID: "SET_COMPANY_INFO_BY_ID",
  EDIT_COMPANY_INFO: "EDIT_COMPANY_INFO",
};

export const getCompanyInfoById = (id: any) => ({
  type: companyInfoTypes.GET_COMPANY_INFO_BY_ID,
  payload: id,
});

export const setCompanyInfoById = (payload: any) => ({
  type: companyInfoTypes.SET_COMPANY_INFO_BY_ID,
  payload,
});

export const editCompanyInfo = (payload: any) => ({
  type: companyInfoTypes.EDIT_COMPANY_INFO,
  payload,
});
