import CreationSuccess from "../../modules/shared/CreationSuccess";

const SuccessPopUpLayout = () => {
  return (
    <div className="bg-primary-900 h-screen flex items-center justify-center">
      <CreationSuccess />
    </div>
  );
};

export default SuccessPopUpLayout;
