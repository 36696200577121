const removeItem = (key: string) => {
  localStorage.removeItem(key);
};

const setStringItem = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error(error);
  }
};

const setObjectItem = (key: string, value: object) => {
  try {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem(key, jsonValue);
  } catch (error) {
    console.error(error);
  }
};

const getStringItem = (key: string) => {
  try {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (error) {
    console.error(error);
  }
};

const getObjectItem = (key: string) => {
  try {
    const jsonValue = localStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (error) {
    console.error(error);
  }
};

const clearStorage = () => {
  localStorage.clear();
};

const StorageService = {
  setStringItem,
  setObjectItem,
  getStringItem,
  getObjectItem,
  removeItem,
  clearStorage,
};

export default StorageService;
