import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { deleteModule, getModuleById } from "../../../../redux/educator/actions";
import { StateType } from "../../../../utils/types";
import { selectModule } from "../../../../redux/educator/selector";

const mapStateToProps = (state: StateType) => ({
  module: selectModule(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getModuleById,
      deleteModule
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
