import { useEffect, useRef, useState } from "react";
import Player from "@vimeo/player";

const VideoPlayer = ({
  videoUri,
  onEnd,
}: {
  videoUri: string;
  onEnd: () => void;
}) => {
  const playerRef = useRef<HTMLDivElement>(null);
  const [videoId, setVideoId] = useState(
    parseInt(videoUri.split("/").pop() || "")
  );
  useEffect(() => {
    if (playerRef.current) {
      const player = new Player(playerRef.current, {
        id: videoId,
        responsive: true,
      });

      let lastTime = 0;

      player.on("timeupdate", (data: any) => {
        if (data.seconds > lastTime + 1) {
          player.setCurrentTime(lastTime);
        } else {
          lastTime = data.seconds;
        }
      });

      player.on("ended", () => {
        onEnd();
      });

      return () => {
        player.unload();
      };
    }
  }, [videoId]);

  useEffect(() => {
    setVideoId(parseInt(videoUri.split("/").pop() || ""));
  }, [videoUri]);

  return <div ref={playerRef}></div>;
};

export default VideoPlayer;
