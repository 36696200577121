import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  Select,
  Button,
  Typography,
  Upload,
  Carousel,
  Form,
} from "antd";
import avatar from "../../../../utils/constants/avatar";
import showNotification from "../../../../services/notificationService";
import languageVoice from "../../../../utils/constants/languageVoice";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UploadPDFIcon } from "../../../../assets/images/upload-pdf.svg";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

type Language = keyof typeof languageVoice;
type Voice = keyof (typeof languageVoice)[Language]["voices"];

const CreateAIContent = (props: any) => {
  const {
    extractedText,
    actions: { addVideo, extractText },
  } = props;

  const [selectedAvatar, setSelectedAvatar] = useState<any>(avatar[0]);
  const [title, setTitle] = useState<string>("");
  const [language, setLanguage] = useState<Language>("bosnian");
  const [voice, setVoice] = useState<Voice>("female");
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any>([]);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  useEffect(() => {
    if (extractedText) {
      form.setFieldsValue({ textInput: extractedText });
    }
  }, [extractedText, form]);

  const handleAvatarSelect = (avatar: any) => {
    setSelectedAvatar(avatar);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleLanguageChange = (value: Language) => {
    setLanguage(value);
  };

  const handleVoiceChange = (value: Voice) => {
    setVoice(value);
  };

  const handleFileChange = async ({ fileList }: { fileList: any }) => {
    setFileList(fileList);

    if (fileList.length > 0) {
      const file = fileList[0].originFileObj as File;
      const formData = new FormData();
      formData.append("file", file);

      try {
        await extractText(formData);
      } catch (error) {
        console.error("Error extracting text from file:", error);
        showNotification("error", "Error extracting text from file");
      }
    }
  };

  const handleRemoveFile = () => {
    setFileList([]);
    form.setFieldsValue({ textInput: "" });
  };

  const handleSubmit = async (values: any) => {
    const { title, textInput } = values;

    setLoading(true);

    const languageCode = languageVoice[language].code;
    const voiceName = languageVoice[language].voices[voice];

    let ssmlText = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="${languageCode}">
                    <voice name="${languageCode}-${voiceName}">${textInput}</voice>
                  </speak>`;

    const requestBody = {
      title,
      inputKind: "SSML",
      inputs: [{ content: ssmlText }],
      avatarConfig: {
        talkingAvatarCharacter: selectedAvatar.character,
        talkingAvatarStyle: selectedAvatar.style,
        videoCodec: "h264",
      },
    };

    addVideo({ videoData: requestBody, navigate });
  };

  return (
    <div className="flex flex-col gap-2 pb-8">
      <Text className="text-neutral-600 text-base font-semibold">
        Create AI Content
      </Text>
      <Form onFinish={handleSubmit} form={form}>
        <Form.Item
          name="title"
          rules={[{ required: true, message: "Please enter the title" }]}
        >
          <Input
            placeholder="Enter title"
            value={title}
            onChange={handleTitleChange}
          />
        </Form.Item>
        <div className="bg-white rounded-lg my-4 shadow-sm">
          <Carousel
            dots={false}
            slidesToShow={4}
            slidesToScroll={4}
            infinite={true}
            draggable={true}
            arrows={true}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {avatar.map((av: any, index: number) => (
              <div key={index} className="p-3">
                <div
                  onClick={() => handleAvatarSelect(av)}
                  className={`cursor-pointer shadow-md bg-neutral-100 rounded-md hover:shadow-xl ${
                    av.avatar === selectedAvatar?.avatar
                      ? "border-2 border-primary-300"
                      : ""
                  }`}
                >
                  <img
                    src={av.avatar}
                    alt={av.character}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="h-[300px]">
              {selectedAvatar ? (
                <img
                  src={selectedAvatar.avatar}
                  alt={selectedAvatar.character}
                  className="object-cover h-full m-auto"
                />
              ) : (
                <p>Please select an avatar</p>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className="flex flex-col gap-3 h-full">
              <Select
                defaultValue="bosnian"
                onChange={handleLanguageChange}
                className="h-[50px] w-full"
              >
                <Option value="bosnian">Bosnian</Option>
                <Option value="english">English</Option>
              </Select>
              <Select
                defaultValue="female"
                onChange={handleVoiceChange}
                className="h-[50px] w-full"
              >
                <Option value="female">Female</Option>
                <Option value="male">Male</Option>
              </Select>
              <Upload
                name="file"
                listType="picture-card"
                maxCount={1}
                beforeUpload={() => false}
                accept=".pdf, .docx"
                className="bg-white rounded-md flex-1"
                fileList={fileList}
                onChange={handleFileChange}
                showUploadList={false}
              >
                {fileList.length === 0 ? (
                  <div className="flex flex-col items-center justify-center">
                    <UploadPDFIcon />
                    <p className="text-neutral-600 text-base font-medium">
                      Upload a file
                    </p>
                    <p className="text-xs text-neutral-500 font-normal">
                      PDF or DOCX
                    </p>
                  </div>
                ) : (
                  <div className="relative flex justify-center items-center w-full h-full">
                    <p>{fileList[0].name}</p>
                    <Button
                      onClick={handleRemoveFile}
                      className="absolute top-2 right-2 bg-red-500 text-white"
                    >
                      Remove
                    </Button>
                  </div>
                )}
              </Upload>
            </div>
          </Col>
        </Row>

        <div className="my-4">
          <Form.Item
            name="textInput"
            rules={[{ required: true, message: "Please enter the text" }]}
          >
            <TextArea placeholder="Enter text" rows={10} />
          </Form.Item>
        </div>
        <div className="w-full flex justify-end">
          <Button
            type="primary"
            className="w-1/2 h-[50px]"
            htmlType="submit"
            loading={loading}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CreateAIContent;
