import { ReactComponent as Step1 } from "./step1.svg";
import { ReactComponent as Step2 } from "./step2.svg";
import { ReactComponent as Step3 } from "./step3.svg";
import { ReactComponent as Step4 } from "./step4.svg";
import { ReactComponent as Add } from "./add.svg";

const CompanyStructureItems = {
  Step1,
  Step2,
  Step3,
  Step4,
  Add,
};

export default CompanyStructureItems;
