import { call, all, put, takeLatest } from "redux-saga/effects";

import { userTypes as types } from "../actions";
import { AxiosResponse } from "axios";
import { logoutUserService } from "../../../services/learner/user";
import { EntityIdPayload } from "../../../utils";

export default function* learnerUserSagas() {
  yield all([takeLatest(types.LOGOUT, handleLogout)]);
}

function* handleLogout(action: any) {
  const { data }: AxiosResponse = yield call(logoutUserService);
  action.payload.navigate("/login");
}
