import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const addVideoService = async (videoData: any) =>
  handleApiCall(() => ApiService.post(apiRoutes.aiContent, videoData));

export const getVideoService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.aiContent));

export const deleteVideoByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.aiContentById.replace(":id", id))
  );

export const extractTextService = async (file: any) =>
  handleApiCall(() =>
    ApiService.post(apiRoutes.extractText, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  );
