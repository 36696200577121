import { createSelector } from "@reduxjs/toolkit";

import { EducatorModuleStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectModuleState = (state: StateType): EducatorModuleStateType =>
  state.educatorModuleReducer;

export const selectModules = createSelector(
  selectModuleState,
  (state: EducatorModuleStateType) => state.modules
);

export const selectModule = createSelector(
  selectModuleState,
  (state: EducatorModuleStateType) => state.module
);

export const selectModulesLoading = createSelector(
  selectModuleState,
  (state: EducatorModuleStateType) => state.loading
);

export const selectModuleLoaded = createSelector(
  selectModuleState,
  (state: EducatorModuleStateType) => state.loaded
);
