import { Row, Col, Skeleton } from "antd";
import { useEffect, useState } from "react";
import Chart from "../Dashboard/components/Chart";
import EntityHeader from "./components/EntityHeader";

const EducatorBenchmarkScreen = (props: any) => {
  const {
    loading,
    loaded,
    benchmark,
    actions: { getBenchmarkAnalytics },
  } = props;

  const [usersTimelineValue, setUsersTimelineValue] = useState("week");
  const [modulesTimelineValue, setModulesTimelineValue] = useState("week");
  const [coursesTimelineValue, setCoursesTimelineValue] = useState("week");
  const [lessonsTimelineValue, setLessonsTimelineValue] = useState("week");

  const [usersData, setUsersData] = useState([]);
  const [modulesData, setModulesData] = useState([]);
  const [coursesData, setCoursesData] = useState([]);
  const [lessonsData, setLessonsData] = useState([]);

  useEffect(() => {
    document.body.classList.add("educator-benchmark-layout");
    return () => {
      document.body.classList.remove("educator-benchmark-layout");
    };
  }, []);

  useEffect(() => {
    if (!loaded) {
      getBenchmarkAnalytics();
    }
  }, []);

  useEffect(() => {
    if (loaded) {
      usersWeekData();
      modulesWeekData();
      coursesWeekData();
      lessonWeekData();
    }
  }, [loaded]);

  const handleChangeUsersTimeline = (value: string) => {
    setUsersTimelineValue(value);

    if (value === "week") {
      usersWeekData();
      return;
    }
    if (value === "month") {
      usersMonthData();
      return;
    }
    if (value === "year") {
      usersYearData();
      return;
    }
  };

  const handleChangeModulesTimeline = (value: string) => {
    setModulesTimelineValue(value);

    if (value === "week") {
      modulesWeekData();
      return;
    }
    if (value === "month") {
      modulesMonthData();
      return;
    }
    if (value === "year") {
      modulesYearData();
      return;
    }
  };

  const handleChangeCoursesTimeline = (value: string) => {
    setCoursesTimelineValue(value);

    if (value === "week") {
      coursesWeekData();
      return;
    }
    if (value === "month") {
      coursesMonthData();
      return;
    }
    if (value === "year") {
      coursesYearData();
      return;
    }
  };

  const handleChangeLessonsTimeline = (value: string) => {
    setLessonsTimelineValue(value);

    if (value === "week") {
      lessonWeekData();
      return;
    }
    if (value === "month") {
      lessonMonthData();
      return;
    }
    if (value === "year") {
      lessonYearData();
      return;
    }
  };

  const usersWeekData = () => {
    const weekUserData = benchmark?.users?.slice(0, 7);
    const chartData = getChartDataForWeek(weekUserData);
    setUsersData(chartData);
  };

  const usersMonthData = () => {
    const monthUserData = benchmark?.users?.slice(0, 30);
    const chartData = getChartDataForMonth(monthUserData);
    setUsersData(chartData);
  };

  const usersYearData = () => {
    const yearUserData = benchmark?.users?.slice(0, 365);
    const chartData = getChartDataForYear(yearUserData);
    setUsersData(chartData);
  };

  const modulesWeekData = () => {
    const weekModulesData = benchmark?.modules?.slice(0, 7);
    const chartData = getChartDataForWeek(weekModulesData);
    setModulesData(chartData);
  };

  const modulesMonthData = () => {
    const monthModulesData = benchmark?.modules?.slice(0, 30);
    const chartData = getChartDataForMonth(monthModulesData);
    setModulesData(chartData);
  };

  const modulesYearData = () => {
    const yearModulesData = benchmark?.modules?.slice(0, 365);
    const chartData = getChartDataForYear(yearModulesData);
    setModulesData(chartData);
  };

  const coursesWeekData = () => {
    const weekCourseData = benchmark?.courses?.slice(0, 7);
    const chartData = getChartDataForWeek(weekCourseData);
    setCoursesData(chartData);
  };

  const coursesMonthData = () => {
    const monthCourseData = benchmark?.courses?.slice(0, 30);
    const chartData = getChartDataForMonth(monthCourseData);
    setCoursesData(chartData);
  };

  const coursesYearData = () => {
    const yearCourseData = benchmark?.courses?.slice(0, 365);
    const chartData = getChartDataForYear(yearCourseData);
    setCoursesData(chartData);
  };

  const lessonWeekData = () => {
    const weekLessonData = benchmark?.lessons?.slice(0, 7);
    const chartData = getChartDataForWeek(weekLessonData);
    setLessonsData(chartData);
  };

  const lessonMonthData = () => {
    const monthLessonData = benchmark?.lessons?.slice(0, 30);
    const chartData = getChartDataForMonth(monthLessonData);
    setLessonsData(chartData);
  };

  const lessonYearData = () => {
    const yearLessonData = benchmark?.lessons?.slice(0, 365);
    const chartData = getChartDataForYear(yearLessonData);
    setLessonsData(chartData);
  };

  const getChartDataForWeek = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).toLocaleDateString("en-US", {
        weekday: "short",
      }),
    }));
  };

  const getChartDataForMonth = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).getDate(),
    }));
  };

  const getChartDataForYear = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).toLocaleDateString("en-US", {
        month: "short",
      }),
    }));
  };

  const timeLineOptions = [
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
    { value: "year", label: "Year" },
  ];

  const LoadingState = () => (
    <Row gutter={[32, 32]}>
      <Col span={12} className="flex flex-col gap-3">
        <Skeleton active />
      </Col>
      <Col span={12} className="flex flex-col gap-3">
        <Skeleton active />
      </Col>
      <Col span={12} className="flex flex-col gap-3">
        <Skeleton active />
      </Col>
      <Col span={12} className="flex flex-col gap-3">
        <Skeleton active />
      </Col>
    </Row>
  );

  return (
    <>
      <div className="max-w-[522px] mb-10">
        <div className="text-primary-600 text-xl font-semibold mb-2">
          Benchamark Activity
        </div>
        <div className="text-neutral-500 text-sm">
          Compare the activity of Students, Modules, Courses and Lessons and
          filter the data by time frame (week, month, year).
        </div>
      </div>
      {loading ? (
        <LoadingState></LoadingState>
      ) : (
        <div className="pb-[80px]">
          <Row gutter={[32, 32]}>
            <Col span={12} className="flex flex-col gap-3">
              <div>
                <EntityHeader
                  filterOptions={timeLineOptions}
                  title="Students"
                  selectedOption={usersTimelineValue}
                  onSelectionChange={handleChangeUsersTimeline}
                ></EntityHeader>
              </div>
              <div>
                <Chart
                  type="activity"
                  chartData={usersData}
                  xAxisDataKey="dateDay"
                ></Chart>
              </div>
            </Col>
            <Col span={12} className="flex flex-col gap-3">
              <div>
                <EntityHeader
                  filterOptions={timeLineOptions}
                  title="Modules"
                  selectedOption={modulesTimelineValue}
                  onSelectionChange={handleChangeModulesTimeline}
                ></EntityHeader>
              </div>
              <div>
                <Chart
                  type="performance"
                  chartData={modulesData}
                  xAxisDataKey="dateDay"
                ></Chart>
              </div>
            </Col>
            <Col span={12} className="flex flex-col gap-3">
              <div>
                <EntityHeader
                  filterOptions={timeLineOptions}
                  title="Courses"
                  selectedOption={coursesTimelineValue}
                  onSelectionChange={handleChangeCoursesTimeline}
                ></EntityHeader>
              </div>
              <div>
                <Chart
                  type="performance"
                  chartData={coursesData}
                  xAxisDataKey="dateDay"
                ></Chart>
              </div>
            </Col>
            <Col span={12} className="flex flex-col gap-3">
              <div>
                <EntityHeader
                  filterOptions={timeLineOptions}
                  title="Lessons"
                  selectedOption={lessonsTimelineValue}
                  onSelectionChange={handleChangeLessonsTimeline}
                ></EntityHeader>
              </div>
              <div>
                <Chart
                  type="performance"
                  chartData={lessonsData}
                  xAxisDataKey="dateDay"
                ></Chart>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default EducatorBenchmarkScreen;
