import { createSelector } from "@reduxjs/toolkit";

import { EducatorAnalyticsStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectAnalyticsState = (state: StateType): EducatorAnalyticsStateType =>
  state.educatorAnalyticsReducer;

export const selectAnalyticsForEntity = createSelector(
  selectAnalyticsState,
  (state: EducatorAnalyticsStateType) => state.entity
);

export const selectAnalyticsForSingleEntity = createSelector(
  selectAnalyticsState,
  (state: EducatorAnalyticsStateType) => state.singleEntity
);

export const selectBenchmarkLoading = createSelector(
  selectAnalyticsState,
  (state: EducatorAnalyticsStateType) => state.benchmark.loading
);

export const selectBenchmarkLoaded = createSelector(
  selectAnalyticsState,
  (state: EducatorAnalyticsStateType) => state.benchmark.loaded
);

export const selectBenchmarkData = createSelector(
  selectAnalyticsState,
  (state: EducatorAnalyticsStateType) => state.benchmark.data
);
