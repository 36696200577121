import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { StateType } from "../../../../utils/types";
import {
  selectUsers,
  selectBenchmarkData,
  selectBenchmarkLoaded,
} from "../../../../redux/educator/selector";
import {
  getUser,
  getBenchmarkAnalytics,
  exportExcel
} from "../../../../redux/educator/actions";

const mapStateToProps = (state: StateType) => ({
  users: selectUsers(state),
  benchmarkLoaded: selectBenchmarkLoaded(state),
  benchmark: selectBenchmarkData(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getUser,
      getBenchmarkAnalytics,
      exportExcel
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
