import { call, all, put, takeLatest } from "redux-saga/effects";

import { setAlert, alertTypes as types } from "../actions";
import { AxiosResponse } from "axios";
import { getAlertService } from "../../../services/learner/alert";
import { EntityIdPayload } from "../../../utils";

export default function* learnerAlertSagas() {
  yield all([takeLatest(types.GET_ALERT, handleGetAlert)]);
}

function* handleGetAlert() {
  const { data }: AxiosResponse = yield call(getAlertService);
  if (data) {
    yield put(setAlert({ alerts: data }));
  }
}
