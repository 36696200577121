import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const markLessonAsCompletedService = async (id: string) =>
  handleApiCall(() =>
    ApiService.post(apiRoutes.markLessonAsCompleted.replace(":id", id))
  );

export const getPresignedUrlService = async (fileKey: string) =>
  handleApiCall(() =>
    ApiService.get(`${apiRoutes.getPresignedUrl}?fileKey=${fileKey}`)
  );
