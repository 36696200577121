import { call, all, put, takeLatest } from "redux-saga/effects";

import { AxiosResponse } from "axios";
import {
  courseStatisticsTypes as types,
  setCourseStatistics,
} from "../actions";
import { courseStatisticsService } from "../../../services/learner/courseStatistics";

export default function* learnerCourseStatisticsSaga() {
  yield all([
    takeLatest(types.GET_COURSE_STATISTICS_ACTION, handleGetCourseStatistics),
  ]);
}

function* handleGetCourseStatistics() {
  const { data }: AxiosResponse = yield call(courseStatisticsService);
  yield put(setCourseStatistics(data));
}
