import axios from "axios";
import * as tus from "tus-js-client";

const apiKey = process.env.REACT_APP_VIMEO_ACCESS_TOKEN;
const apiBaseUrl = process.env.REACT_APP_VIMEO_API_BASE_URL;

export const createVimeoUploadLink = async (videoUrl: string) => {
  try {
    const response = await axios.post(
      `${apiBaseUrl}`,
      {
        upload: {
          approach: "pull",
          link: videoUrl,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
          Accept: "application/vnd.vimeo.*+json;version=3.4",
        },
      }
    );

    return response.data.uri;
  } catch (error) {
    throw error;
  }
};

export const createVimeoUploadLinkTus = async (fileSize: number) => {
  try {
    const response = await axios.post(
      `${apiBaseUrl}`,
      {
        upload: {
          approach: "tus",
          size: fileSize.toString(),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
          Accept: "application/vnd.vimeo.*+json;version=3.4",
        },
      }
    );

    return {
      uploadLink: response.data.upload.upload_link,
      videoUri: response.data.uri,
    };
  } catch (error) {
    throw error;
  }
};

export const uploadVideoToVimeo = async (
  file: File,
  setProgress: (progress: number) => void
) => {
  try {
    const { uploadLink, videoUri } = await createVimeoUploadLinkTus(file.size);
    const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB
    return new Promise((resolve, reject) => {
      const upload = new tus.Upload(file, {
        uploadUrl: uploadLink,
        chunkSize: CHUNK_SIZE,
        onError: (error: any) => {
          reject(error);
        },
        onProgress: (bytesUploaded: any, bytesTotal: any) => {
          const percentage = bytesUploaded / bytesTotal;
          setProgress(Math.round(percentage * 100));
        },
        onSuccess: async () => {
          try {
            const isUploadComplete = await verifyUpload(uploadLink);

            if (isUploadComplete) {
              resolve(videoUri);
            } else {
              reject("Upload failed");
            }
          } catch (error) {
            reject(error);
          }
        },
      });
      upload.start();
    });
  } catch (error) {
    throw error;
  }
};

export const verifyUpload = async (uploadLink: string) => {
  try {
    const response = await axios.head(uploadLink, {
      headers: {
        "Tus-Resumable": "1.0.0",
        Accept: "application/vnd.vimeo.*+json;version=3.4",
      },
    });

    const uploadLength = response.headers["upload-length"];
    const uploadOffset = response.headers["upload-offset"];

    return uploadLength === uploadOffset;
  } catch (error) {
    throw error;
  }
};
