import { produce } from "immer";

import { analyticsTypes as types } from "../actions";
import { LearnerAnalyticsState } from "../../../utils/types/Learner";

const defaultState: LearnerAnalyticsState = {
  learnerAnalytics: {
    completedCourses: "",
    points: "",
    progress: "",
    totalCourses: "",
  },
  loading: false,
};

const handleSetLearnerAnalytics = (
  state: { learnerAnalytics: LearnerAnalyticsState; loading: boolean },
  payload: LearnerAnalyticsState
) => {
  state.learnerAnalytics = payload;
  state.loading = false;
  return state;
};

export default produce((state = defaultState, action) => {
  switch (action.type) {
    case types.SET_LEARNER_ANALYTICS_ACTION:
      return handleSetLearnerAnalytics(state, action.payload);
    case types.GET_LEARNER_ANALYTICS_ACTION:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
});
