import { Typography } from "antd";

const { Text } = Typography;

const Question = (props: any) => {
  const { question, currentQuestionIndex, numberOfQuestions } = props;

  return (
    <div className="bg-white rounded-lg w-full p-10 flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div
          className="h-10 w-10 bg-primary-600 text-white font-semibold text-base rounded-3xl text-center leading-10"
          style={{ boxShadow: "inset 0 2px 3px 0 rgba(0, 0, 0, 0.25)" }}
        >
          {currentQuestionIndex}
        </div>
        <Text className="text-neutral-600 font-normal text-sm">
          Quiz Question {String(currentQuestionIndex).padStart(2, "0")}/
          {String(numberOfQuestions).padStart(2, "0")}
        </Text>
      </div>
      <Text className="text-neutral-600 text-lg font-semibold">
        {question.question}
      </Text>
      {question.imageKey && (
        <img
          src={`${process.env.REACT_APP_CLOUDFRONT_KEY}/${question.imageKey}`}
          alt="test"
        />
      )}
    </div>
  );
};

export default Question;
