import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { getModuleById } from "../../../../redux/learner/actions";
import { StateType } from "../../../../utils/types";
import {
  selectModule,
  selectModulesLoading,
} from "../../../../redux/learner/selector";

const mapStateToProps = (state: StateType) => ({
  module: selectModule(state),
  loading: selectModulesLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getModuleById,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
