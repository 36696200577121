export const labelWithNewsFeed = (label: string, newsNumber: any) => {
  return (
    <div className="flex justify-between items-center  h-5">
      <div>{label}</div>
      {newsNumber > 0 && (
        <div className="bg-rose-400 text-white text-[10px] font-semibold size-5 flex justify-center items-center rounded-full">
          {newsNumber}
        </div>
      )}
    </div>
  );
};
