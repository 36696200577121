import { AxiosError, AxiosPromise } from "axios";
import showNotification from "./notificationService";

const handleApiCall = async (invocation: () => AxiosPromise) => {
  try {
    return await invocation();
  } catch (error) {
    if (error instanceof AxiosError) {
      showNotification("error", error.response?.data.message);
      return error;
    }
  }
};

export default handleApiCall;
