import { produce } from "immer";

import {
  SetQuizByIdPayload,
  SetQuizzesPayload,
  SetQuizzieByIdPayload,
} from "../../../utils";
import { quizzieTypes as types } from "../actions";
import { EducatorQuizzieStateType } from "../../../utils/types";

const defaultState: EducatorQuizzieStateType = {
  quizzies: [],
  quizzie: null,
  loading: false,
  loaded: false,
  loadingSubmission: false,
};

const handleSetQuizzies = (state: EducatorQuizzieStateType, payload: any) => {
  state.quizzies = payload.quizzies;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetQuizzie = (
  state: EducatorQuizzieStateType,
  payload: SetQuizzieByIdPayload
) => {
  state.quizzie = payload.quizzie;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSubmissionComplete = (state: EducatorQuizzieStateType) => {
  state.loadingSubmission = false;
  return state;
};

export default produce(
  (state: EducatorQuizzieStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_QUIZZIES:
        return handleSetQuizzies(state, action.payload);
      case types.SET_QUIZZIE_BY_ID:
        return handleSetQuizzie(state, action.payload);
      case types.SUBMISSION_COMPLETE:
        return handleSubmissionComplete(state);
      case types.ADD_QUIZZIE:
        return {
          ...state,
          loadingSubmission: true,
        };
      case types.GET_QUIZZIE:
        return {
          ...state,
          loading: true,
        };
      case types.GET_QUIZZIE_BY_ID:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
