import { call, all, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import {
  setAnalyticsEntityById,
  setAnalytics,
  setBenchmarkAnalytics,
  analyticsTypes as types,
} from "../actions";
import {
  exportAnalytics,
  getBenchmarkAnalytics,
  getCourseAnalyticsById,
  getCoursesAnalytics,
  getLessonAnalyticsById,
  getModuleAnalyticsById,
} from "../../../services/educator/analytics";
import { EntityIdPayload } from "../../../utils";

export default function* educatorAnalyticsSagas() {
  yield all([
    takeLatest(types.GET_ANALYTICS, handleGetAnalytics),
    takeLatest(
      types.GET_ANALYTICS_ENTITY_BY_ID,
      handleGetAnalyticsForSingleCourse
    ),
    takeLatest(
      types.GET_ANALYTICS_MODULE_BY_ID,
      handleGetAnalyticsForSingleModule
    ),
    takeLatest(types.GET_BENCHMARK_ANAYLTICS, handleGetBenchmarkAnalytics),
    takeLatest(
      types.GET_LESSON_ANALYTICS_BY_ID,
      handleGetAnalyticsForSingleLesson
    ),
    takeLatest(
      types.GET_COURSE_ANALYTICS_BY_ID,
      handleGetAnalyticsForSingleCourse
    ),
    takeLatest(types.EXPORT_EXCEL, handleExportAnalytics),
  ]);
}

function* handleGetAnalytics(action: any) {
  const { data }: AxiosResponse = yield call(
    getCoursesAnalytics,
    action?.payload?.type
  );

  if (data) {
    yield put(setAnalytics({ entity: data }));
  }
}

function* handleGetAnalyticsForSingleCourse(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getCourseAnalyticsById,
    action.payload.id
  );

  if (data) {
    yield put(setAnalyticsEntityById({ singleEntity: data }));
  }
}

function* handleGetAnalyticsForSingleLesson(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getLessonAnalyticsById,
    action.payload.id
  );

  if (data) {
    yield put(setAnalyticsEntityById({ singleEntity: data }));
  }
}

function* handleGetBenchmarkAnalytics() {
  const { data }: AxiosResponse = yield call(getBenchmarkAnalytics);

  const { courses, modules, lessons, users } = data;
  if (data) {
    yield put(setBenchmarkAnalytics({ courses, modules, lessons, users }));
  }
}

function* handleGetAnalyticsForSingleModule(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getModuleAnalyticsById,
    action.payload.id
  );

  if (data) {
    yield put(setAnalyticsEntityById({ singleEntity: data }));
  }
}

function* handleExportAnalytics(action: any) {
  const { data }: AxiosResponse = yield call(
    exportAnalytics,
    action.payload.query
  );
  if (data) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `analytics.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
}
