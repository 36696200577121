import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const addModuleService = async (moduleData: FormData) =>
  handleApiCall(() => ApiService.post(apiRoutes.module, moduleData));

export const getModuleService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.module));

export const getModuleByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.moduleByIdIncludeStructure.replace(":id", id))
  );

export const deleteModuleByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.moduleById.replace(":id", id))
  );

export const editModuleService = async (id: string, moduleData: FormData) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.moduleById.replace(":id", id), moduleData)
  );

export const assignCoursesService = async (
  moduleId: string,
  courseIds: string[]
) =>
  handleApiCall(() =>
    ApiService.post(apiRoutes.moduleAssignCourses.replace(":id", moduleId), {
      courseIds,
    })
  );
