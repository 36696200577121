import {
  Form,
  Input,
  Upload,
  Button,
  Typography,
  Progress,
  Modal,
  UploadFile,
  Radio,
} from "antd";
import { ReactComponent as UploadIcon } from "../../../../assets/images/upload.svg";
import { ReactComponent as UploadVideoIcon } from "../../../../assets/images/upload-video.svg";
import { ReactComponent as UploadPDFIcon } from "../../../../assets/images/upload-pdf.svg";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import showNotification from "../../../../services/notificationService";
import ExistingVideoSelect from "./ExistingVideoSelect";
import {
  createVimeoUploadLink,
  uploadVideoToVimeo,
} from "../../../../services/vimeoService";

const { Text } = Typography;
const { TextArea } = Input;

const CreateLessonScreen = (props: any) => {
  const {
    videos,
    videosLoading,
    actions: { getVideo, addLesson, setInitialSteps },
  } = props;

  const [fileList, setFileList] = useState<any>([]);
  const [videoFileList, setVideoFileList] = useState<any>([]);
  const [pdfFileList, setPdfFileList] = useState<any>([]);
  const [progress, setProgress] = useState<number>(0);
  const [isVideoModalVisible, setIsVideoModalVisible] =
    useState<boolean>(false);
  const [isProgressModalVisible, setIsProgressModalVisible] =
    useState<boolean>(false);
  const [videoOption, setVideoOption] = useState<string>("upload");
  const [selectedVideo, setSelectedVideo] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getVideo();
  }, []);

  useEffect(() => {
    setInitialSteps(2);

    if (location.state && location.state.video) {
      setSelectedVideo(location.state.video);
      setVideoOption("existing");
    }
  }, [location.state, setInitialSteps]);

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    const { title, description, points } = values;

    const isUploadOptionInvalid =
      videoOption === "upload" &&
      videoFileList.length === 0 &&
      pdfFileList.length === 0;
    const isExistingOptionInvalid =
      videoOption === "existing" && !selectedVideo && pdfFileList.length === 0;

    if (fileList.length === 0) {
      showNotification("error", "Please upload a cover image");
      setIsSubmitting(false);
      return;
    }
    if (isUploadOptionInvalid) {
      showNotification("error", "Please upload a video or a PDF");
      setIsSubmitting(false);
      return;
    }
    if (isExistingOptionInvalid) {
      showNotification(
        "error",
        "Please select an existing video or upload a PDF"
      );
      setIsSubmitting(false);
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("points", points.toString());
    if (fileList.length > 0) {
      formData.append("coverImage", fileList[0].originFileObj as File);
    }
    if (pdfFileList.length > 0) {
      formData.append("file", pdfFileList[0].originFileObj as File);
    }
    try {
      let videoUri = null as string | null;
      if (videoOption === "upload" && videoFileList.length > 0) {
        setIsProgressModalVisible(true);
        const videoFile = videoFileList[0].originFileObj as File;
        videoUri = (await uploadVideoToVimeo(videoFile, setProgress)) as string;
        setIsProgressModalVisible(false);
      } else if (videoOption === "existing" && selectedVideo) {
        videoUri = await createVimeoUploadLink(selectedVideo.url);
      }

      if (videoUri) {
        formData.append("videoUri", videoUri);
      }

      addLesson({ lesson: formData, navigate });
    } catch (error) {
      setIsProgressModalVisible(false);
      showNotification("error", "Error uploading video to Vimeo");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };

  const handleVideoChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setVideoFileList(fileList);
  };

  const handlePdfChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setPdfFileList(fileList);
  };

  const handleRemoveVideo = () => {
    setVideoFileList([]);
  };

  const handleCancelModal = () => {
    setIsProgressModalVisible(false);
    setProgress(0);
  };

  return (
    <div>
      <Form onFinish={handleSubmit} className="flex flex-row pb-10 gap-10">
        <div className="flex-1">
          <Text className="text-neutral-600 text-sm font-semibold">Title</Text>
          <Form.Item
            name="title"
            className="mt-2"
            rules={[{ required: true, message: "Please input the title!" }]}
          >
            <Input placeholder="Type the title here" />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Description
          </Text>
          <Form.Item
            name="description"
            className="mt-2"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
          >
            <TextArea placeholder="Add description here" rows={10} />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">Points</Text>
          <Form.Item
            name="points"
            className="mt-2"
            rules={[{ required: true, message: "Please input the points!" }]}
          >
            <Input type="number" placeholder="Points" />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Cover Image
          </Text>

          <Upload
            name="coverImage"
            listType="picture-card"
            maxCount={1}
            beforeUpload={() => false}
            accept="image/png, image/jpeg, image/gif"
            className="bg-white rounded-md h-[310px] mt-2"
            fileList={fileList}
            onChange={handleChange}
          >
            {fileList.length === 0 && (
              <div className="flex flex-col items-center justify-center">
                <UploadIcon />
                <p className="text-neutral-600 text-base font-medium">
                  Upload a cover image
                </p>
                <p className="text-xs text-neutral-500 font-normal">
                  PNG, JPG, GIF up to 5MB
                </p>
              </div>
            )}
          </Upload>
        </div>
        <div className="flex-1 flex flex-col justify-between mt-[100px]">
          <div>
            <Radio.Group
              onChange={(e) => setVideoOption(e.target.value)}
              value={videoOption}
              style={{ marginBottom: "10px" }}
            >
              <Radio value="upload">Upload Video</Radio>
              <Radio value="existing">Select Existing Video</Radio>
            </Radio.Group>
            {videoOption === "upload" ? (
              <Upload
                name="video"
                listType="picture-card"
                maxCount={1}
                beforeUpload={() => false}
                accept="video/mp4"
                className="bg-white rounded-md h-[310px] mt-2"
                fileList={videoFileList}
                onChange={handleVideoChange}
                showUploadList={false}
              >
                {videoFileList.length === 0 ? (
                  <div className="flex flex-col items-center justify-center">
                    <UploadVideoIcon />
                    <p className="text-neutral-600 text-base font-medium">
                      Upload a video
                    </p>
                    <p className="text-xs text-neutral-500 font-normal">
                      MP4 up to 50MB
                    </p>
                  </div>
                ) : (
                  <div className="relative flex justify-center items-center w-full h-full">
                    <video
                      src={URL.createObjectURL(
                        videoFileList[0].originFileObj as Blob
                      )}
                      controls
                      className="w-full h-full object-cover"
                    />
                    <Button
                      onClick={handleRemoveVideo}
                      className="absolute top-2 right-2 bg-red-500 text-white"
                    >
                      Remove
                    </Button>
                  </div>
                )}
              </Upload>
            ) : (
              <div className="h-[310px] mt-2 bg-white rounded-lg">
                {selectedVideo ? (
                  <div className="relative flex justify-center items-center w-full h-full mt-2">
                    <Button
                      className="absolute top-2 right-2 bg-red-500 text-white z-10"
                      onClick={() => setSelectedVideo(null)}
                    >
                      Remove
                    </Button>
                    <video
                      src={selectedVideo.url}
                      controls
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                ) : (
                  <Button
                    onClick={() => setIsVideoModalVisible(true)}
                    className="w-full h-full"
                  >
                    Select Existing AI Content
                  </Button>
                )}
              </div>
            )}
          </div>
          <div>
            <Text className="text-neutral-600 text-sm font-semibold">
              Upload PDF
            </Text>
            <Upload
              name="pdf"
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
              accept="application/pdf"
              className="bg-white rounded-md h-[310px] mt-2"
              fileList={pdfFileList}
              onChange={handlePdfChange}
              iconRender={() => (
                <div className="w-full h-full flex items-center justify-center ">
                  <UploadPDFIcon />
                </div>
              )}
            >
              {pdfFileList.length === 0 && (
                <div className="flex flex-col items-center justify-center">
                  <UploadPDFIcon />
                  <p className="text-neutral-600 text-base font-medium">
                    Upload a PDF
                  </p>
                  <p className="text-xs text-neutral-500 font-normal">
                    PDF up to 10MB
                  </p>
                </div>
              )}
            </Upload>
          </div>
          <div className="w-100 flex flex-row justify-end">
            <Form.Item className="w-2/4 mb-0">
              <Button
                type="primary"
                htmlType="submit"
                className="w-full bg-primary-600 h-[50px] font-semibold text-base mb-0"
                loading={isSubmitting}
              >
                Continue
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>

      <Modal
        title="Select Existing Video"
        open={isVideoModalVisible}
        footer={null}
        onCancel={() => setIsVideoModalVisible(false)}
      >
        <ExistingVideoSelect
          onVideoSelect={setSelectedVideo}
          selectedVideoId={selectedVideo ? selectedVideo.id : null}
          videos={videos}
          loading={videosLoading}
        />
      </Modal>

      {videoOption === "upload" && (
        <Modal
          title="Upload Progress"
          open={isProgressModalVisible}
          footer={null}
          closable={true}
          onCancel={handleCancelModal}
        >
          <Progress percent={progress} />
        </Modal>
      )}
    </div>
  );
};

export default CreateLessonScreen;
