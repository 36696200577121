import React from "react";
import CircleProgress from "../../../shared/CircleProgress";

interface ProgressInfoBoxProps {
  percentage: number;
  heading: string;
  totalNumber: string | number;
  type: "activity" | "performance";
}

const ProgressInfoBox: React.FC<ProgressInfoBoxProps> = ({
  percentage,
  heading,
  totalNumber,
  type,
}) => {
  const strokeColor = type === "activity" ? "#FDA4AF" : "#7AC5CC";

  return (
    <div className="flex flex-col gap-3 p-6 bg-white rounded-lg educator-dashboard-box-shadow-cards justify-center items-center">
      <CircleProgress
        size={100}
        percentage={percentage || 0}
        firstStopColor={strokeColor}
      />
      <div className="text-neutral-600 text-center">{heading}</div>
      <div className="text-neutral-600 font-extrabold text-xl text-center">
        {totalNumber}
      </div>
    </div>
  );
};

export default ProgressInfoBox;
