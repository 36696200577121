import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import { getLearnerAnalytics } from "../../../../redux/learner/actions";
import {
  selectLearnerAnalytics,
  selectLearnerAnalyticsLoading,
} from "../../../../redux/learner/selector";

const mapStateToProps = (state: StateType) => ({
  analytics: selectLearnerAnalytics(state),
  loading: selectLearnerAnalyticsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getLearnerAnalytics,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
