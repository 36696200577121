import TimelineSelection from "../../Dashboard/components/TimelineSelection";

const EntityHeader = ({
  title,
  filterOptions,
  selectedOption,
  onSelectionChange,
}: any) => (
  <div className="flex gap-3 items-center">
    <div className="text-white text-sm bg-primary-600 rounded-lg text-center px-4 py-1 font-medum">
      {title}
    </div>
    <div>
      <TimelineSelection
        onSelectionChange={onSelectionChange}
        selectedOption={selectedOption}
        options={filterOptions}
      ></TimelineSelection>
    </div>
  </div>
);

export default EntityHeader;
