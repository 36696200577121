import { Typography, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import resultsOptions from "../../utils/constants/results";
import { useEffect, useState } from "react";
const { Text } = Typography;

const Results = ({ path }: { path: string }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { passed, id, points, minimumCorrectAnswers } = location.state || {};
  const [results, setResults] = useState<any>({});

  if (!location.state) {
    navigate(path);
  }

  useEffect(() => {
    const route = resultsOptions.find((r) =>
      location.pathname.endsWith(r.path)
    );

    if (!route) {
      navigate(path);
      return;
    }
    let res = passed ? route.success : route.fail;
    const updatedRes = {
      ...res,
      firstButtonOnClickNavigate: res.firstButtonOnClickNavigate.replace(
        ":id",
        id
      ),
      secondButtonOnClickNavigate: res.secondButtonOnClickNavigate.replace(
        ":id",
        id
      ),
    };

    setResults(updatedRes);
  }, []);

  return (
    <div className="bg-white w-[584px] h-[666px] flex flex-col gap-10 p-20 rounded-lg m-auto mb-[60px]">
      <img className="h-[250px]" src={results.icon} alt="icon" />
      <Text className="text-center text-2xl font-extrabold text-neutral-600">
        {results.title}
      </Text>
      <div className=" flex flex-col gap-4">
        <Text className="text-center text-lg text-neutral-600 font-normal">
          {results.text}
          {points && passed && (
            <span className=" font-semibold">{points} Points.</span>
          )}
        </Text>
        {results.smallText && (
          <Text className="text-center text-sm text-neutral-500 font-normal">
            {results.smallText.replace("%NUMBER%", minimumCorrectAnswers)}
          </Text>
        )}
      </div>
      <div className="flex gap-10">
        {results.firstButtonText && (
          <Button
            className="flex-1 h-[50px] font-semibold text-base border-primary-200 text-primary-600"
            onClick={() => navigate(results.firstButtonOnClickNavigate)}
          >
            {results.firstButtonText}
          </Button>
        )}
        {results.secondButtonText && (
          <Button
            type="primary"
            className="flex-1 bg-primary-600 h-[50px] font-semibold text-base"
            onClick={() => navigate(results.secondButtonOnClickNavigate)}
          >
            {results.secondButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Results;
