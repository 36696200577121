import { Button } from "antd";

const ActionButtons = ({
  onQuizClick,
  onNextLessonClick,
  showQuizButton,
  isQuizDisabled,
  isNextLessonDisabled,
  isLastLesson,
}: {
  onQuizClick: () => void;
  onNextLessonClick: () => void;
  showQuizButton: boolean;
  isQuizDisabled: boolean;
  isNextLessonDisabled: boolean;
  isLastLesson: boolean;
}) => (
  <div className="flex flex-col w-full">
    {showQuizButton && (
      <Button
        onClick={onQuizClick}
        className="h-[50px] mb-4  text-base font-semibold text-primary-600 border-primary-200"
        disabled={isQuizDisabled}
      >
        Take Quiz
      </Button>
    )}
    <Button
      onClick={onNextLessonClick}
      type="primary"
      className="bg-primary-600 h-[50px] text-base font-semibold"
      disabled={isNextLessonDisabled}
    >
      {isLastLesson ? "Finish Course" : "Next Lesson"}
    </Button>
  </div>
);

export default ActionButtons;
