import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { deleteQuizzie, getQuizzie } from "../../../../redux/educator/actions";
import {
  selectQuizzies,
  selectQuizziesLoading,
} from "../../../../redux/educator/selector";
import { StateType } from "../../../../utils";

const mapStateToProps = (state: StateType) => ({
  loading: selectQuizziesLoading(state),
  quizzies: selectQuizzies(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getQuizzie,
      deleteQuizzie,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
