import { produce } from "immer";

import { aiContentTypes as types } from "../actions";
import { EducatorAIContentStateType } from "../../../utils";

const defaultState: EducatorAIContentStateType = {
  videos: [],
  extractText: "",
  loading: false,
  loaded: false,
};

const handleSetVideos = (state: any, payload: any) => {
  state.videos = payload.videos;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetExtractText = (state: any, payload: any) => {
  state.extractText = payload.extractText;
  state.loading = false;
  state.loaded = true;
  return state;
};

export default produce(
  (state: EducatorAIContentStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_VIDEO:
        return handleSetVideos(state, action.payload);
      case types.SET_EXTRACT_TEXT:
        return handleSetExtractText(state, action.payload);
      case types.ADD_VIDEO:
        return {
          ...state,
          extractText: "",
        };
      case types.GET_VIDEO:
        return {
          ...state,
          extractText: "",
          loading: true,
        };
      case types.EXTRACT_TEXT:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
