import AvatarItems from "../../assets/images/avatar-items";

export default [
  {
    avatar: AvatarItems.harryBusiness,
    character: "harry",
    style: "business",
  },
  {
    avatar: AvatarItems.harryCasual,
    character: "harry",
    style: "casual",
  },
  {
    avatar: AvatarItems.harryYouthful,
    character: "harry",
    style: "youthful",
  },
  {
    avatar: AvatarItems.jeffBusiness,
    character: "jeff",
    style: "business",
  },
  {
    avatar: AvatarItems.jeffFormal,
    character: "jeff",
    style: "formal",
  },
  {
    avatar: AvatarItems.lisaCasualSitting,
    character: "lisa",
    style: "casual-sitting",
  },
  {
    avatar: AvatarItems.lisaGracefulSitting,
    character: "lisa",
    style: "graceful-sitting",
  },
  {
    avatar: AvatarItems.lisaGracefulStanding,
    character: "lisa",
    style: "graceful-standing",
  },
  {
    avatar: AvatarItems.lisaTechnicalSitting,
    character: "lisa",
    style: "technical-sitting",
  },
  {
    avatar: AvatarItems.lisaTechnicalStanding,
    character: "lisa",
    style: "technical-standing",
  },
  {
    avatar: AvatarItems.loriCasual,
    character: "lori",
    style: "casual",
  },
  {
    avatar: AvatarItems.loriFormal,
    character: "lori",
    style: "formal",
  },
  {
    avatar: AvatarItems.loriGraceful,
    character: "lori",
    style: "graceful",
  },
  {
    avatar: AvatarItems.maxBusiness,
    character: "max",
    style: "business",
  },
  {
    avatar: AvatarItems.maxCasual,
    character: "max",
    style: "casual",
  },
  {
    avatar: AvatarItems.maxFormal,
    character: "max",
    style: "formal",
  },
  {
    avatar: AvatarItems.megBusiness,
    character: "meg",
    style: "business",
  },
  {
    avatar: AvatarItems.megCasual,
    character: "meg",
    style: "casual",
  },
  {
    avatar: AvatarItems.megFormal,
    character: "meg",
    style: "formal",
  },
];
