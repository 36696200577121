import { call, all, put, takeLatest } from "redux-saga/effects";

import { AxiosResponse } from "axios";
import { statisticsTypes as types, setLearnerStatistics } from "../actions";
import { learnerStatisticsService } from "../../../services/learner/statistics";

export default function* learnerStatisticsSaga() {
  yield all([
    takeLatest(types.GET_LEARNER_STATISTICS_ACTION, handleGetLearnerStatistics),
  ]);
}

function* handleGetLearnerStatistics() {
  const { data }: AxiosResponse = yield call(learnerStatisticsService);

  yield put(setLearnerStatistics(data));
}
