import { createSelector } from "@reduxjs/toolkit";

import { EducatorAIContentStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectAIContentState = (state: StateType): EducatorAIContentStateType =>
  state.educatorAIContentReducer;

export const selectVideos = createSelector(
  selectAIContentState,
  (state: EducatorAIContentStateType) => state.videos
);

export const selectVideosLoading = createSelector(
  selectAIContentState,
  (state: EducatorAIContentStateType) => state.loading
);

export const selectVideosLoaded = createSelector(
  selectAIContentState,
  (state: EducatorAIContentStateType) => state.loaded
);

export const selectExtractText = createSelector(
  selectAIContentState,
  (state: EducatorAIContentStateType) => state.extractText
);
