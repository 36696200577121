import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";
import StorageService from "../storageService";

export const getUserService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.user));

export const getUserByIdService = async (id: string) =>
  handleApiCall(() => ApiService.get(apiRoutes.userById.replace(":id", id)));

export const deleteUserByIdService = async (id: string) =>
  handleApiCall(() => ApiService.delete(apiRoutes.userById.replace(":id", id)));

export const editUserService = async (id: string, userData: any) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.userById.replace(":id", id), userData)
  );

export const signUpService = async (userData: any) =>
  handleApiCall(() => ApiService.post(apiRoutes.singUp, userData));

export const getUserByRoleService = async (role: string) =>
  handleApiCall(() =>
    ApiService.get(`${apiRoutes.user}?role=${role.toUpperCase()}`)
  );

export const logoutUserService = async () => {
  const refreshToken = StorageService.getStringItem("refreshToken");

  return handleApiCall(() =>
    ApiService.post(apiRoutes.logout, { refreshToken })
  );
};
