import { produce } from "immer";
import { quizzieTypes as types } from "../actions";
import { LearnerQuizzieStateType } from "../../../utils/types/Learner";

const defaultState: LearnerQuizzieStateType = {
  quizzies: [],
  quizzie: null,
  loading: false,
  loaded: false,
};

const handleSetQuizzie = (state: LearnerQuizzieStateType, payload: any) => {
  state.quizzie = payload.quizzie;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetQuizzies = (state: LearnerQuizzieStateType, payload: any) => {
  state.quizzies = payload.quizzies;
  state.loading = false;
  state.loaded = true;
  return state;
};

export default produce(
  (state: LearnerQuizzieStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_QUIZZIE_BY_ID:
        return handleSetQuizzie(state, action.payload);
      case types.SET_QUIZZIES:
        return handleSetQuizzies(state, action.payload);
      case types.GET_QUIZZIE:
        return {
          ...state,
          loading: true,
        };
      case types.GET_QUIZZIE_BY_ID:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
