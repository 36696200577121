import { Button, Typography, Divider, Modal, Skeleton, Spin } from "antd";
import { useEffect } from "react";
import testImage from "../../../../assets/images/default-cover-image.jpg";
import { ReactComponent as UploadPDFIcon } from "../../../../assets/images/upload-pdf.svg";
import { ReactComponent as DurationIcon } from "../../../../assets/images/reusable-items/duration.svg";
import { useParams, useNavigate } from "react-router-dom";
import { DownloadOutlined, EyeFilled } from "@ant-design/icons";

const { Text } = Typography;

const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const LessonDetailsScreen = (props: any) => {
  const {
    lesson,
    loading,
    actions: { getLessonById, deleteLesson },
  } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getLessonById({ id });
  }, []);

  const onDeleteLesson = async () => {
    Modal.confirm({
      title: "Are you sure you want to delete this lesson?",
      onOk: async () => {
        await deleteLesson({ id, navigate });
      },
    });
  };

  const getBestQualityVideo = (files: any[]) => {
    const sortedFiles = [...files].sort((a, b) => {
      const qualityOrder = ["sd", "hd"];
      const renditionOrder = [
        "240p",
        "360p",
        "540p",
        "720p",
        "1080p",
        "adaptive",
      ];

      const qualityComparison =
        qualityOrder.indexOf(a.quality) - qualityOrder.indexOf(b.quality);
      if (qualityComparison !== 0) return qualityComparison;

      return (
        renditionOrder.indexOf(a.rendition) -
        renditionOrder.indexOf(b.rendition)
      );
    });

    return sortedFiles[sortedFiles.length - 1].link;
  };

  if (loading) return <Skeleton active />;

  return (
    <>
      {lesson && (
        <div className="pb-6">
          <Text className="text-neutral-600 font-semibold text-lg">
            Lesson Overview
          </Text>
          <div className="mt-4 bg-white rounded-lg p-6 flex flex-row gap-8">
            <div className="flex-1">
              <Text className="text-neutral-600 font-semibold text-sm">
                Title
              </Text>
              <div className="w-full mb-6 h-[50px] mt-2 bg-neutral-100 p-3 text-neutral-600 text-base font-semibold rounded-lg">
                <Text>{lesson.title}</Text>
              </div>

              <Text className="text-neutral-600 font-semibold text-sm">
                Description
              </Text>
              <div className="w-full mb-6 h-[156px] mt-2 bg-neutral-100 p-3 text-neutral-600 text-base font-normal rounded-lg overflow-auto">
                <Text>{lesson.description}</Text>
              </div>

              <div>
                <Text className="text-neutral-600 font-semibold text-sm">
                  Points
                </Text>
                <div className="w-full mt-2 mb-6 h-[50px] bg-neutral-100 p-3 text-neutral-600 text-base font-semibold rounded-lg">
                  <Text>{lesson.points}</Text>
                </div>
              </div>
              <div className="flex gap-4 w-full">
                <div className="w-full">
                  <Text className="text-neutral-600 font-semibold text-sm">
                    Lesson duration
                  </Text>
                  <div className="flex w-full gap-3 mb-6 mt-1 p-3 items-center rounded-lg">
                    <div className="w-4 h-4">
                      <DurationIcon className="fill-primary-300" />
                    </div>
                    <div
                      className="rounded-md flex-1 p-1 text-center"
                      style={{
                        backgroundColor: "#EEF1FF",
                      }}
                    >
                      <Text className="text-neutral-600">
                        {lesson?.duration
                          ? lesson?.duration?.split("hr")[0] + " hr"
                          : "0 hr"}
                      </Text>
                    </div>
                    <div
                      className="rounded-md flex-1 p-1 text-center"
                      style={{
                        backgroundColor: "#EEF1FF",
                      }}
                    >
                      <Text className="text-neutral-600">
                        {lesson?.duration
                          ? lesson?.duration?.split("hr")[1]
                          : "0 min"}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>

              <Text className="text-neutral-600 font-semibold text-sm">
                Cover Image
              </Text>

              <div>
                <img
                  src={
                    lesson.coverImageKey
                      ? `${cloudfrontKey}/${lesson.coverImageKey}`
                      : testImage
                  }
                  alt="cover"
                  className="rounded-lg w-full  mt-2 h-[280px] object-cover"
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col justify-between">
              <div>
                {lesson.files && (
                  <div>
                    <Text className="text-neutral-600 font-semibold text-sm">
                      Video
                    </Text>
                    <div className="w-full mt-2 mb-6 bg-neutral-100 p-4 text-neutral-600 text-base font-semibold rounded-lg flex items-center justify-center">
                      {lesson.videoStatus === "AVAILABLE" ||
                      lesson.videoStatus ===
                        "AVAILABLE_WITHOUT_TRANSCRIPTION" ? (
                        <video
                          width="100%"
                          height="200px"
                          controls
                          src={getBestQualityVideo(lesson.files)}
                          className="rounded-lg"
                        ></video>
                      ) : (
                        <div className="h-[200px] flex items-center">
                          <div className="flex flex-col gap-6 mt-4">
                            <Spin size="large" />
                            <Text className="text-neutral-600 font-semibold text-sm mb-4 block">
                              Video is processing
                            </Text>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {lesson.fileKey && (
                  <div className="bg-neutral-100 p-4 rounded-lg">
                    <Text className="text-neutral-600 font-semibold text-sm">
                      PDF Documents
                    </Text>
                    <div className="w-full mt-2 educator-dashboard-box-shadow-cards p-4 rounded-lg bg-white text-neutral-600 text-base font-semibold  flex items-center justify-between">
                      <div className="flex gap-2 items-center">
                        <UploadPDFIcon
                          style={{
                            width: 30,
                            height: 30,
                          }}
                        />
                        <Text>
                          {
                            lesson.fileKey?.split("-")[
                              lesson.fileKey?.split("-").length - 1
                            ]
                          }
                        </Text>
                      </div>
                      <div className="flex gap-2 items-center">
                        <EyeFilled
                          className="text-base text-primary-450 cursor-pointer"
                          onClick={() =>
                            window.open(
                              `${cloudfrontKey + "/" + lesson.fileKey}`,
                              "_blank"
                            )
                          }
                        />
                        <Divider
                          type="vertical"
                          style={{ borderColor: "#BECBFE" }}
                        ></Divider>
                        <a
                          href={`${cloudfrontKey + "/" + lesson.fileKey}`}
                          download={lesson.title}
                        >
                          <DownloadOutlined className="text-base text-primary-450 cursor-pointer" />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {lesson.quiz && (
                  <div className="mt-3">
                    <Text className="text-neutral-600 font-semibold text-sm">
                      Quiz
                    </Text>
                    <div className="w-full mt-2 mb-6 bg-neutral-100 p-3 text-neutral-600 text-base font-semibold rounded-lg flex items-center justify-between">
                      <Text>{lesson.quiz.title}</Text>
                      <Button
                        type="primary"
                        className="bg-primary-600 text-white"
                        onClick={() =>
                          navigate(`/educator/quiz/${lesson.quiz.id}`)
                        }
                      >
                        View Quiz
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full flex flex-row justify-end  mt-4 gap-4">
                <Button
                  className="w-2/4 bg-primary-600 h-[50px] font-semibold text-base mb-0"
                  type="primary"
                  danger
                  onClick={onDeleteLesson}
                  disabled={
                    lesson.videoUri !== null &&
                    lesson.videoStatus !== "AVAILABLE" &&
                    lesson.videoStatus !== "AVAILABLE_WITHOUT_TRANSCRIPTION"
                  }
                >
                  Delete
                </Button>
                <Button
                  onClick={() => navigate(`/educator/lesson/${lesson.id}/edit`)}
                  type="primary"
                  className="bg-primary-600 w-2/4 h-[50px] text-base font-semibold"
                  disabled={
                    lesson.videoUri !== null &&
                    lesson.videoStatus !== "AVAILABLE" &&
                    lesson.videoStatus !== "AVAILABLE_WITHOUT_TRANSCRIPTION"
                  }
                >
                  Edit
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LessonDetailsScreen;
