import { produce } from "immer";

import { alertTypes as types } from "../actions";
import { LearnerAlertStateType } from "../../../utils";

const defaultState: LearnerAlertStateType = {
  alerts: [],
  loading: false,
  loaded: false,
};

const handleSetAlerts = (state: any, payload: any) => {
  state.alerts = payload.alerts;
  state.loading = false;
  state.loaded = true;
  return state;
};

export default produce(
  (state: LearnerAlertStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_ALERT:
        return handleSetAlerts(state, action.payload);
      case types.GET_ALERT:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
