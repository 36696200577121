export const quizTypes = {
  GET_QUIZ_BY_ID: "LEARNER_GET_QUIZ_BY_ID",
  SET_QUIZ_BY_ID: "LEARNER_SET_QUIZ_BY_ID",
  SUBMIT_QUIZ_ANSWERS: "LEARNER_SUBMIT_QUIZ_ANSWERS",
};

export const getQuizById = (payload: any) => ({
  type: quizTypes.GET_QUIZ_BY_ID,
  payload,
});

export const setQuizById = (payload: any) => ({
  type: quizTypes.SET_QUIZ_BY_ID,
  payload,
});

export const submitQuizAnswers = (payload: any) => ({
  type: quizTypes.SUBMIT_QUIZ_ANSWERS,
  payload,
});
