import { notification } from "antd";

const showNotification = (type: string, description: string) => {
  if (type === "success") {
    notification.success({
      message: "Success",
      description: description,
      placement: "topRight",
      duration: 5,
    });
  } else {
    notification.error({
      message: "Error",
      description: description,
      placement: "topRight",
      duration: 5,
    });
  }
};

export default showNotification;
