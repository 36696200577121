import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const getQuizByIdService = async (id: string) =>
  handleApiCall(() => ApiService.get(apiRoutes.quizById.replace(":id", id)));

export const submitQuizAnswersService = async (id: string, data: any) =>
  handleApiCall(() =>
    ApiService.post(apiRoutes.quizSubmitAnswers.replace(":id", id), data)
  );
