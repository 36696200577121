import { useEffect, useState } from "react";
import { ConnectedProps } from "react-redux";
import { Row, Skeleton } from "antd";

import { StatisticsCardProps } from "../../../../utils";
import educatorStatisticsCard from "../../../../utils/constants/statisticsCard";
import StatisticsSingleCard from "./StatisticsSingleCard";
import connector from "./Container";
import { Link } from "react-router-dom";

const HomeStatistics = (props: ConnectedProps<typeof connector>) => {
  const {
    statistics,
    loading,
    actions: { getLearnerStatistics },
  } = props;

  const [statisticsData, setStatisticsData] = useState<StatisticsCardProps[]>(
    []
  );

  useEffect(() => {
    getLearnerStatistics();
  }, []);

  useEffect(() => {
    if (statistics) {
      let data: StatisticsCardProps[] = [];
      educatorStatisticsCard.slice(0, 3).forEach((item) => {
        data.push({ ...item, progress: "" });
      });

      data[0].progress = `${statistics.completedCourses}/${statistics.totalCourses}`;
      data[1].progress = `${statistics.completedLessons}/${statistics.totalLessons}`;
      data[2].progress = `${statistics.completedQuizzes}/${statistics.totalQuizzes}`;

      setStatisticsData(data);
    }
  }, [statistics]);

  if (loading) {
    return (
      <div className="h-[190px]">
        <Skeleton active paragraph={{ rows: 4 }} />
      </div>
    );
  }

  return (
    <>
      <Row justify={"space-between"} align={"middle"}>
        <div className="text-neutral-600 font-semibold text-base">
          Statistics
        </div>
        <Link
          to="/user/dashboard"
          className="text-primary-600 font-semibold text-[13px]"
        >
          See All
        </Link>
      </Row>
      <div className="mt-4 gap-5 w-full flex justify-between">
        {statisticsData.map((statistics, index) => (
          <StatisticsSingleCard key={index} statistics={statistics} />
        ))}
      </div>
    </>
  );
};

export default HomeStatistics;
