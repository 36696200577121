import { Card, Typography, Spin } from "antd";
import type { StatisticsCardProps } from "../../utils/types";

const { Text } = Typography;

const StatisticsCard = (props: any) => {
  const { icon: Icon, title, progress } = props;
  return (
    <Card className="flex-1 rounded-2xl educator-dashboard-box-shadow-cards bg-white fill-primary-600 pb-2 pt-6">
      <div className="flex flex-col items-center h-full">
        <Icon height={30} />
        <Text className="text-neutral-400 text-xs mt-4">{title}</Text>
        <Text className="text-primary-500 text-base font-semibold">
          {progress === "" ? <Spin /> : progress}
        </Text>
      </div>
    </Card>
  );
};

export default StatisticsCard;
