import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import {
  signUp,
  getDepartment,
  getPosition,
} from "../../../../redux/educator/actions";
import {
  selectDepartments,
  selectPositions,
} from "../../../../redux/educator/selector";

const mapStateToProps = (state: StateType) => ({
  departments: selectDepartments(state),
  positions: selectPositions(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      signUp,
      getDepartment,
      getPosition,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
