import { createSelector } from "@reduxjs/toolkit";

import { EducatorAlertStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectAlertState = (state: StateType): EducatorAlertStateType =>
  state.educatorAlertReducer;

export const selectAlerts = createSelector(
  selectAlertState,
  (state: EducatorAlertStateType) => state.alerts
);

export const selectAlert = createSelector(
  selectAlertState,
  (state: EducatorAlertStateType) => state.alert
);

export const selectAlertsLoading = createSelector(
  selectAlertState,
  (state: EducatorAlertStateType) => state.loading
);

export const selectAlertsLoaded = createSelector(
  selectAlertState,
  (state: EducatorAlertStateType) => state.loaded
);
