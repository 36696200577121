interface EducatorHeaderProgressItemProps {
  isFilled: boolean;
}

const EducatorHeaderProgressItem = ({
  isFilled,
}: EducatorHeaderProgressItemProps) => {
  return (
    <div
      className={`${
        isFilled ? "bg-danger-400" : "bg-primary-450"
      } h-2 rounded w-full`}
    ></div>
  );
};

export default EducatorHeaderProgressItem;
