import { Form, Input, Button, Select, Row, Col, Typography } from "antd";
import { useEffect } from "react";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";

const { Text } = Typography;

const SignUp = (props: any) => {
  const {
    departments,
    positions,
    actions: { signUp, getDepartment, getPosition },
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    getDepartment();
    getPosition();
  }, []);

  const onFinishSignUp = ({
    email,
    departmentId,
    positionId,
    role,
  }: {
    email: string;
    departmentId: string | null;
    positionId: string | null;
    role: string;
  }) => {
    signUp({
      email,
      departmentId: departmentId || null,
      positionId: positionId || null,
      role,
    });
    form.resetFields();
  };

  const roles = ["USER", "EDUCATOR"];

  return (
    <Row className="h-full  pb-[60px]">
      <Col lg={8}>
        <CreateEntityInfo
          title="Invite a User"
          text="Enter the information of the user you want to invite to the application"
        />
      </Col>
      <Col
        lg={16}
        className="h-full flex flex-col justify-between max-w-[600px]"
      >
        <Form
          form={form}
          name="basic"
          onFinish={onFinishSignUp}
          autoComplete="off"
          size="large"
        >
          <div className="w-full mb-7">
            <Text className="text-neutral-700 text-lg font-semibold">
              User Information
            </Text>
          </div>

          <Text className="text-neutral-600 text-sm font-semibold">
            Email Adress
          </Text>

          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
            className="mt-2"
          >
            <Input
              type="email"
              autoComplete="off"
              name="email"
              placeholder="Input Email Adress"
            />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            User role
          </Text>

          <Form.Item
            name="role"
            rules={[{ required: true, message: "Please select role!" }]}
            className="mt-2"
          >
            <Select placeholder="Select Role" className="min-h-[50px]">
              {roles.map((role) => (
                <Select.Option key={role} value={role}>
                  {role}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Department (Optional)
          </Text>

          <Form.Item
            name="departmentId"
            rules={[{ required: false }]}
            className="mt-2"
          >
            <Select
              placeholder="Select Department"
              allowClear
              className="min-h-[50px]"
            >
              {departments.map((dept: any) => (
                <Select.Option key={dept.id} value={dept.id}>
                  {dept.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Position (Optional)
          </Text>

          <Form.Item
            name="positionId"
            rules={[{ required: false }]}
            className="mt-2"
          >
            <Select
              placeholder="Select Position"
              allowClear
              className="min-h-[50px]"
            >
              {positions.map((pos: any) => (
                <Select.Option key={pos.id} value={pos.id}>
                  {pos.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <div className="w-full flex justify-end">
              <Button
                type="primary"
                htmlType="submit"
                className="w-1/2 bg-primary-600 h-[50px]"
              >
                Send Invitation
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default SignUp;
