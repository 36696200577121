import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const addPositionService = async (positionData: any) =>
  handleApiCall(() => ApiService.post(apiRoutes.position, positionData));

export const getPositionService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.position));

export const getPositionUserService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.positionUsers));

export const getPositionByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.positionById.replace(":id", id))
  );

export const deletePositionByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.positionById.replace(":id", id))
  );

export const editPositionService = async (id: string, positionData: any) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.positionById.replace(":id", id), positionData)
  );
