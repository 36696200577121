import { createSelector } from "@reduxjs/toolkit";

import { LearnerQuizzieStateType } from "../../../utils/types/Learner";
import { StateType } from "../../../utils";

const selectQuizState = (state: StateType): LearnerQuizzieStateType =>
  state.learnerQuizzieReducer;

export const selectQuizzie = createSelector(
  selectQuizState,
  (state: LearnerQuizzieStateType) => state.quizzie
);

export const selectQuizzies = createSelector(
  selectQuizState,
  (state: LearnerQuizzieStateType) => state.quizzies
);

export const selectQuizziesLoading = createSelector(
  selectQuizState,
  (state: LearnerQuizzieStateType) => state.loading
);

export const selectQuizziesLoaded = createSelector(
  selectQuizState,
  (state: LearnerQuizzieStateType) => state.loaded
);
