import { LearnerNewsFeedStateType } from "../../../utils/types/Learner";

export const newsFeedTypes = {
  GET_LEARNER_NEWS_FEED_ACTION: "GET_LEARNER_NEWS_FEED_ACTION",
  SET_LEARNER_NEWS_FEED_ACTION: "SET_LEARNER_NEWS_FEED_ACTION",
  DELETE_LEARNER_NEWS_FEED_ACTION: "DELETE_LEARNER_NEWS_FEED_ACTION",
  UPDATE_LEARNER_NEWS_FEED_STATE_ACTION:
    "UPDATE_LEARNER_NEWS_FEED_STATE_ACTION",
};

export const getLearnerNewsFeed = () => ({
  type: newsFeedTypes.GET_LEARNER_NEWS_FEED_ACTION,
});

export const setLearnerNewsFeed = (payload: LearnerNewsFeedStateType) => ({
  type: newsFeedTypes.SET_LEARNER_NEWS_FEED_ACTION,
  payload,
});

export const deleteNewsFeed = (payload: LearnerNewsFeedStateType) => ({
  type: newsFeedTypes.DELETE_LEARNER_NEWS_FEED_ACTION,
  payload,
});

export const updateNewsFeed = (payload: LearnerNewsFeedStateType) => ({
  type: newsFeedTypes.UPDATE_LEARNER_NEWS_FEED_STATE_ACTION,
  payload,
});
