import { Typography } from "antd";
import { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";

const { Text } = Typography;

const Answers = (props: any) => {
  const { answers, selectedAnswers, setSelectedAnswers } = props;

  const handleSelect = (answerId: string) => {
    setSelectedAnswers((prevSelected: string[]) =>
      prevSelected.includes(answerId)
        ? prevSelected.filter((item) => item !== answerId)
        : [...prevSelected, answerId]
    );
  };

  return (
    <>
      {answers.map((answer: any, index: number) => (
        <div
          key={index}
          onClick={() => handleSelect(answer.id)}
          className={`flex items-center px-4 py-2 rounded-lg cursor-pointer h-[50px] ${
            selectedAnswers.includes(answer.id)
              ? "bg-primary-100"
              : "bg-neutral-100"
          } hover:bg-primary-200 transition`}
        >
          <Text
            className={`flex-1 text-neutral-600 text-sm ${
              selectedAnswers.includes(answer.id) ? "font-semibold" : ""
            }`}
          >
            {answer.answer}
          </Text>
          {selectedAnswers.includes(answer.id) && (
            <CheckOutlined className="text-blue-500" />
          )}
        </div>
      ))}
    </>
  );
};

export default Answers;
