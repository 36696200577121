import { createSelector } from "@reduxjs/toolkit";

import { EducatorQuizzieStateType } from "../../../utils/types";
import { StateType } from "../../../utils";

const selectQuizzieState = (state: StateType): EducatorQuizzieStateType =>
  state.educatorQuizzieReducer;

export const selectQuizzies = createSelector(
  selectQuizzieState,
  (state: EducatorQuizzieStateType) => state.quizzies
);

export const selectQuizzie = createSelector(
  selectQuizzieState,
  (state: EducatorQuizzieStateType) => state.quizzie
);

export const selectQuizziesLoading = createSelector(
  selectQuizzieState,
  (state: EducatorQuizzieStateType) => state.loading
);

export const selectQuizziesLoaded = createSelector(
  selectQuizzieState,
  (state: EducatorQuizzieStateType) => state.loaded
);

export const selectQuizziesSubmissionLoading = createSelector(
  selectQuizzieState,
  (state: EducatorQuizzieStateType) => state.loadingSubmission
);
