import { useState, useEffect } from "react";
import { Button, Col, Input, Row, Select, Typography } from "antd";
import CreateEntityInfo from "../../../../shared/CreateEntityInfo";
import DepartmentSelector from "../../../../shared/DepartmentSelector";
import PositionSelector from "../../../../shared/PositionSelector";
import UserSelector from "../../../../shared/UserSelector";
const { Text } = Typography;

const QuizzieStructure = (props: any) => {
  const {
    form,
    formValues,
    setFormValues,
    setPreviousStep,
    setQuizStep,
    isEdit,
    loading,
  } = props;

  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [points, setPoints] = useState<any>(null);
  const [minimumCorrectAnswers, setMinimumCorrectAnswers] = useState<any>(null);
  const [maxNumberOfAnswers, setMaxNumberOfAnswers] = useState([]);

  useEffect(() => {
    if (formValues?.userIds?.length > 0) {
      setSelectedUsers(formValues?.userIds);
    }
    if (formValues?.departmentIds?.length > 0) {
      setSelectedDepartments(formValues?.departmentIds);
    }
    if (formValues?.positionIds?.length > 0) {
      setSelectedPositions(formValues?.positionIds);
    }
    if (formValues?.questions?.length > 0) {
      calculateMaxNumberOfAnswers();
    }
    if (formValues?.points) {
      setPoints(formValues.points);
    }
    if (formValues?.minimumCorrectAnswers) {
      setMinimumCorrectAnswers(formValues.minimumCorrectAnswers);
    }
  }, []);

  const calculateMaxNumberOfAnswers = () => {
    const { questions } = formValues;

    setMaxNumberOfAnswers(
      questions?.map((question: any, index: number) => ({
        label: `Answer${index === 0 ? "" : "s"} ${index + 1}`,
        value: index + 1,
      }))
    );
  };

  const handleDepartmentChange = (departments: string[]) => {
    setSelectedDepartments(departments);
    setFormValues((prevState: any) => ({
      ...prevState,
      departmentIds: departments,
    }));
  };

  const handlePositionChange = (positions: string[]) => {
    setSelectedPositions(positions);
    setFormValues((prevState: any) => ({
      ...prevState,
      positionIds: positions,
    }));
  };

  const handleUserChange = (users: string[]) => {
    setSelectedUsers(users);
    setFormValues((prevState: any) => ({
      ...prevState,
      userIds: users,
    }));
  };

  const onChangeMinimumCorrectAnswers = (value: number) => {
    setMinimumCorrectAnswers(value);

    setFormValues((prevState: any) => ({
      ...prevState,
      minimumCorrectAnswers: value,
    }));
  };

  const handlePointsChange = (value: string) => {
    setPoints(value);

    setFormValues((prevState: any) => ({
      ...prevState,
      points: value,
    }));
  };

  const handleGoBack = () => {
    setPreviousStep();
    setQuizStep(1);
  };

  const handlePublishQuizzie = () => {
    form.submit();
  };

  return (
    <Row className="pb-10">
      <Col lg={8}>
        <CreateEntityInfo
          title="Assign Quizzie"
          text="Assign a Quizzie by adding the departments, positions and related users. Once you finalize quizzie structure proceed to Publish Quizzie."
        ></CreateEntityInfo>
      </Col>
      <Col lg={16}>
        <div className="flex flex-col gap-2 mt-11">
          <DepartmentSelector
            selectedDepartments={selectedDepartments}
            setSelectedDepartments={setSelectedDepartments}
            onSelectionChange={handleDepartmentChange}
          />
          <PositionSelector
            onSelectionChange={handlePositionChange}
            selectedPositions={selectedPositions}
            setSelectedPositions={setSelectedPositions}
          />
          <UserSelector
            onSelectionChange={handleUserChange}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
          <div className="flex gap-4 mt-2">
            <div className="w-full">
              <Text className="text-neutral-600 text-sm font-semibold">
                Points
              </Text>

              <Input
                onChange={(e) => handlePointsChange(e.target.value)}
                type="number"
                value={points}
                placeholder="Points"
              />
            </div>
            <div className="w-full h-[50px]">
              <Text className="text-neutral-600 text-sm font-semibold">
                Correct Answers
              </Text>

              <Select
                style={{ width: "100%", height: "100%" }}
                value={minimumCorrectAnswers}
                options={maxNumberOfAnswers}
                onChange={onChangeMinimumCorrectAnswers}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-5 justify-center mt-5 ">
          <Button
            onClick={handleGoBack}
            className="w-full h-[50px]  text-base  text-primary-600 border-primary-200 font-semibold rounded-lg"
          >
            Back
          </Button>

          <Button
            className="w-full h-[50px] text-base bg-primary-600 font-semibold rounded-lg"
            type="primary"
            onClick={handlePublishQuizzie}
            disabled={!points || !minimumCorrectAnswers}
            loading={loading}
          >
            Publish quizzie
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default QuizzieStructure;
