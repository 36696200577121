import { produce } from "immer";

import { types } from "../actions";
import { HeaderStateType } from "../../../utils/types/Header/HeaderState";

const defaultState: HeaderStateType = {
  steps: [],
  titles: {
    course: null,
    lesson: null,
    quiz: null,
  },
};

const handleCreateInitialSteps = (state: HeaderStateType, steps: number) => {
  state.steps = new Array(steps).fill(false);
  if (steps > 0) {
    state.steps[0] = true;
  }
  return state;
};

const handleResetStepper = (state: HeaderStateType) => {
  state.steps = [];

  return state;
};

const handleSetNextStep = (state: HeaderStateType) => {
  const index = state.steps.findIndex((value) => value === false);
  if (index !== -1) {
    state.steps[index] = true;
  }
  return state;
};

const handleSetPreviousStep = (state: HeaderStateType) => {
  const lastTrueIndex = state.steps.lastIndexOf(true);
  if (lastTrueIndex !== -1) {
    state.steps[lastTrueIndex] = false;
  }
  return state;
};

const handleSetStep = (state: HeaderStateType, payload: any) => {
  const { steps, currentStep } = payload;
  state.steps = new Array(steps).fill(false);
  for (let i = 0; i < currentStep && i < steps; i++) {
    state.steps[i] = true;
  }
  return state;
};

const handleSetHeaderTitles = (state: HeaderStateType, payload: any) => {
  state.titles = payload.titles;
  return state;
};

export default produce((state: HeaderStateType = defaultState, action) => {
  switch (action.type) {
    case types.CREATE_INITIAL_STEPS:
      return handleCreateInitialSteps(state, action.payload);
    case types.SET_NEXT_STEP:
      return handleSetNextStep(state);
    case types.SET_PREVIOUS_STEP:
      return handleSetPreviousStep(state);
    case types.RESET_STEPPER:
      return handleResetStepper(state);
    case types.SET_STEP:
      return handleSetStep(state, action.payload);
    case types.SET_TITLES:
      return handleSetHeaderTitles(state, action.payload);
    default:
      return state;
  }
});
