import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../utils/types";

const mapStateToProps = (state: StateType) => ({});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
