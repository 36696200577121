import { LearnerStatisticsState } from "../../../utils/types/Learner";

export const statisticsTypes = {
  GET_LEARNER_STATISTICS_ACTION: "GET_LEARNER_STATISTICS_ACTION",
  SET_LEARNER_STATISTICS_ACTION: "SET_LEARNER_STATISTICS_ACTION",
};

export const getLearnerStatistics = () => ({
  type: statisticsTypes.GET_LEARNER_STATISTICS_ACTION,
});

export const setLearnerStatistics = (payload: LearnerStatisticsState) => ({
  type: statisticsTypes.SET_LEARNER_STATISTICS_ACTION,
  payload,
});
