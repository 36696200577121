import { produce } from "immer";

import { lessonTypes as types } from "../actions";
import { LearnerLessonStateType } from "../../../utils";

const defaultState: LearnerLessonStateType = {
  lessonSummary: null,
  loading: false,
  presignedUrl: null,
};

const handleSetPresignedUrl = (state: any, payload: any) => {
  state.presignedUrl = payload;
  state.loading = false;
  return state;
};

const handleRemovePresignedUrl = (state: any) => {
  state.presignedUrl = null;
  state.loading = false;
  return state;
};

export default produce(
  (state: LearnerLessonStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_PRESIGNED_URL:
        return handleSetPresignedUrl(state, action.payload);
      case types.REMOVE_PRESIGNED_URL:
        return handleRemovePresignedUrl(state);
      case types.GET_PRESIGNED_URL:
        return {
          ...state,
          loading: true,
          presignedUrl: null,
        };
      default:
        return state;
    }
  }
);
