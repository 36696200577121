import { Card, Typography } from "antd";
import { Link } from "react-router-dom";
import type { CreateCardProps } from "../../utils/types";

const { Text } = Typography;

const CreateCard = (props: any) => {
  const { icon: Icon, title, path } = props;

  return (
    <Link to={path} className="no-underline">
      <Card className="p-6 educator-dashboard-box-shadow-cards  hover:shadow-md fill-primary-400 hover:fill-primary-600">
        <div className="flex flex-wrap gap-3">
          <Icon height={20} />
          <Text className="text-neutral-600 hover:text-primary-450 text-sm">
            {title}
          </Text>
        </div>
      </Card>
    </Link>
  );
};

export default CreateCard;
