import React from "react";
import succssIcon from "../../assets/images/results-success.svg";
import failIcon from "../../assets/images/results-fail.svg";

export default [
  {
    path: "/quizzie/results",
    success: {
      icon: succssIcon,
      title: "Congratulations!",
      text: "You successfully completed the Quizzie and gained ",
      smallText: "",
      firstButtonText: "",
      firstButtonOnClickNavigate: "",
      secondButtonText: "All Quizzies",
      secondButtonOnClickNavigate: "/user/quizzie",
    },
    fail: {
      icon: failIcon,
      title: "Sorry!",
      text: "Quizzie is not completed!",
      smallText:
        "To complete this course, you must answer %NUMBER% questions correctly.",
      firstButtonText: "All Quizzies",
      firstButtonOnClickNavigate: "/user/quizzie",
      secondButtonText: "Start again",
      secondButtonOnClickNavigate: "/user/quizzie/:id",
    },
  },
  {
    path: "/quiz/results",
    success: {
      icon: succssIcon,
      title: "Congratulations!",
      text: "You successfully completed the Quiz",
      smallText: "",
      firstButtonText: "",
      firstButtonOnClickNavigate: "",
      secondButtonText: "Next Lesson",
      secondButtonOnClickNavigate: "/user/course/:id/lesson",
    },
    fail: {
      icon: failIcon,
      title: "Sorry!",
      text: "Lesson is not completed!",
      smallText:
        "To complete this lesson, you must answer %NUMBER% questions correctly.",
      firstButtonText: "",
      firstButtonOnClickNavigate: "",
      secondButtonText: "Re-start the Lesson now",
      secondButtonOnClickNavigate: "/user/course/:id/lesson",
    },
  },
];
