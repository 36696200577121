import { Form, Input, Button, Typography } from "antd";
import type { LoginProps } from "../../../utils/types";
import { useNavigate, Link } from "react-router-dom";

const { Text } = Typography;

const Login = (props: LoginProps) => {
  const {
    actions: { login },
  } = props;

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinishLogin = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    login({ email, password, navigate });
  };

  return (
    <div className="w-[351px]">
      <div className="flex flex-col gap-3 mb-[50px]">
        <Text className="text-neutral-600 text-xl font-medium">Welcome,</Text>
        <Text className="text-neutral-500 text-sm font-normal">
          to our educational app where learning and teaching is easy and fun.
        </Text>
      </div>
      <Form
        form={form}
        name="basic"
        onFinish={onFinishLogin}
        autoComplete="off"
        size="large"
      >
        <Text className="text-neutral-600 text-sm font-semibold">
          Email Address
        </Text>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
          className="mt-2"
        >
          <Input
            type="email"
            autoComplete="on"
            name="email"
            placeholder="Input your Email Address"
          />
        </Form.Item>

        <Text className="text-neutral-600 text-sm font-semibold">Password</Text>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
          className="mt-2"
        >
          <Input.Password
            type="password"
            autoComplete="on"
            name="Password"
            placeholder="Input your Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="w-full bg-primary-600 h-[50px] text-base leading-[18px] font-semibold"
          >
            Sign In
          </Button>
        </Form.Item>
      </Form>
      <div className="w-full flex justify-center">
        <Link to="/reset-password">
          <Text className="text-primary-500 text-sm">
            Forgot your password ?
          </Text>
        </Link>
      </div>
    </div>
  );
};

export default Login;
