import { produce } from "immer";

import { statisticsTypes as types } from "../actions";
import { LearnerStatisticsState } from "../../../utils/types/Learner";

const defaultState: {
  learnerStatistics: LearnerStatisticsState;
  loading: boolean;
} = {
  learnerStatistics: {
    completedCourses: "",
    completedLessons: "",
    completedQuizzes: "",
    totalCourses: "",
    totalLessons: "",
    totalQuizzes: "",
  },
  loading: false,
};

const handleSetLearnerStatistics = (
  state: { learnerStatistics: LearnerStatisticsState; loading: boolean },
  payload: LearnerStatisticsState
) => {
  state.learnerStatistics = payload;
  state.loading = false;
  return state;
};

export default produce(
  (
    state: {
      learnerStatistics: LearnerStatisticsState;
      loading: boolean;
    } = defaultState,
    action
  ) => {
    switch (action.type) {
      case types.SET_LEARNER_STATISTICS_ACTION:
        return handleSetLearnerStatistics(state, action.payload);
      case types.GET_LEARNER_STATISTICS_ACTION:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
