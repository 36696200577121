import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "antd";

import UserHeader from "../../modules/learner/Header/Header";
import UserSidebar from "../../modules/learner/Sidebar/Sidebar";
import UserHomeScreen from "../../modules/learner/Home";
import UserDashboardScreen from "../../modules/learner/Dashboard";
import { selectUser } from "../../redux/auth/selector";
import { types } from "../../redux/auth/actions";
import Courses from "../../modules/learner/Course/View";
import CourseDetailsScreen from "../../modules/learner/Course/Details";
import Quizzie from "../../modules/learner/Quizzie/Form";
import Quizzies from "../../modules/learner/Quizzie/View";
import Results from "../../modules/shared/Results";
import CourseWatch from "../../modules/learner/Course/Watch";
import Alerts from "../../modules/learner/Alert/View";
import Modules from "../../modules/learner/Module/View";
import ModuleDetailsScreen from "../../modules/learner/Module/Details";
import WildCardNavigation from "../../modules/shared/WildCardNavigation";

const { Content } = Layout;

const UserLayout = () => {
  const loggedUser = useSelector(selectUser);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!loggedUser) {
      dispatch({ type: types.GET_LOGGED_USER });
    }
  });

  return (
    <Layout style={{ minHeight: "100vh", padding: "0" }}>
      <UserSidebar></UserSidebar>

      <Layout>
        <UserHeader></UserHeader>

        <Content className="educator-container">
          <Routes>
            <Route path="home" element={<UserHomeScreen />} />
            <Route path="dashboard" element={<UserDashboardScreen />} />
            <Route path="module" element={<Modules />} />
            <Route path="module/:id" element={<ModuleDetailsScreen />} />
            <Route path="course" element={<Courses />} />
            <Route path="course/:id" element={<CourseDetailsScreen />} />
            <Route path="course/:id/lesson" element={<CourseWatch />} />
            <Route
              path="quiz/results"
              element={<Results path="/user/quiz" />}
            />
            <Route path="alert" element={<Alerts />} />
            <Route path="quizzie" element={<Quizzies />} />
            <Route path="quizzie/:id" element={<Quizzie />} />
            <Route
              path="quizzie/results"
              element={<Results path="/user/quizzie" />}
            />

            <Route path="*" element={<WildCardNavigation />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default UserLayout;
