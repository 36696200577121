import { call, all, put, takeLatest } from "redux-saga/effects";

import { setVideo, aiContentTypes as types, setExtractText } from "../actions";
import { AxiosResponse } from "axios";
import {
  addVideoService,
  getVideoService,
  deleteVideoByIdService,
  extractTextService,
} from "../../../services/educator/ai-content";
import { EntityIdPayload } from "../../../utils";
import showNotification from "../../../services/notificationService";

export default function* educatorAIContentSagas() {
  yield all([
    takeLatest(types.ADD_VIDEO, handleAddVideo),
    takeLatest(types.GET_VIDEO, handleGetVideo),
    takeLatest(types.DELETE_VIDEO, handleDeleteVideo),
    takeLatest(types.EXTRACT_TEXT, handleExtractText),
  ]);
}

function* handleAddVideo(action: any) {
  const { data }: AxiosResponse = yield call(
    addVideoService,
    action.payload.videoData
  );

  if (data) {
    action.payload.navigate("/success/ai-content");
  }
}

function* handleGetVideo() {
  const { data }: AxiosResponse = yield call(getVideoService);
  if (data) {
    yield put(setVideo({ videos: data }));
  }
}

function* handleDeleteVideo(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    deleteVideoByIdService,
    action.payload.id
  );
  if (data) {
    showNotification("success", "Video deleted successfully");
    yield put({ type: types.GET_VIDEO });
  }
}

function* handleExtractText(action: any) {
  const { data }: AxiosResponse = yield call(
    extractTextService,
    action.payload
  );
  if (data) {
    yield put(setExtractText({ extractText: data }));
  }
}
