import { Select } from "antd";

const TimelineSelection = ({
  onSelectionChange,
  options,
  selectedOption,
}: any) => (
  <Select
    defaultValue="week"
    style={{ width: 140 }}
    onChange={onSelectionChange}
    value={selectedOption}
    options={options}
  />
);

export default TimelineSelection;
