import { createSelector } from "reselect";

import { StateType } from "../../../utils/types";
import { EducatorStateType } from "../../../utils/types/Educator/EducatorState";

const selectEducatorState = (state: StateType): EducatorStateType =>
  state.educatorReducer;

export const selectStatisticsOverall = createSelector(
  selectEducatorState,
  (state: EducatorStateType) => state.statistics
);
