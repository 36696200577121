import { createSelector } from "@reduxjs/toolkit";

import { EducatorQuizStateType } from "../../../utils/types";
import { StateType } from "../../../utils";

const selectQuizState = (state: StateType): EducatorQuizStateType =>
  state.educatorQuizReducer;

export const selectQuizzes = createSelector(
  selectQuizState,
  (state: EducatorQuizStateType) => state.quizzes
);

export const selectQuiz = createSelector(
  selectQuizState,
  (state: EducatorQuizStateType) => state.quiz
);

export const selectQuizzesLoading = createSelector(
  selectQuizState,
  (state: EducatorQuizStateType) => state.loading
);

export const selectQuizzesLoaded = createSelector(
  selectQuizState,
  (state: EducatorQuizStateType) => state.loaded
);

export const selectQuizzesSubmissionLoading = createSelector(
  selectQuizState,
  (state: EducatorQuizStateType) => state.loadingSubmission
);

export const selectQuizzesGenerateLoading = createSelector(
  selectQuizState,
  (state: EducatorQuizStateType) => state.loadingGenerate
);
