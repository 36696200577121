import { produce } from "immer";

import { analyticsTypes as types } from "../actions";
import { EducatorAnalyticsStateType } from "../../../utils/types/Educator/analytics/AnalyticsState";

const defaultState: EducatorAnalyticsStateType = {
  entity: {
    loading: false,
    loaded: false,
    data: [],
  },
  singleEntity: {
    loading: false,
    loaded: false,
    data: {},
  },
  benchmark: {
    loading: false,
    loaded: false,
    data: {},
  },
};

const handleGetEntity = (state: any, payload: any) => {
  state.entity = {
    ...state.entity,
    loading: true,
    loaded: false,
  };
  return state;
};

const handleSetEntity = (state: any, payload: any) => {
  state.entity = {
    data: payload.entity,
    loading: false,
    loaded: true,
  };

  return state;
};

const handleGetEntityById = (state: any, payload: any) => {
  state.singleEntity = {
    ...state.singleEntity,
    loading: true,
    loaded: false,
  };
  return state;
};

const handleSetEntityById = (state: any, payload: any) => {
  state.singleEntity = {
    data: payload.singleEntity,
    loading: false,
    loaded: true,
  };

  return state;
};

const handleGetBenchmark = (state: any) => {
  state.benchmark = {
    ...state.benchmark,
    loading: true,
    loaded: false,
  };
  return state;
};

const handleSetBenchmark = (state: any, payload: any) => {
  state.benchmark = {
    data: {
      courses: payload.courses,
      modules: payload.modules,
      users: payload.users,
      lessons: payload.lessons,
    },
    loading: false,
    loaded: true,
  };

  return state;
};

const handleResetSingleEntity = (state: any) => {
  state.singleEntity = {
    data: {},
    loading: false,
    loaded: false,
  };

  return state;
};

export default produce(
  (state: EducatorAnalyticsStateType = defaultState, action) => {
    switch (action.type) {
      case types.GET_ANALYTICS:
        return handleGetEntity(state, action.payload);
      case types.SET_ANALYTICS:
        return handleSetEntity(state, action.payload);
      case types.GET_ANALYTICS_ENTITY_BY_ID:
        return handleGetEntityById(state, action.payload);
      case types.SET_ANALYTICS_ENTITY_BY_ID:
        return handleSetEntityById(state, action.payload);
      case types.GET_BENCHMARK_ANAYLTICS:
        return handleGetBenchmark(state);
      case types.SET_BENCHMARK_ANAYLTICS:
        return handleSetBenchmark(state, action.payload);
      case types.RESET_ANALYTICS_MODULE_BY_ID ||
        types.RESET_ALALYTICS_COURSE_BY_ID ||
        types.RESET_ANALYTICS_LESSON_BY_ID:
        return handleResetSingleEntity(state);

      default:
        return state;
    }
  }
);
