import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { addQuizzie } from "../../../../redux/educator/actions";
import { StateType } from "../../../../utils";
import {
  setInitialSteps,
  setNextStep,
  setPreviousStep,
} from "../../../../redux/shared/actions";
import { selectQuizziesSubmissionLoading } from "../../../../redux/educator/selector/quizzie";

const mapStateToProps = (state: StateType) => ({
  loadingSubmission: selectQuizziesSubmissionLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      setInitialSteps,
      setNextStep,
      setPreviousStep,
      addQuizzie,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
