import { IdPayload } from "../../../utils";
import { SetQuizziePayload } from "../../../utils/types";

export const quizzieTypes = {
  ADD_QUIZZIE: "ADD_QUIZZIE",
  GET_QUIZZIE: "GET_QUIZZIE",
  SET_QUIZZIES: "SET_QUIZZIES",
  GET_QUIZZIE_BY_ID: "GET_QUIZZIE_BY_ID",
  SET_QUIZZIE_BY_ID: "SET_QUIZZIE_BY_ID",
  DELETE_QUIZZIE: "DELETE_QUIZZIE",
  SUBMISSION_COMPLETE: "SUBMISSION_COMPLETE",
};

export const addQuizzie = (payload: SetQuizziePayload) => ({
  type: quizzieTypes.ADD_QUIZZIE,
  payload,
});

export const getQuizzie = () => ({
  type: quizzieTypes.GET_QUIZZIE,
});

export const setQuizzies = (payload: any) => ({
  type: quizzieTypes.SET_QUIZZIES,
  payload,
});

export const deleteQuizzie = (payload: IdPayload) => ({
  type: quizzieTypes.DELETE_QUIZZIE,
  payload,
});

export const getQuizzieById = (payload: IdPayload) => ({
  type: quizzieTypes.GET_QUIZZIE_BY_ID,
  payload,
});

export const setQuizzieById = (payload: any) => ({
  type: quizzieTypes.SET_QUIZZIE_BY_ID,
  payload,
});

export const submissionComplete = () => ({
  type: quizzieTypes.SUBMISSION_COMPLETE,
});
