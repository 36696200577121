import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const getCourseService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.course));

export const getCourseByIdService = async (id: string) =>
  handleApiCall(() => ApiService.get(apiRoutes.courseById.replace(":id", id)));

export const restartCourseByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.post(apiRoutes.courseRestart.replace(":id", id))
  );

export const getLatestCourseService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.courseLatest));
