import { Input, Button, Form } from "antd";
import CompanyStructureItems from "../../../assets/images/company-structure-items/company-structure-items";

const ItemForm = (props: any) => {
  const { itemType, newItem, onChangeNewItem, onAddItem } = props;
  const [form] = Form.useForm();

  const onFinishForm = () => {
    onAddItem();
    form.resetFields();
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={onFinishForm}
        autoComplete="off"
        size="large"
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please input name!" }]}
        >
          <div className="border border-primary-300 rounded-lg">
            <Input
              type="name"
              autoComplete="off"
              name="name"
              value={newItem}
              onChange={onChangeNewItem}
              placeholder={"Type " + itemType + " Name"}
              className="m-0 rounded-lg h-[36px] text-sm"
            />
          </div>
        </Form.Item>
        <Button
          disabled={newItem.trim() === ""}
          htmlType="submit"
          className="w-full flex items-center justify-center text-neutral-600 text-sm font-semibold border-none shadow-none"
          icon={<CompanyStructureItems.Add />}
        >
          Add New {itemType}
        </Button>
      </Form>
    </>
  );
};

export default ItemForm;
