import { createSelector } from "@reduxjs/toolkit";

import { EducatorLessonStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectLessonState = (state: StateType): EducatorLessonStateType =>
  state.educatorLessonReducer;

export const selectLessons = createSelector(
  selectLessonState,
  (state: EducatorLessonStateType) => state.lessons
);

export const selectLesson = createSelector(
  selectLessonState,
  (state: EducatorLessonStateType) => state.lesson
);

export const selectLessonLoading = createSelector(
  selectLessonState,
  (state: EducatorLessonStateType) => state.loading
);

export const selectLessonLoaded = createSelector(
  selectLessonState,
  (state: EducatorLessonStateType) => state.loaded
);
