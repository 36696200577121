import axios from "axios";
import StorageService from "./storageService";

const BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

const defaultOptions = (url: string) => ({
  timeout: 30000,
  baseURL: url,
});

const ApiService = axios.create(defaultOptions(BASE_URL));

ApiService.interceptors.request.use((req) => {
  if (req.headers) {
    req.headers.Authorization = `Bearer ${
      StorageService.getStringItem("accessToken") as string
    }`;
    if (req.data instanceof FormData) {
      delete req.headers["Content-Type"];
    } else {
      req.headers["Content-Type"] = "application/json";
    }
  }
  return req;
});

ApiService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshToken();
        ApiService.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${newAccessToken}`;
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

        return ApiService(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

const refreshToken = async () => {
  try {
    const refreshToken = StorageService.getStringItem("refreshToken");
    const userRole = StorageService.getStringItem("role");
    const payload = userRole
      ? {
          refreshToken,
          tokenScope: userRole,
        }
      : {
          refreshToken,
        };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/refresh-token`,
      payload
    );

    StorageService.setStringItem("accessToken", response?.data?.accessToken);
    StorageService.setStringItem("refreshToken", response?.data?.refreshToken);

    return response?.data?.accessToken;
  } catch (error) {
    console.error("Token refresh failed:", error);
    window.location.href = "/login";
    throw error;
  }
};

export default ApiService;
