export default {
  bosnian: {
    code: "bs-BA",
    voices: {
      female: "VesnaNeural",
      male: "GoranNeural",
    },
  },
  english: {
    code: "en-US",
    voices: {
      female: "AvaMultilingualNeural",
      male: "AndrewMultilingualNeural",
    },
  },
};
