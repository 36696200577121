import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  setCompanyInfoById,
  companyInfoTypes as types,
  getCompanyInfoById,
} from "../actions";
import { AxiosResponse } from "axios";
import {
  getCompanyInfoByIdService,
  editCompanyInfoService,
} from "../../../services/educator/company";
import { EntityIdPayload } from "../../../utils";
import showNotification from "../../../services/notificationService";

export default function* educatorCompanyInfoSagas() {
  yield all([
    takeLatest(types.GET_COMPANY_INFO_BY_ID, handleGetCompanyInfoById),
    takeLatest(types.EDIT_COMPANY_INFO, handleEditCompanyInfo),
  ]);
}

function* handleGetCompanyInfoById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getCompanyInfoByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setCompanyInfoById({ companyInfo: data }));
  }
}

function* handleEditCompanyInfo(action: any) {
  const { data }: AxiosResponse = yield call(
    editCompanyInfoService,
    action.payload.id,
    action.payload.companyInfo
  );
  if (data) {
    showNotification("success", data.message);
    const id = action.payload.id;
    yield put(getCompanyInfoById({ id }));
  }
}
