import { Card, Col, Row, Tag, Skeleton } from "antd";
import EmptyState from "../../../shared/EmptyState";

const ExistingVideoSelect = ({
  onVideoSelect,
  selectedVideoId,
  videos,
  loading,
}: {
  onVideoSelect: (video: any) => void;
  selectedVideoId: string | null;
  videos: any[];
  loading: boolean;
}) => {
  if (loading) {
    return <Skeleton active />;
  }

  if (!videos.length) {
    return (
      <div className="flex items-center justify-center h-full ">
        <EmptyState />
      </div>
    );
  }

  return (
    <Row gutter={[16, 16]}>
      {videos.map((video) => (
        <Col span={8} key={video.id}>
          <Card
            hoverable
            onClick={() => onVideoSelect(video)}
            cover={<video src={video.url} controls style={{ width: "100%" }} />}
            style={{
              border: video.id === selectedVideoId ? "2px solid blue" : "",
            }}
          >
            <Card.Meta
              title={video.title}
              description={<Tag color="blue">{video.tag}</Tag>}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ExistingVideoSelect;
