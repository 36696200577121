import {
  Input,
  Button,
  DatePicker,
  Select,
  Avatar,
  Upload,
  Row,
  Col,
  Form,
} from "antd";
import {
  UserOutlined,
  CameraOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useState, useEffect } from "react";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";

const { Option } = Select;
const cloudFrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const EditUserScreen = (props: any) => {
  const {
    user,
    departments,
    positions,
    actions: { getDepartment, getPosition, getUserById, editUser },
  } = props;
  const [form] = Form.useForm();
  const [isChanged, setIsChanged] = useState(false);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const { id } = useParams();

  useEffect(() => {
    getDepartment();
    getPosition();
    getUserById({ id });
  }, []);

  useEffect(() => {
    if (!user) return;
    setAvatarUrl(
      user.profilePictureKey ? `${cloudFrontKey}/${user.profilePictureKey}` : ""
    );
    form.setFieldsValue({
      name: user.name || null,
      email: user.email,
      dateOfBirth: user.dateOfBirth ? dayjs(user.dateOfBirth) : null,
      department: user.departmentId,
      position: user.positionId,
    });
  }, [user, form]);

  const handleFormChange = () => {
    const fieldsChanged = form.isFieldsTouched();
    setIsChanged(fieldsChanged);
  };

  const handleSaveChanges = () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        if (!!values.name && !!values.name.trim())
          formData.append("name", values.name.trim());
        formData.append("email", values.email);
        if (values.dateOfBirth)
          formData.append(
            "dateOfBirth",
            values.dateOfBirth.format("YYYY-MM-DD")
          );
        formData.append(
          "departmentId",
          values.department !== undefined ? values.department : null
        );

        formData.append(
          "positionId",
          values.position !== undefined ? values.position : null
        );

        if (profilePicture) {
          formData.append("profilePicture", profilePicture);
        }
        editUser({ id, user: formData });
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      });
  };

  const handleUploadChange = (info: any) => {
    const file = info.file.originFileObj || info.file;

    if (file) {
      setProfilePicture(file);
      try {
        const imageUrl = URL.createObjectURL(file);
        setAvatarUrl(imageUrl);
      } catch (error) {
        console.error("Error creating object URL: ", error);
      }

      setIsChanged(true);
    } else {
      console.error("No valid file provided for upload.");
    }
  };

  const beforeUpload = (file: File) => {
    return false;
  };

  return (
    <Row className="h-full pb-[60px]">
      <Col lg={8}>
        <CreateEntityInfo
          title=""
          text={
            <Link
              to="/educator/user"
              className="text-neutral-600 font-semibold text-sm"
            >
              <ArrowLeftOutlined className="text-neutral-300 mr-2" /> Back to
              users
            </Link>
          }
        />
      </Col>
      <Col lg={16} className="h-full flex flex-col max-w-[600px]">
        {user && (
          <>
            <div className="flex justify-center mb-4">
              <div className="relative">
                <Avatar size={150} icon={<UserOutlined />} src={avatarUrl} />
                <Upload
                  className="absolute bottom-0 right-0"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleUploadChange}
                  accept=".png,.jpg,.jpeg,.gif"
                >
                  <Button
                    shape="circle"
                    icon={<CameraOutlined />}
                    className="bg-blue-500 text-white"
                  />
                </Upload>
              </div>
            </div>

            <Form
              form={form}
              layout="vertical"
              onValuesChange={handleFormChange}
              initialValues={{
                name: user.name,
                email: user.email,
                dateOfBirth: dayjs(user.dateOfBirth),
                department: user.departmentId,
                position: user.positionId,
              }}
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: !!user.name, message: "Please input user name!" },
                  {
                    validator: (_, value) => {
                      if (!!value && value.trim() === "") {
                        return Promise.reject(
                          new Error("Name cannot be whitespace!")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Name" size="large" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input placeholder="Email" size="large" />
              </Form.Item>

              <Form.Item
                name="dateOfBirth"
                label="Date of Birth"
                rules={[
                  {
                    required: !!user.dateOfBirth,
                    message: "Please select your date of birth!",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Date of Birth"
                  size="large"
                  className="w-full h-[50px]"
                  disabledDate={(current) =>
                    !current || current.isAfter(dayjs())
                  }
                  allowClear={false}
                />
              </Form.Item>
              {/*
              <Form.Item name="language" label="Language">
                <Select size="large" className="w-full min-h-[50px]">
                  <Option value="English">English</Option>
                  <Option value="Bosnian">Bosnian</Option>
                </Select>
              </Form.Item>
*/}
              <Form.Item name="department" label="Department">
                <Select size="large" className="w-full min-h-[50px]" allowClear>
                  {departments.map((dept: any) => (
                    <Option key={dept.id} value={dept.id}>
                      {dept.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="position" label="Position">
                <Select size="large" className="w-full min-h-[50px]" allowClear>
                  {positions.map((pos: any) => (
                    <Option key={pos.id} value={pos.id}>
                      {pos.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  block
                  size="large"
                  className="bg-primary-600 h-[50px]"
                  onClick={handleSaveChanges}
                  disabled={!isChanged}
                >
                  Save Changes
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Col>
    </Row>
  );
};

export default EditUserScreen;
