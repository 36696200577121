import { Routes, Route } from "react-router-dom";
import { Layout } from "antd";

import EducatorHeader from "../../modules/educator/Header/Header";
import EducatorSidebar from "../../modules/educator/Sidebar/Sidebar";
import EducatorHomeScreen from "../../modules/educator/Home";
import EducatorSignUp from "../../modules/educator/User/SignUp";
import CreateQuiz from "../../modules/educator/Quiz/Create";
import EducatorCoursesScreen from "../../modules/educator/Course/View";
import CreateCourseScreen from "../../modules/educator/Course/Create";
import CourseDetailsScreen from "../../modules/educator/Course/Details";
import AssignLessonsScreen from "../../modules/educator/Course/AssignLessons";
import EducatorLessonsScreen from "../../modules/educator/Lesson/View";
import CreateLessonScreen from "../../modules/educator/Lesson/Create";
import AssignQuiz from "../../modules/educator/Lesson/AssignQuiz";
import LessonDetailsScreen from "../../modules/educator/Lesson/Details";
import CreateModuleScreen from "../../modules/educator/Module/Create";
import ModuleDetailsScreen from "../../modules/educator/Module/Details";
import EducatorModulesScreen from "../../modules/educator/Module/View";
import AssignCourses from "../../modules/educator/Module/AssignCourses";
import ViewQuiz from "../../modules/educator/Quiz/View";
import QuizDetails from "../../modules/educator/Quiz/Details";
import CompanyInfoScreen from "../../modules/educator/CompanyInfo";
import DepartmentsScreen from "../../modules/educator/Department";
import PositionsScreen from "../../modules/educator/Position";
import UsersScreen from "../../modules/educator/User/View";
import EditUserScreen from "../../modules/educator/User/Edit";
import EducatorAlertsScreen from "../../modules/educator/Alert/View";
import CreateAlertScreen from "../../modules/educator/Alert/Create";
import CreateQuizzie from "../../modules/educator/Quizzie/Create";
import ViewQuizzie from "../../modules/educator/Quizzie/View";
import QuizzieDetails from "../../modules/educator/Quizzie/Details";
import { useEffect } from "react";
import { selectUser } from "../../redux/auth/selector";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../redux/auth/actions";
import EditModuleScreen from "../../modules/educator/Module/Edit";
import EditCourseScreen from "../../modules/educator/Course/Edit";
import EditLessonScreen from "../../modules/educator/Lesson/Edit";
import EducatorDashboardStudentsScreen from "../../modules/educator/Dashboard/Students";
import EducatorDashboardLessonsScreen from "../../modules/educator/Dashboard/Lessons";
import EducatorDashboardCoursesScreen from "../../modules/educator/Dashboard/Courses";
import EducatorDashboardModulesScreen from "../../modules/educator/Dashboard/Modules";
import EducatorBenchmarkScreen from "../../modules/educator/Benchmark";
import WildCardNavigation from "../../modules/shared/WildCardNavigation";
import CreateAIContent from "../../modules/educator/AIContent/Create";
import AIContentScreen from "../../modules/educator/AIContent/View";

const { Content } = Layout;

const EducatorLayout = () => {
  const loggedUser = useSelector(selectUser);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!loggedUser) {
      dispatch({ type: types.GET_LOGGED_USER });
    }
  });

  return (
    <Layout style={{ minHeight: "100vh", padding: "0" }}>
      <EducatorSidebar></EducatorSidebar>

      <Layout>
        <EducatorHeader></EducatorHeader>

        <Content className="educator-container">
          <Routes>
            <Route path="home" element={<EducatorHomeScreen />} />
            <Route
              path="dashboard/module"
              element={<EducatorDashboardModulesScreen />}
            />
            <Route
              path="dashboard/course"
              element={<EducatorDashboardCoursesScreen />}
            />
            <Route
              path="dashboard/lesson"
              element={<EducatorDashboardLessonsScreen />}
            />
            <Route
              path="dashboard/user"
              element={<EducatorDashboardStudentsScreen />}
            />
            <Route path="benchmark" element={<EducatorBenchmarkScreen />} />
            <Route path="sign-up" element={<EducatorSignUp />} />
            <Route path="course" element={<EducatorCoursesScreen />} />
            <Route path="course/create" element={<CreateCourseScreen />} />
            <Route path="course/:id" element={<CourseDetailsScreen />} />
            <Route path="course/:id/edit" element={<EditCourseScreen />} />
            <Route
              path="course/:id/assign-lessons"
              element={<AssignLessonsScreen />}
            />

            <Route path="lesson" element={<EducatorLessonsScreen />} />
            <Route path="lesson/create" element={<CreateLessonScreen />} />
            <Route path="lesson/:id" element={<LessonDetailsScreen />} />
            <Route path="lesson/:id/assign-quiz" element={<AssignQuiz />} />
            <Route path="lesson/:id/edit" element={<EditLessonScreen />} />

            <Route path="module" element={<EducatorModulesScreen />} />
            <Route path="module/create" element={<CreateModuleScreen />} />
            <Route path="module/:id" element={<ModuleDetailsScreen />} />
            <Route path="module/:id/edit" element={<EditModuleScreen />} />
            <Route
              path="module/:id/assign-courses"
              element={<AssignCourses />}
            />

            <Route path="/quiz/create" element={<CreateQuiz />} />
            <Route path="/quiz" element={<ViewQuiz />} />
            <Route path="/quiz/:id" element={<QuizDetails />} />

            <Route path="/quizzie/create" element={<CreateQuizzie />} />
            <Route path="/quizzie" element={<ViewQuizzie />} />
            <Route path="/quizzie/:id" element={<QuizzieDetails />} />

            <Route path="company-info" element={<CompanyInfoScreen />} />
            <Route path="department" element={<DepartmentsScreen />} />
            <Route path="position" element={<PositionsScreen />} />
            <Route path="user" element={<UsersScreen />} />
            <Route path="user/:id" element={<EditUserScreen />} />

            <Route path="alert" element={<EducatorAlertsScreen />} />
            <Route path="alert/create" element={<CreateAlertScreen />} />

            <Route path="ai-content" element={<AIContentScreen />} />
            <Route path="ai-content/create" element={<CreateAIContent />} />

            <Route path="*" element={<WildCardNavigation />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default EducatorLayout;
