import { useEffect, useState } from "react";
import { ConnectedProps } from "react-redux";
import { Button, Image, Skeleton, Typography } from "antd";

import connector from "./Container";
import CircleProgress from "../../../shared/CircleProgress";
import { ReactComponent as CourseIcon } from "../../../../assets/images/reusable-items/course.svg";
import { ReactComponent as DurationIcon } from "../../../../assets/images/reusable-items/duration.svg";
import { ReactComponent as LessonIcon } from "../../../../assets/images/reusable-items/lesson.svg";
import { LessonProps } from "../../../../utils";
import { Link, useNavigate } from "react-router-dom";

const { Text } = Typography;

const LatestCourse = (props: ConnectedProps<typeof connector>) => {
  const {
    latestCourse,
    loading,
    actions: { getLatestCourse },
  } = props;
  const navigate = useNavigate();
  const [completedLessons, setCompletedLessons] = useState([]);
  const [collectedPoints, setCollectedPoints] = useState(0);

  useEffect(() => {
    getLatestCourse();
  }, []);

  useEffect(() => {
    latestCourse &&
      setCompletedLessons(
        latestCourse.lessons.filter((lesson: LessonProps) => lesson.isFinished)
      );
  }, [latestCourse]);

  useEffect(() => {
    const points = completedLessons?.reduce(
      (accumulator: number, item: LessonProps) => {
        return (accumulator += item.points);
      },
      0
    );
    setCollectedPoints(points);
  }, [completedLessons]);

  if (loading) {
    return (
      <div className="h-[292px]">
        <Skeleton active paragraph={{ rows: 7 }} />
      </div>
    );
  }

  return (
    <>
      <div>
        <Text className="w-full h-[54px] bg-primary-800 text-white rounded-lg flex justify-between items-center px-5 mb-5">
          <span className="font-semibold">Last Course Added</span>
          {latestCourse && (
            <Link
              to={"/user/course/" + latestCourse.id}
              className="text-primary-300 font-semibold"
            >
              See All
            </Link>
          )}
        </Text>
        {latestCourse ? (
          <div className="flex  gap-7">
            <div className="flex items-center">
              <CircleProgress
                size={100}
                percentage={
                  Math.round(
                    (completedLessons.length / latestCourse.lessonCount) * 100
                  ) || 0
                }
              />
            </div>
            <div className="w-full">
              <div className="text-neutral-600 font-semibold text-[15px] min-h-12">
                {latestCourse.title}
              </div>
              <div className="flex items-center gap-2">
                <div className="w-4 h-4">
                  <DurationIcon className="w-full h-full fill-primary-400" />
                </div>

                <Text className="text-base text-neutral-500 ">
                  {latestCourse?.duration}
                </Text>
              </div>
              <div className="border-b border-primary-300 my-8"></div>
              <div className="flex gap-2 items-center ">
                <div className="w-4 h-4">
                  <LessonIcon className="w-full h-full fill-primary-400" />
                </div>
                <Text className="text-base text-neutral-600">
                  {latestCourse.lessonCount === 0 ? (
                    <>0 lessons</>
                  ) : latestCourse.lessonCount === completedLessons.length ? (
                    <>Finished</>
                  ) : latestCourse.lessonCount - completedLessons.length ===
                    1 ? (
                    <>1 Lesson remain</>
                  ) : (
                    <>
                      {latestCourse.lessonCount - completedLessons.length}{" "}
                      Lessons remain
                    </>
                  )}
                </Text>
              </div>
              <div className="flex gap-2 mt-7">
                <Text className="w-1/2 h-[30px] bg-rose-400 text-white rounded-lg flex justify-center items-center">
                  {latestCourse.lessonCount - completedLessons.length === 0 ? (
                    <>{latestCourse.points} P</>
                  ) : (
                    <>
                      {collectedPoints}/{latestCourse.points} P
                    </>
                  )}
                </Text>
                <Button
                  onClick={() =>
                    navigate(
                      latestCourse.lessonCount - completedLessons.length === 0
                        ? `/user/course/${latestCourse.id}`
                        : `/user/course/${latestCourse.id}/lesson`
                    )
                  }
                  className="w-1/2 h-[30px] bg-primary-600 text-white rounded-lg"
                >
                  {latestCourse.lessonCount - completedLessons.length === 0
                    ? "View Lessons"
                    : "Continue"}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex mt-10 justify-center w-full ">
            <div className={`px-[40px]`}>
              <div className="flex flex-col gap-10 items-center justify-center">
                <div className="h-[60px]">
                  <CourseIcon className="w-full h-full fill-primary-400" />
                </div>
                <Text className="text-neutral-600 text-xl font-bold text-center">
                  There are currently no
                  <div>Courses</div>
                </Text>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LatestCourse;
