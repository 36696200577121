import { Card, Button, Image } from "antd";
import { AddEntityCardProps } from "../../utils/types";

const AddEntity = (props: AddEntityCardProps) => {
  const {
    firstButtonOnClick,
    secondButtonOnClick,
    firstButtonText,
    secondButtonText,
    Icon,
    cardClasses = "shadow-md max-w-[583px] w-full max-h-[642px] p-[100px]",
    imageClasses = "w-[250px] h-[250px] text-center mb-10",
  } = props;

  return (
    <Card className={cardClasses}>
      <div className="flex flex-col items-center h-full">
        <div className={imageClasses}>
          {Icon && <Icon className="w-full h-full fill-primary-600" />}
        </div>
        {firstButtonOnClick && firstButtonText && (
          <Button
            onClick={firstButtonOnClick}
            className="border-primary-200 w-full text-primary-600 h-12 text-base font-semibold mb-6"
          >
            {firstButtonText}
          </Button>
        )}
        {secondButtonOnClick && secondButtonText && (
          <Button
            onClick={secondButtonOnClick}
            className="bg-primary-600 w-full text-white h-12 text-base font-semibold"
          >
            {secondButtonText}
          </Button>
        )}
      </div>
    </Card>
  );
};

export default AddEntity;
