import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { StateType } from "../../../utils/types";
import { getBenchmarkAnalytics } from "../../../redux/educator/actions";
import {
  selectBenchmarkData,
  selectBenchmarkLoaded,
  selectBenchmarkLoading,
} from "../../../redux/educator/selector";

const mapStateToProps = (state: StateType) => ({
  loading: selectBenchmarkLoading(state),
  loaded: selectBenchmarkLoaded(state),
  benchmark: selectBenchmarkData(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getBenchmarkAnalytics,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
