export default [
  {
    path: "/module",
    heading: "You successfully created Module",
    firstButtonText: "Go to Modules",
    firstButtonLink: "/educator/module",
    secondButtonText: "Create New Module",
    secondButtonLink: "/educator/module/create",
  },
  {
    path: "/user/course",
    heading:
      "You successfully finished this Course and collected %POINTS% Points.",
    firstButtonText: "Go to Courses",
    firstButtonLink: "/user/course",
    secondButtonText: "Start New Course",
    secondButtonLink: "/user/course",
  },
  {
    path: "/course",
    heading: "You successfully created Course",
    firstButtonText: "Go to Courses",
    firstButtonLink: "/educator/course",
    secondButtonText: "Create New Course",
    secondButtonLink: "/educator/course/create",
  },
  {
    path: "/lesson",
    heading: "You successfully created Lesson",
    firstButtonText: "Go to Lessons",
    firstButtonLink: "/educator/lesson",
    secondButtonText: "Create New Lesson",
    secondButtonLink: "/educator/lesson/create",
  },
  {
    path: "/publish",
    heading: "Great, the Course is successfully published",
    firstButtonText: "Go to Courses",
    firstButtonLink: "/educator/course",
    secondButtonText: "Create New Course",
    secondButtonLink: "/educator/course/create",
  },
  {
    path: "/quiz",
    heading: "Great, the Quiz is successfully created",
    firstButtonText: "Go to Quizzes",
    firstButtonLink: "/educator/quiz",
    secondButtonText: "Create New Quiz",
    secondButtonLink: "/educator/quiz/create",
  },
  {
    path: "/alert",
    heading: "You successfully created Alert",
    firstButtonText: "Go to All Alerts",
    firstButtonLink: "/educator/alert",
    secondButtonText: "Create New Alert",
    secondButtonLink: "/educator/alert/create",
  },
  {
    path: "/quizzie",
    heading: "You successfully created Quizzie",
    firstButtonText: "Go to All Quizzies",
    firstButtonLink: "/educator/quizzie",
    secondButtonText: "Create New Quizzie",
    secondButtonLink: "/educator/quizzie/create",
  },
  {
    path: "/user",
    heading: "You successfully invited User",
    firstButtonText: "Go to All Users",
    firstButtonLink: "/educator/user",
    secondButtonText: "Invite Another User",
    secondButtonLink: "/educator/sign-up",
  },
  {
    path: "/ai-content",
    heading: "You successfully created AI Content",
    firstButtonText: "Go to AI Content",
    firstButtonLink: "/educator/ai-content",
    secondButtonText: "Create New AI Content",
    secondButtonLink: "/educator/ai-content/create",
  },
];
